import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from "mobx-react";
import { PropTypes } from "prop-types";
import { Dropdown, Icon } from "semantic-ui-react";
import { theme } from "../../styles";
import {DateTime} from 'luxon';
import { Message } from "../../store/Message";

const ChatContainer = styled.div`
    display: flex;

    height: 78px;

    border-bottom: 1px solid #E0E0E0;

    padding: 16px 0;
    :hover {
        cursor: pointer;
    }

    background-color: ${props => props.unread ? '#EBF4F9' : null} !important;

`;

const NotificationContainer = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;
    margin-right: 24px;
`;

const PatientContainer = styled.div`
    display: flex;
    flex: 1;

    justify-content: space-between;
    align-items: center;
`;

const MessageContainer = styled.div`
    display: flex;
    flex: 1;

    justify-content: space-between;
    align-items: center;
`;

const DotsIcon = styled(Icon)`
    width: 24px !important;
    height: 24px !important;

    margin-left: 4px !important;
    padding-top: 2px !important;
    color: rgba(0, 0, 0, 0.6);
`;

const PatientName = styled.span`
    /* lato/body 1 b */

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    letter-spacing: 0.5px;

    /* CY/CYgrey */
    color: #2D303B;

`;

const SentAt = styled.span`

    /* lato/caption 1 */
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;

    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.4px;


    /* pharmi/green3 */
    color: ${theme.primaryColor};
`;


const MessageText = styled.span`
    /* lato/body 2 */

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    /* CY/CYgrey */
    color: #2D303B;

    max-width: 310px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;


`;

const UnreadCountBadge = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: ${theme.primaryColor};
    border-radius: 10px;
    padding: 1px 7px !important;

    color: white;
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 140%;
`;

@observer
export class PatientChatPreview extends Component {
    static propTypes = {
        message: PropTypes.instanceOf(Message).isRequired,
    };

    parseTimeString(sentAt) {
        // const sentAt = DateTime.fromISO(dateTimeString);
        const now = DateTime.local();

        const lessThanTwoDaysAgo = now.diff(sentAt, ['days']).days < 2;
        const isToday = lessThanTwoDaysAgo && sentAt.day === now.day;
        const yesterday = now.minus({days:1});
        const isYesterday = lessThanTwoDaysAgo && sentAt.day === yesterday.day;

        if (isYesterday ){
            return t('time.yesterday', { time: sentAt.toFormat('HH:mm')});
        }

        if (isToday) {
            return t('time.today', {time: sentAt.toFormat('HH:mm')});
        }

         return sentAt.toFormat('dd/MM/yyyy')
    }

    markPatientMessagesAsRead(patient) {
        this.props.message.api.post(`/message/mark_as_read/`, { patient: patient.id } )
    }
    markPatientMessageAsUnread(patient) {
        this.props.message.api.post(`/message/mark_as_unread/`, { patient: patient.id })
    }

    render() {
        const {message} = this.props;
        const unreadCount = message.patient.unreadCount

        const messageTimeString = this.parseTimeString(message.sentAt)

        return (
            <ChatContainer unread={unreadCount > 0}  data-test-chat-patient={message.patient.id}>
                <div>
                    <Dropdown data-test-chat-dropdown compact item icon={<DotsIcon name={'ellipsis vertical'}/>}>
                        <Dropdown.Menu>
                        {unreadCount ? <Dropdown.Item data-test-chat-mark-read onClick={() => { this.markPatientMessagesAsRead(message.patient) }} text={t('chat.button.markRead.label')} /> :
                                    <Dropdown.Item data-test-chat-mark-unread onClick={() => { this.markPatientMessageAsUnread(message.patient) }} text={t('chat.button.markUnread.label')} />}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                    
                <NotificationContainer onClick={() => window.setPatientForChat(message.patient)}>
                    <PatientContainer>
                        <PatientName data-test-chat-patient-name>{message.patient.firstName} {message.patient.lastName}</PatientName>
                        <SentAt data-test-chat-sent-at>{messageTimeString}</SentAt>
                    </PatientContainer>

                    <MessageContainer>
                        <MessageText data-test-chat-message-text>{message.content}</MessageText>
                        {unreadCount > 0 && (<UnreadCountBadge data-test-chat-unread-count>{message.patient.unreadCount}</UnreadCountBadge>)}
                    </MessageContainer>
                </NotificationContainer>

            </ChatContainer>
        );
    }
}
