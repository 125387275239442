import {Model, Store, Casts} from 'store/Base';
import {observable} from "mobx";
import {WorkOrder} from 'store/WorkOrder';
import { Treatment } from './Treatment';

export const TEMPLATE_TYPE_EU = 'eu';
export const TEMPLATE_TYPE_SPECIALTY = 'specialty';
export const TEMPLATE_TYPE_HOMECARE = 'homecare';
export const TEMPLATE_TYPES = [
    TEMPLATE_TYPE_EU,
    TEMPLATE_TYPE_SPECIALTY,
    TEMPLATE_TYPE_HOMECARE
];

export class Template extends Model {
    static backendResourceName = 'template';
    static omitFields = ['assignedToMe'];

    @observable id = null;
    @observable name = '';
    @observable level = '';
    @observable applicationLink = '';
    @observable type = '';


    relations() {
        return {
            workOrder: WorkOrder,
            parentTemplate: Template,
            children: TemplateStore,
            treatment: Treatment
        };
    }

    casts() {
        return {
            createdAt: Casts.date,
        };
    }

    getProgress(userId = this.workOrder && this.workOrder.getAssigneeId()) {

        /*
         * Currently, the relations of the screen class above only
         * require the workOrder of the current template to be known.
         *
         * The workOrder of all parent templates will thus be undefined.
         * This is fine, because the parent templates have to be
         * finished anyway, so can simply return 1 to indicate 100%
         */
        if (this.workOrder === undefined) {
            return 1;
        }

        const checks = this.workOrder.workOrderChecks;
        if (checks === undefined) {
            return 1;
        }
        let numChecked = 0;
        for (let index = 0; index < checks.models.length; index++) {
            const check = checks.models[index];
            if (check.hasUserChecked(userId)) {
                numChecked++;
            }
        }

        /*
         * If there are no checks, it is already 'finished'
         */
        if (checks.length === 0) {
            return 1;
        }

        return numChecked / checks.length;
    }

    getChildLevel() {
        const levels = [
            'grandmother',
            'mother',
            'daughter',
            'granddaughter'
        ];
        const index = levels.indexOf(this.level) + 1;
        return levels[index];
    }

    claim() {
        return this.api.post(`/template/${this.id}/claim/`);
    }

    reject() {
        return this.api.post(`/template/${this.id}/reject/`);
    }
}

export class TemplateStore extends Store {
    Model = Template;
    static backendResourceName = 'template';

    changeRole(role, userId) {
        return this.api.post(`/template/reassign/`, {
            role: role,
            user: userId,
            templates: this.map(t => t.id),
        });
    }
}
