import {Model, Store} from 'store/Base';
import {observable} from 'mobx';

export class Image extends Model {

    static backendResourceName = 'image';

    @observable id;
    @observable name;
    @observable image;

    fileFields() {
        return ['image'];
    }

    relations() {
    }

}

export class ImageStore extends Store {
    Model = Image;
    static backendResourceName = 'image';
}
