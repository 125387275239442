import {Model, Store, Casts} from 'store/Base';
import {observable} from "mobx";
import {WorkOrder} from 'store/WorkOrder';
import {UserStore} from "store/User";
import {subscribe} from "./Base";

export class Notification extends Model {
    static backendResourceName = 'notification';

    @observable id = null;
    @observable content = null;
    @observable createdAt = null;
    @observable seenAt = null;

    markSeen() {
        return this.api.post(`${this.url}seen/`).then(() => {
            this.__store.refresh();
        });
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            seenAt: Casts.datetime,
        };
    }

    relations() {
        return {
            user: UserStore,
            workOrder: WorkOrder
        };
    }
}

export class NotificationStore extends Store {
    Model = Notification;
    static backendResourceName = 'notification';

    @observable hasNewNotification = false;

    refresh(data) {
        this.fetch()
    }

    fetch(...props) {
        return super.fetch(...props)
            .then(() => {
                this.hasNewNotification = !!this.find(notification => !notification.seenAt);
            });
    }

    subscribe(user) {
        subscribe({"type": "work_order", "entity": "*", "user": user.id}, this.refresh.bind(this));
    }

    unsubscribe() {
        this.api.socket.unsubscribe(this.workOrderUpdate);
    }

    getUnreadNotifications(user) {
        return this.api.get(`${this.url}${user}/unread/`);
    }
}
