import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import { TreatmentStore } from 'store/Treatment';

export class Category extends Model {

    static backendResourceName = 'category';

    @observable id = null;
    @observable name = '';
    @observable numTreatments = '';

    relations() {
        return {
            treatments: TreatmentStore,
        };
    }
}

export class CategoryStore extends Store {
    Model = Category;
    static backendResourceName = 'category';
}
