import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { Message, MessageStore } from "../../store/Message";
import { Form, Header, Icon } from "semantic-ui-react";
import { observable, reaction } from "mobx";
import { Patient } from "../../store/Patient";
import { ItemButton } from "../../spider/semantic-ui/Admin/Overview";
import { Link } from "react-router-dom";
import { TargetTextArea } from "../../spider/semantic-ui/Target";
import { ChatMessage } from "../../component/Chat/ChatMessage";
import { throttle } from "lodash";
import { DateTime } from "luxon";
import { theme } from "../../styles";

const SCROLL_FETCH_THRESHOLD = 500;

const FlexContainer = styled.div`
    flex: 1 0 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const PatientChatHeader = styled(Header)`
    height: 112px !important;
    border-bottom: 1px solid #E0E0E0 !important;
    padding: 16px !important;
    font-size: 2.5em !important;
    margin: 0 !important;

    display: flex;
    flex-direction: column;

`;

const HeaderButtonContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    height: 24px;
    margin-bottom: 0.1em;

`;

const PatientHeaderContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 56px;
    align-items: center;
    `;

const PatientNameContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;


    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);

`;

const PatientName = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.15px;
    color: #2D303B;
`;

const SpecialItemButton = styled(ItemButton)`
    height: 36px;
    width: 36px;
    margin-right: 1em !important;
`;

const VerticalAlignDiv = styled.div`
    display: flex;
    flex: 1;
    align-items: center;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    /* general/black 60% */

    color: rgba(0, 0, 0, 0.6);

    :hover {
        cursor: pointer;
    }
`

const MessagesContainer = styled.div`
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const WriteMessageContainer = styled.div`
    border-top: 1px solid #E0E0E0;
    height: auto;
    padding: 22px;
    justify-self: flex-end !important;
`;

const DateIndicator = styled.div`

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    justify-content: center;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;

    /* general/black 60% */

    color: rgba(0, 0, 0, 0.6);

    margin: 12px 0;
`;

const DividerWithText = styled.div`
    //flex: 1;
    height: 25px;
    border-bottom: 2px solid ${theme.primaryColor};
    text-align: center;
    margin: 0px -1em 16px -1em;

    span {
        background-color: #FFFFFF;
        padding: 0 10px;

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */
        letter-spacing: 0.4px;

        position: relative;
        top: 10px !important;



        /* general/black 60% */

        color: ${theme.primaryColor};
    }
`;

const SpecialFormGroup = styled(Form.Group)`
    margin-bottom: 0 !important;
`;

export class BackToChatButton extends Component {
    static propTypes = {
        backToOverview: PropTypes.func.isRequired,
    }

    render() {
        const {backToOverview} = this.props;
        return <VerticalAlignDiv onClick={() => backToOverview()} data-test-chat-back-to-overview-button>
            <Icon style={{height: '15px'}} name={'arrow left'}/>
            <p>{t('chat.back.button.label')}</p>
        </VerticalAlignDiv>
    }
}

export class UnreadMessageIndicator extends Component {
    static propTypes = {
        messageStore: PropTypes.func.isRequired,
        unreadMessageIndicatorRef: PropTypes.object,
    }

    render() {
        const { messageStore, unreadMessageIndicatorRef } = this.props;
        const currentUser = window.viewStore.currentUser.id;
        const unreadMessageCount = messageStore.filter(message => message.sentBy.id !== currentUser && !message.seenAt).length
        return (<DividerWithText>
                    <span ref={unreadMessageIndicatorRef} data-test-chat-unread-message-divider-text>
                        {t('message.divider.text', { count: unreadMessageCount })}
                    </span>
        </DividerWithText>)
    }
}

@observer
export default class PatientChatOverview extends Component {

    static propTypes = {
        patient: PropTypes.instanceOf(Patient).isRequired,
        template: PropTypes.string,
        backToOverview: PropTypes.func.isRequired,
    };

    @observable messageStore= new MessageStore({
        relations: ['patient.user', 'sentBy'],
        params: {
            limit: 100,
            order_by: '-sent_at',
        },
        comparator: (a, b) => a.sentAt - b.sentAt
    });

    @observable messageUnderConstruction = new Message(null, {relations: ['customer', 'patient.user', 'sentBy']});
    latestKnownDate = null;
    firstUnreadMessage = null;

    /**
     * Marks all messages that are not sent by the current user as read for this chat.
     */
    markMessagesAsRead() {
        this.messageStore.api.post(`/message/mark_as_read/`, { patient: this.props.patient.id } )
    }

    setupComponent() {
        const { patient } = this.props;
        const customer = window.viewStore.currentUser.pharmacist.customer;
        // const customer = patient.customer;

        this.messageStore.params['.patient'] = patient.id;
        this.messageStore.fetch().then((response) => {
            this.messageStore.subscribeToChat(customer, patient, this.maybeFixScrollPosition)
        })

        this.handleScroll = throttle(this.handleScroll, 50);
    }

    componentDidMount() {
        this.stopReaction = reaction(
            () => this.props.patient?.id,
            value => this.setupComponent(),
            {fireImmediately: true}
        )

        if (this.props.template && !this.messageUnderConstruction.content.length) {
            this.messageUnderConstruction.setInput('content', this.props.template);
        }
    }

    componentWillUnmount() {
        this.markMessagesAsRead()
        this.stopReaction()
    }


    datesNotTheSameDay(dateTime1, dateTime2) {
        const daysNotTheSame = dateTime1.day !== dateTime2.day;
        const moreThanOneDayDifference = dateTime1.diff(dateTime2, ['days']).days <= -1
        return daysNotTheSame || moreThanOneDayDifference;
    }


    UNSAFE_componentWillUpdate(a, b, c) {
        // reset the latest known date to make sure it will rerender
        this.latestKnownDate = null;
    }

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //  The following code was stolen from Boekestijn MessageOverview container
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    // Scroll to bottom when initially loading a driver
    lastRenderedMessage = false;

    // We have a bug where sometimes the older chat message can be added _under_
    // the current scrollPosition.
    // This means a user has scrolled up, sees a message, and suddenly
    // the DOM jumps to a freshly loaded message.
    //
    // This is because the DOM will sometimes "stick" at the current scrollPosition
    // We use these 2 vars to keep track of the scrollPosition before the fetch.
    //
    // After new data is loaded, the scrollPosition of the user should never be
    // higher than the height of the newly added content.
    scrollTopBeforeFetch = null;
    scrollHeightBeforeFetch = null;

    // We only want to fetch new data if the scrollTop of the user
    // has dipped below the threshold. Otherwise we might fetch multiple times
    // if the treshold has been reached once.
    contentLoaded = false;

    componentDidUpdate(props) {
        const lastMessageId = this.messageStore.length > 0
            ? this.messageStore.at(this.messageStore.length - 1).id
            : null;

        if (this.lastRenderedMessage !== lastMessageId) {
            this.scrollToBottom();
            this.lastRenderedMessage = lastMessageId
        }
        if (this.lastRenderedMessage === lastMessageId) {
            this.restorePreviousScrollPosition();
        }

        if (this.unreadMessageIndicatorRef.current) {
            this.unreadMessageIndicatorRef.current.scrollIntoView({block: 'center'});
        }
    }

    // After a message has been added, we scroll to bottom.
    // We have to use a setTimeout because the new element is not yet rendered
    // after it has been added to the store.
    //
    // This does introduce a bug:
    // the scrollbar randomly disappears on mac
    deferredScrollToBottom = () => {
        setTimeout(this.scrollToBottom);
    };

    scrollToBottom = () => {
        if (this.scrollView) {
            this.scrollView.scrollTop = this.scrollView.scrollHeight;
        }
    };

    maybeFixScrollPosition = () => {
        // We have a new bug.
        if (
            this.scrollView &&
            this.scrollView.scrollTop < this.newContentHeight
        ) {
            this.restorePreviousScrollPosition();
        }
    };


    handleScroll = () => {
        if (!(this.scrollView && this.scrollView.clientHeight)) {
            return;
        }

        const thresholdReached =
            this.scrollView.scrollTop <= SCROLL_FETCH_THRESHOLD;

        if (!this.contentLoaded && !thresholdReached) {
            this.contentLoaded = true;
        }

        if (!thresholdReached) {
            return;
        }

        if (!this.contentLoaded) {
            return;
        }

        this.snapShotScrollPosition();
    };

    get newContentHeight() {
        return this.scrollView.scrollHeight - this.scrollHeightBeforeFetch;
    }

    restorePreviousScrollPosition() {
        this.scrollView.scrollTop =
            this.newContentHeight + this.scrollTopBeforeFetch;
    }

    snapShotScrollPosition() {
        this.scrollHeightBeforeFetch = this.scrollView.scrollHeight;
        this.scrollTopBeforeFetch = this.scrollView.scrollTop;
    }

    shouldRenderDateIndicator(message) {
        let renderDateIndicator = true;
        // Check if our date is different than the latest known one or set the latest known to this date
        if (this.latestKnownDate) {
            renderDateIndicator = this.datesNotTheSameDay(this.latestKnownDate, message.sentAt)
            if (renderDateIndicator) {
                this.latestKnownDate = message.sentAt;
            }
        } else {
            this.latestKnownDate = message.sentAt;
        }

        return renderDateIndicator;
    }

    shouldRenderUnreadMessageIndicator(message) {
        let renderUnreadMessageIndicator = false;
        if (message.patient && message.patient.user) {
            renderUnreadMessageIndicator = !message.seenAt && message.sentBy.id === message.patient.user.id && !this.firstUnreadMessage;
        }

        if (renderUnreadMessageIndicator && !this.firstUnreadMessage){
            this.firstUnreadMessage = message.id;

            // Do not show for the first message
            renderUnreadMessageIndicator = this.messageStore.at(0).id !== message.id
        }

        return renderUnreadMessageIndicator;
    }

    unreadMessageIndicatorRef = React.createRef();

    returnRef() {
        return this.unreadMessageIndicatorRef;
    }

    handleRef = ref => {
        this.scrollView = ref;
    };

    handleKeyPress = key => {
        if (!(key.shiftKey) && key.key === 'Enter'){
            if (this.messageUnderConstruction.content.length) {
                this.sendAndResetMessage();
            }
            key.preventDefault();
        }
    }

    sendAndResetMessage = () => {
        const currentUser = window.viewStore.currentUser.copy();
        this.messageUnderConstruction.sentBy = currentUser;
        this.messageUnderConstruction.patient = this.props.patient.copy();
        this.messageUnderConstruction.customer = currentUser.pharmacist.customer;

        this.messageUnderConstruction.save()

        this.messageUnderConstruction._unsaved = true
        this.messageUnderConstruction.sentAt = DateTime.local();
        this.messageStore.models.push(this.messageUnderConstruction)
        this.messageUnderConstruction = new Message(null, {relations: ['customer', 'patient.user', 'sentBy']});


    }

    render() {
        // const keys = Object.keys(messageStore.overviewData);
        const {patient, backToOverview} = this.props;
        return (<FlexContainer>
                <PatientChatHeader>
                    <HeaderButtonContainer>
                        <BackToChatButton backToOverview={backToOverview}/>
                    </HeaderButtonContainer>
                    <PatientHeaderContainer>
                        <SpecialItemButton
                            icon={'eye'}
                            label={t('chat.button.patient.label')}
                            as={Link}
                            to={`/patient/patient/${patient.id}/edit`}
                            data-test-chat-view-patient-button
                        />
                        <PatientNameContainer>
                            <PatientName data-test-chat-patient-name>{patient.firstName} {patient.lastName}</PatientName>
                            {patient.number}
                        </PatientNameContainer>
                    </PatientHeaderContainer>
                </PatientChatHeader>

                <MessagesContainer
                    data-test-chat-message-container
                    innerRef={this.handleRef}
                    onScroll={this.handleScroll}
                >
                    {this.messageStore.map((message) => {
                        const renderDateIndicator = this.shouldRenderDateIndicator(message)
                        const renderUnreadMessageIndicator = this.shouldRenderUnreadMessageIndicator(message)

                        return (<>
                            {renderUnreadMessageIndicator && <UnreadMessageIndicator
                                messageStore={this.messageStore}
                                unreadMessageIndicatorRef={this.unreadMessageIndicatorRef}
                            />}
                            {renderDateIndicator && <DateIndicator>
                                {message.sentAt.setLocale(window.viewStore.currentUser.language).toFormat('cccc d LLL yyyy')}
                            </DateIndicator>}
                            <ChatMessage key={message.cid} message={message}/>
                        </>)
                    })}


                </MessagesContainer>


                <WriteMessageContainer>
                    <Form>
                        <SpecialFormGroup>
                            <TargetTextArea
                                width={14}
                                autoHeight
                                style={{ maxHeight: 200 }}
                                target={this.messageUnderConstruction}
                                noLabel
                                name={'content'}
                                placeholder={t('message.field.content.write')} 
                                onKeyPress={this.handleKeyPress} 
                                onClick={() => this.markMessagesAsRead()}
                            />
                            <SpecialItemButton
                                icon='send'
                                style={{alignSelf: 'flex-end'}}
                                disabled={!this.messageUnderConstruction.content.length}
                                onClick={this.sendAndResetMessage}
                                data-test-chat-send-message-button
                            />
                        </SpecialFormGroup>
                    </Form>
                </WriteMessageContainer>

            </FlexContainer>
        );
    }
}

