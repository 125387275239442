import { Model, Store, Casts } from 'store/Base';
import { observable } from 'mobx';
import { WorkOrder } from './WorkOrder';
import { User } from './User';

export class Reassignment extends Model {

    static backendResourceName = 'reassignment'

    @observable id = null;
    @observable role = null;
    @observable moment = null;

    relations() {
        return {
            workOrder: WorkOrder,
            newAssignee: User
        };
    }

    casts() {
        return {
            moment: Casts.datetime,
        };
    }
}

export class ReassignmentStore extends Store {
    Model = Reassignment;
    static backendResourceName = 'reassignment';
}