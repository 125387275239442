import {Model, Store} from 'store/Base';
import {observable} from "mobx";
import { Treatment } from "../Treatment";
import { TreatmentGroup } from "./TreatmentGroup";

export class TreatmentTreatmentGroup extends Model {
    static backendResourceName = 'treatment_treatment_group';

    @observable id = null;


    relations() {
        return {
            treatment: Treatment,
            treatmentGroup: TreatmentGroup,
        };
    }
}

export class TreatmentTreatmentGroupStore extends Store {
    Model = TreatmentTreatmentGroup;
    static backendResourceName = 'treatment_treatment_group';
}
