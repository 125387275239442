import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import {Question} from "./Question"
import {Patient} from './Patient';
import {Treatment} from "./Treatment"
import {Casts} from "store/Base"
import {ChoiceResponseStore} from './ChoiceResponse';
import { DATE_FORMAT } from 'helpers';

export class Response extends Model {

    static backendResourceName = 'response';

    @observable id = null;
    @observable value = '';
    @observable dateValue = null;
    @observable treatmentDay = null;
    @observable createdAt = null;

    casts() {
        return {
            dateValue: Casts.datetime,
            createdAt: Casts.datetime,
        };
    };

    relations() {
        return {
            question: Question,
            choiceResponses: ChoiceResponseStore,
            patient: Patient,
            treatment: Treatment,
        }
    }

    getFormattedResponse(separator=', ') {
        return this.value || this.dateValue?.format(DATE_FORMAT) || this.choiceResponses?.map((cr) => cr.choice.value)?.join(separator);
    }
}

export class ResponseStore extends Store {
    Model = Response;
    static backendResourceName = 'response';
}
