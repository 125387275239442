import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import {Casts} from "./Base";
import {Customer} from "./Customer/Customer";
import {User} from './User';

export class Pharmacist extends Model {

    static backendResourceName = 'pharmacist';

    @observable id = null;
    @observable firstName = '';
    @observable lastName = '';

    @observable createdAt = null;

    relations() {
        return {
            user: User,
            customer: Customer
        };
    }


    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }


}

export class PharmacistStore extends Store {
    Model = Pharmacist;
    static backendResourceName = 'pharmacist';
}
