import {Model, Store} from 'store/Base';
import {observable} from 'mobx';

/**
 * DndAccordion model should be used for the model that is the root node for the DndAccordion.
 * The children of this model will be rendered in the DndAccordion.
 *
 * A DndAccordion needs to have a property `_children` for the relation that holds the child nodes (important for the DndAccordion).
 * If you extend this model for your custom model you can define `_children` by adding a function:
 *
 *      get _children(){
 *          return this.{nameOfRelation}
 *      }
 *
 * A DndAccordion is a tree structure and consists of internal nodes and leaf nodes.
 *  * An example of DndAccordion is a questionnaire: a questionnaire can hold sections and in those sections it can have
 *      specific questions
 *
 * An internal node is a node that contains other nodes as children
 *  * In the example an internal node is represented as a section, a questionnaire has sections and every section can
 *      have specific questions
 *
 * A leaf node is a node that does not contain any children (children here is defined as nodes that need to
 * be rendered for the DndAccordion, a leaf node is allowed to have deeper relations)
 *  * In the example a leaf node is represented as a specific question, note that a specific question can have
 *      relations such as responses, however the responses are not visualised in the DndAccordion and should therefore
 *      not be assigned to the property `_children`
 *
 * A node might be rendered in two different ways, either as a leaf node or as an internal node. There are two ways the
 * DndAccordion decides on the rendering method used.
 *  1. If your model extends the DndNodeModel directly, the decision will be made based on the amount of children the node
 *     has. When there are no children, your node is rendered as a leaf node. If it does have children your node will be
 *     rendered as an internal node.
 *
 *  2. In some cases you want your internal node rendered as an internal node even if it does not contain any children.
 *     You can force an internal node to be rendered as an internal node by having your model directly extend the
 *     DndInternalNodeModel. The same can be done for leaf nodes by extending the DndLeafNodeModel
 *
 */
export class DndRootModel extends Model {

    static backendResourceName;

    /**
     * This method should be overridden and return the child relation
     * @private
     */
    get _children() {
    }

}

export class DndRootStore extends Store {
    Model = DndRootModel;
    static backendResourceName;
}

/**
 * DndNodeModel should be used for models that can be either a leaf node or an internal node based on if it has
 * children or not.
 *
 * A DndLeafModel needs to have a property `_parent` for the relation that points to the direct parent of this node
 * (important for the DndAccordion), as well as a property `_children` for the relation that points to the children of this node.
 *
 * If you extend this model for your custom model you can define `_parent` and `_children` by
 * adding the functions:
 *
 *      get _children(){
 *          return this.{nameOfRelation}
 *      }
 *
 *      get _parent(){
 *          return this.{nameOfRelation}
 *      }
 *
 * A root node is the root of a tree structure and its children consists of internal nodes and leaf nodes.
 *  * An example of a root node is a questionnaire: a questionnaire can hold sections and in those sections it can have
 *      specific questions
 *
 * An internal node is a node that is part of the root model and contains other nodes as children
 *  * In the example an internal node is represented as a section, a questionnaire has sections and every section can
 *      have specific questions
 *
 * A leaf node is a node that does not contain any children (children here are considered children if the related nodes
 * have to be rendered for the DndAccordion, a leaf node is allowed to have deeper relations)
 *  * In the example a leaf node is represented as a specific question, note that a specific question can have
 *      relations such as responses, however the responses are not visualised in the DndAccordion and should therefore
 *      not be assigned to the property `_children`
 *
 */
export class DndNodeModel extends DndRootModel {

    static backendResourceName;

    @observable id = null;
    @observable title = '';
    @observable ordering = 1;

    @observable _dndCollapsed = false;

    /**
     * This method should be overridden to return the boolean field. Should not be overridden or return undefined if
     * checkboxes should be disabled
     * @private
     */
    get _dndCheckbox() {}

    /**
     * Set the updated value of the dndCheckbox, use setInput to allow onlyChanges to be used at save
     * @param bool  - the new value for the boolean field
     * @private
     */
    set _dndCheckbox(bool) {}

    /**
     * This method should be overridden and return the child relation
     * @private
     */
     get _children() {
    }

    /**
     * This method should be overridden and return the parent relation
     * @private
     */
    get _parent() {
    }

 }

export class DndNodeStore extends Store {
    Model = DndNodeModel;
    static backendResourceName;
}

/**
 * DndInternalNodeModel should be used for the model that represents the internal nodes in a DndAccordion.
 * It is a specific version of the root node in that this root node of the subtree has a parent therefore it
 * should have a direct parent (either a root node or another internal node) and have children.
 *
 * A DndLeafModel needs to have a property `_parent` for the relation that points to the direct parent of this node
 * (important for the DndAccordion), as well as a property `_children` for the relation that points to the children of this node.
 *
 * If you extend this model for your custom model you can define `_parent` and `_children` by
 * adding the functions:
 *
 *      get _children(){
 *          return this.{nameOfRelation}
 *      }
 *
 *      get _parent(){
 *          return this.{nameOfRelation}
 *      }
 *
* A root node is the root of a tree structure and its children consists of internal nodes and leaf nodes.
 *  * An example of a root node is a questionnaire: a questionnaire can hold sections and in those sections it can have
 *      specific questions
 *
 * An internal node is a node that is part of the root model and contains other nodes as children
 *  * In the example an internal node is represented as a section, a questionnaire has sections and every section can
 *      have specific questions
 *
 * A leaf node is a node that does not contain any children (children here are considered children if the related nodes
 * have to be rendered for the DndAccordion, a leaf node is allowed to have deeper relations)
 *  * In the example a leaf node is represented as a specific question, note that a specific question can have
 *      relations such as responses, however the responses are not visualised in the DndAccordion and should therefore
 *      not be assigned to the property `_children`
 *
 */
export class DndInternalNodeModel extends DndNodeModel {

    static backendResourceName;

    @observable id = null;
    @observable title = '';
    @observable ordering = 99;

}

export class DndInternalNodeStore extends Store {
    Model = DndInternalNodeModel;
    comparator = 'ordering';

}


/**
 * DndLeafModel should be used for the model that contains the leaf nodes in a DndAccordion.
 * It is the final depth of the visualization and does therefore not contain children.
 *
 * A DndLeafModel needs to have a property `_parent` for the relation that points to the direct parent of this node
 * (important for the DndAccordion). If you extend this model for your custom model you can define `_parent` by
 * adding a function:
 *
 *      get _parent(){
 *          return this.{nameOfRelation}
 *      }
 *
* A root node is the root of a tree structure and its children consists of internal nodes and leaf nodes.
 *  * An example of a root node is a questionnaire: a questionnaire can hold sections and in those sections it can have
 *      specific questions
 *
 * An internal node is a node that is part of the root model and contains other nodes as children
 *  * In the example an internal node is represented as a section, a questionnaire has sections and every section can
 *      have specific questions
 *
 * A leaf node is a node that does not contain any children (children here are considered children if the related nodes
 * have to be rendered for the DndAccordion, a leaf node is allowed to have deeper relations)
 *  * In the example a leaf node is represented as a specific question, note that a specific question can have
 *      relations such as responses, however the responses are not visualised in the DndAccordion and should therefore
 *      not be assigned to the property `_children`
 *
 */
export class DndLeafModel extends DndNodeModel {

    static backendResourceName;

    @observable id = null;
    @observable title = '';
    @observable ordering = 1;


}

export class DndLeafStore extends Store {
    Model = DndLeafModel;
    static backendResourceName;
    comparator = 'ordering';
}
