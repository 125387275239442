import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import { Choice } from './Choice';
import { SignalCondition } from './SignalCondition';

export class SignalConditionChoice extends Model {

    static backendResourceName = 'signal_condition_choice';

    @observable id = null;

    relations() {
        return {
            choice: Choice,
            signalCondition: SignalCondition
        };
    }
}

export class SignalConditionChoiceStore extends Store {
    Model = SignalConditionChoice;
    static backendResourceName = 'signal_condition_choice';
}
