import {observable} from 'mobx';
import {Treatment} from "./Treatment";
import {PhaseMomentStore} from "./PhaseMoment";
import { DndInternalNodeModel, DndInternalNodeStore } from "../spider/store/DndAccordion";

export class Phase extends DndInternalNodeModel {

    static backendResourceName = 'phase';

    @observable id = null;
    @observable title = '';
    @observable ordering = 0;

    get _parent(){
        return this.treatment;
    }

    /**
     * Returns a list of moments that could be used to quickly access some info
     *
     * @returns []  - list of related moments
     */
    get moments(){
        const moments = []
        this.phaseMoments.models.forEach((phaseMoment) => {
            moments.push(phaseMoment.moment);
        })
        return moments
    }

    get _children(){
        return this.phaseMoments;
    }


    relations() {
        return {
            treatment: Treatment,
            phaseMoments: PhaseMomentStore
        }
    }

}

export class PhaseStore extends DndInternalNodeStore {
    Model = Phase;
    static backendResourceName = 'phase';
}
