import {observable} from 'mobx';
import {Signal} from './Signal';
import { User } from './User';
import {Casts, Model, Store} from 'store/Base';

export class SignalComment extends Model {

    static backendResourceName = 'signal_comment';
    
    @observable id = null;
    @observable content = '';
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            signal: Signal,
            user: User,
        }
    }

}

export class SignalCommentStore extends Store {
    Model = SignalComment;
    static backendResourceName = 'signal_comment';
}
