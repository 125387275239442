import {observable} from 'mobx';
import { Model, Store } from "./Base";

export class SpecialVariable extends Model {

    static backendResourceName = 'special_variable';

    @observable id = null;
    @observable identifier;
    @observable name;

 }

export class SpecialVariableStore extends Store {
    Model = SpecialVariable;
    static backendResourceName = 'special_variable';
}
