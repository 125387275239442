import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Question } from "./Question"
import { Choice } from "./Choice";

export class MomentQuestionDependency extends Model {
    static backendResourceName = 'moment_question_dependency';

    @observable id = null;

    // Specific value a response should have
    @observable value = '';
    @observable numberFrom;
    @observable numberTo;
    @observable shouldBeAnswered = null;


    relations() {
        return {
            question: Question,
            choice: Choice,
            // forQuestion: Question,
        };
    }
}

export class MomentQuestionDependencyStore extends Store {
    Model = MomentQuestionDependency;

    static backendResourceName = 'moment_question_dependency';
}
