import {observable } from "mobx";
import { Casts, Model, Store } from "../Base";
import { MedicationSchedulePhase } from "./MedicationSchedulePhase";


export class MedicationSchedulePhaseTime extends Model {
    static backendResourceName = 'medication_schedule_phase_time';
    static omitFields = ['title']

    @observable id = null;
    @observable time = Casts.time;

    casts() {
        return {
            time: Casts.time
        };
    }

     relations() {
        return {
            medicationSchedulePhase: MedicationSchedulePhase,
        }
    }
}

export class MedicationSchedulePhaseTimeStore extends Store {
    // comparator = 'ordering'
    Model = MedicationSchedulePhaseTime;
    static backendResourceName = 'medication_schedule_phase_time';
    _phase_time_cache = []
    cache_push(){
        if(this.length>0){
            this._phase_time_cache.push(this.at(-1));
            this.remove(this.at(-1));
        }
    }
    cache_pop_or_create(){
        if(this._phase_time_cache.length>0){
            this.add({...this._phase_time_cache.pop().toJS()});
        } else {
            this.add({time: '08:00'});
        }
    }
}
