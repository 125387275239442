/**
 * Similar to src/Button.js, but based on Semantic-UI
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Store } from 'mobx-spine';
import { Button as BaseButton, Icon, Popup, Dropdown, Responsive, List } from 'semantic-ui-react';
import styled from 'styled-components';
import { t } from 'i18n';
import { omit, pick } from 'lodash';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import {theme} from "styles";

const SU_COLORS = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'];
function customColor(props) {
    if (props.color && !(props.color in SU_COLORS)) {
        return `color: ${props.color};`;
    }

    return '';
}

const StyledIcon = styled(({ color, ...rest }) => {
    if (!SU_COLORS.includes(color)) {
        return <Icon style={{ color }} {...rest} />;
    } else {
        return <Icon color={color} {...rest} />;
    }
})`
    cursor: pointer;
    margin: 4px !important;
    line-height: normal;
`;

const linkCss = `
    color: inherit;
    text-decoration: none;
    &:visited {
        color: inherit;
    }
    &:hover {
        color: inherit;
    }
    &:active {
        color: inherit;
    }

    ${props => customColor(props)}
`;

const StyledA = styled.a`
    ${linkCss}
`;

const StyledLink = styled(Link)`
    ${linkCss}
`;

export { StyledLink as Link };
export { StyledIcon as Icon };

const StyledPopup = styled(Popup)`
    /* Makes buttons fluid in the popup on mobile */
    .ui.button {
        width: 100%;
    }
`;

export class ResponsiveContainer extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    render() {
        const { children } = this.props;

        return (
            <React.Fragment>
                <Responsive maxWidth={Responsive.onlyComputer.minWidth}>
                    <StyledPopup trigger={<BaseButton primary icon="ellipsis vertical" />} on="click">
                        <List divided relaxed>
                            {children.map
                                ? children.map(b => <List.Item key={b ? b.key : undefined}>{b}</List.Item>)
                                : children
                            }
                        </List>
                    </StyledPopup>
                </Responsive>
                <Responsive minWidth={Responsive.onlyComputer.minWidth} style={{ display: 'flex' }}>
                    {children}
                </Responsive>
            </React.Fragment>
        );
    }
}

/**
 * Use Semantic-UI icons as buttons. It also support simple titles which will
 * turn into popups.
 */
export class IconButton extends Component {
    static propTypes = {
        /** Renders a Popup with title content. */
        title: PropTypes.string,
        /** Suddenly render as an external link. */
        href: PropTypes.string,
        /** Suddenly render as an internal link. */
        to: PropTypes.string,

        /** Semantic only supports basic colors, but here we add support for custom colors. */
        color: PropTypes.string,
        name: PropTypes.string.isRequired,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        download: PropTypes.bool,
    };

    render() {
        const { title, to, href, loading, name, disabled, onClick, download, ...props } = this.props;
        let buttonIcon = <StyledIcon disabled={disabled} onClick={disabled ? null : onClick} {...props} loading={loading} name={loading ? 'circle notch' : name} />;

        if (href) {
            buttonIcon = <StyledA download={download} href={href} data-test-anchor>{buttonIcon}</StyledA>;
        }

        if (to) {
            buttonIcon = <StyledLink to={to}>{buttonIcon}</StyledLink>
        }

        if (title) {
            return <Popup style={{ marginLeft: '-0.75em' }} trigger={buttonIcon} content={title} />
        }

        return buttonIcon;
    }
}

export class Button extends Component {
    static propTypes = {
        content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    };

    static defaultProps = {
        type: 'button',
        labelPosition: 'left',
    }

    render() {
        let { content, ...props } = this.props;
        if (typeof content === 'function') {
            content = content();
        }
        return <BaseButton content={content} {...props} />;
    }
}

export class SyncButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'sync',
        labelPosition: 'left',
        content: () => t('form.syncButton'),
    }
}

export class CancelButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'cancel',
        labelPosition: 'left',
        content: () => t('form.cancelButton'),
    }
}

export class DownloadButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'download',
        labelPosition: 'left',
        content: () => t('form.downloadButton'),
    }
}

export class SendButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'send',
        labelPosition: 'left',
        content: () => t('form.sendButton'),
        'data-test-send-button': true,
    }
}

export class SubmitButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'check',
        labelPosition: 'left',
        content: () => t('form.submitButton'),
    }
}

export class SaveButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'save',
        labelPosition: 'left',
        content: () => t('form.saveButton'),
        'data-test-save-button': true,
    }
}

export class CopyButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'copy',
        labelPosition: 'left',
        content: () => t('form.copyButton'),
    }
}

export class AddButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'add circle',
        labelPosition: 'left',
        content: () => t('form.addButton'),
    }
}

export class EditButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'edit',
        labelPosition: 'left',
        content: () => t('form.editButton'),
    }
}

export class ApplyButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'check',
        labelPosition: 'left',
        content: () => t('form.applyButton'),
    }
}

export class DeleteButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'trash',
        labelPosition: 'left',
        content: () => t('form.deleteButton'),
        color: 'red',
        'data-test-delete-button': true,
    }
}

export class RestoreButton extends Button {
    static defaultProps = {
        type: 'button',
        icon: 'undo',
        labelPosition: 'left',
        content: () => t('form.restoreButton'),
    }
}

const MyDropzone = styled(Dropzone)`
    position: relative;
    display: inline;
    cursor: pointer;
`;

@observer
export class DroppableButton extends Component {
    static propTypes = {
        onDrop: PropTypes.func.isRequired,
        title: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    // Found from dropzone docs.
    dropzoneProps = [
        'accept',
        'children',
        'disabled',
        'getFilesFromEvent',
        'maxSize',
        'minSize',
        'multiple',
        'noClick',
        'noDrag',
        'noDragEventsBubbling',
        'noKeyboard',
        'onDragEnter',
        'onDragLeave',
        'onDragOver',
        'onDrop',
        'onDropAccepted',
        'onDropRejected',
        'onFileDialogCancel',
        'preventDropOnDocument',

        // Not originally from dropzone, but useful to pass as well.
        'name',
    ];

    @observable droppable = false;

    onDrop = (...args) => {
        const { onDrop } = this.props;

        this.droppable = false;

        onDrop(...args);
    }

    /**
     * A bit weird how this works, but this is the default implementation
     * used by BOEK. Perhaps refactor to a more sane DroppableButton.
     */
    renderContent(props) {
        let color = this.droppable ? 'red' : undefined;

        if (props.disabled) {
            color = 'grey';
        }

        return (
            <IconButton name="upload" color={color} {...props } />
        );
    }

    render() {
        const propsIconButton = omit(this.props, this.dropzoneProps);
        const { title, loading, disabled, ...props } = this.props;

        if (disabled) {
            return this.renderContent({ disabled, ...propsIconButton });
        }

        return (
            <MyDropzone
                activeStyle={{ position: 'relative', display: 'inline', cursor: 'pointer' }}
                {...pick(props, this.dropzoneProps)}
                onDragOver={() => this.droppable = true}
                onDragLeave={() => this.droppable = false}
                onDrop={this.onDrop}
            >
                {this.renderContent(propsIconButton)}
            </MyDropzone>
        );
    }
}

/**
 * Example:
 *
 * <BulkActionsButton data-test-bulk-action-button
 *    labeled={false}
 *    label={({ key, count }) => t(`order.edit.bulkActionButton.${key}`, { count })}
 *    store={this.selectedDriverStore}
 *    afterBulkAction={() => this.selectAllChecked = false}
 *    actions={[
 *        {
 *            key: color,
 *            label: { color: color, empty: true, circular: true },
 *            text: t('planning.asset.bulkActionButton.markColor', { color: color }),
 *            action: (drivers) => {
 *                const promises = [];
 *
 *                drivers.forEach(driver => {
 *                    if (!driver.markedColors.includes(color)) {
 *                        driver.markedColors.push(color);
 *                    } else {
 *                        driver.markedColors.remove(color);
 *                    }
 *
 *                    driver.markChanged('markedColors');
 *                    promises.push(driver.save({ fields: ['markedColors'] }));
 *                });
 *
 *                return Promise.all(promises).then(showSaveNotification);
 *            },
 *        }
 *    ]}
 * />
 */
@observer
export class BulkActionsButton extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        actions: PropTypes.array.isRequired,
        actionText: PropTypes.func,
        className: PropTypes.string,
        afterBulkAction: PropTypes.func,
    }

    @observable isBulkActionLoading = false;

    afterBulkAction = () => {
        const { store, afterBulkAction } = this.props;

        this.isBulkActionLoading = false;
        store.clear();

        if (afterBulkAction) {
            afterBulkAction();
        }
    }

    renderBulkAction = ({ key, filter, action, text, disabled = false, ...props }) => {
        const { store, actionText } = this.props;

        if (!filter) {
            filter = () => true;
        }

        const count = store.filter(filter).length;

        if (text === undefined && actionText !== undefined) {
            text = actionText({ key, count });
        }

        return (
            <Dropdown.Item
                key={key}
                text={text}
                onClick={() => {
                    const tempStore = new store.constructor();
                    tempStore.models = store.filter(filter);

                    this.isBulkActionLoading = true;

                    let promise;
                    try {
                        promise = action(tempStore);
                    } catch (error) {
                        promise = Promise.reject(error);
                    }
                    if (!(promise instanceof Promise)) {
                        promise = Promise.resolve(promise);
                    }

                    return promise.catch(() => {}).then(this.afterBulkAction);
                }}
                disabled={disabled || count === 0}
                {...props}
            />
        );
    }

    render() {
        const { store, actions, className, ...rest } = this.props;

        return (
            <Dropdown compact floating button labeled {...rest}
                loading={this.isBulkActionLoading}
                className={`icon primary ${className}`}
            >
                <Dropdown.Menu>
                    {actions.map(this.renderBulkAction)}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}


const ButtonContainer = styled.div`
  display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const MinimalButton = styled(Button)`
    width: 25px;
    height: 25px;
    border-radius: 0.28571429rem !important;
    padding: 0 !important;
    background: ${props => props.primaryColor} !important;
`

const MinimalColoredText = styled.p`
    display: inline-block;
    margin-left: 10px;
    color: ${props => props.primaryColor} !important;
    font-weight: bold;
`;

@observer
export class MinimalColoredTextButton extends Button {
    static propTypes = {
        text: PropTypes.string.isRequired,
        icon: PropTypes.string,
        onClick: PropTypes.func,
        customColor: PropTypes.string,
    }

    render() {
        const {text, icon, onClick, customColor, ...props} = this.props;
        return (
            <ButtonContainer onClick={onClick} {...props}>
                {/* Padding must be added here to override semantic padding */}
                <MinimalButton compact primaryColor={customColor ? customColor : theme.primaryColor} label={false} primary size='mini' icon={icon ? icon : 'add'}/>
                <MinimalColoredText primaryColor={customColor ? customColor: theme.primaryColor}>{text}</MinimalColoredText>
            </ButtonContainer>
        )
    }

}
