import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import {lighten} from 'polished';
import TrippyBackground from 'component/TrippyBackground';
import { Body, ContentContainer, FormField, TextInput, Col, Row} from 're-cy-cle';
import { AuthenticationStore } from 'store/Authentication';
import {TranslucentSidebar} from "screen/Login/Login";
import logo from 'image/pharmilogo.svg'
import PoweredByLogo from 'component/PoweredByLogo'
import { Link } from '../spider/semantic-ui/Button';
import { Button, Message } from 'semantic-ui-react';
import {Image, Header, Form} from 'semantic-ui-react'

const ResetCodeLink = styled(Link)`
    color: #5689C5 !important;
    float: right;
    margin-right: 10px;
    padding-bottom:10px;
    &:hover {
        color: ${lighten(0.2, '#5689C5')} !important;
    }
    font-weight: bold;
`;

@observer
 class TwoFactorAuth extends Component {
    static propTypes = {
        viewStore: PropTypes.object,
    };

    @observable code = '';
    @observable hasError = false;

    handleCodeChange = (name, value) => this.code = value;

    handleSubmit = () => {
        const { viewStore } = this.props;
        return (
            new AuthenticationStore()
            .verifyCode(this.code)
            .then(() => {
                this.hasError = false;
                viewStore.fetchBootstrap();
            })
            .catch(err => {
                this.hasError = true;
            })
        );
    };

    handleReset = () => {
        const { viewStore } = this.props;
        return (
            new AuthenticationStore()
            .resetCode()
            .then(() => viewStore.fetchBootstrap())
        );
    };

    handleCancel = () => {
        const { viewStore } = this.props;
        return viewStore.performLogout();
    };

    render() {

        const { viewStore } = this.props;

        return (
            <Body>
                <ContentContainer>
                    <TrippyBackground/>
                    <TranslucentSidebar>
                        <Form>
                            <a href="/">
                                <Image style={{'margin': 'auto', 'marginTop': '10%'}} size='tiny' src={logo} />
                            </a>
                            <Header as='h2' style={{'color': '#FFFFFF'}}>{t('user.twoFactorAuth.title')}</Header>
                            <p style={{'color': '#FFFFFF'}}>{
                                viewStore.auth.status === 'waiting'
                                ? t('user.twoFactorAuth.explanation')
                                : t('user.twoFactorAuth.explanationFailed')
                            }</p>
                            {viewStore.auth.status === 'waiting' && (
                                <React.Fragment>
                                    <FormField  color='white'>
                                        <label style={{'color': 'white'}}>{t('user.twoFactorAuth.code')}</label>
                                        <TextInput
                                            name="2fa-code"
                                            type="text"
                                            value={this.code}
                                            onChange={this.handleCodeChange}
                                        />
                                    </FormField>
                                </React.Fragment>
                            )}
                            {
                                this.hasError &&
                                <Message data-test-2fa-invalid error content={"De inlogcode is verkeerd of is verlopen. Probeer het opnieuw of vraag een nieuwe code aan."} style={{"display": "block"}}/>
                            }

                            <Row>
                                <div style={{'width': '100%'}}>
                                    <ResetCodeLink  data-test-2fa-reset to='#' onClick={this.handleReset}>Request new code</ResetCodeLink>
                                </div>
                                <Col xs>
                                    <Button type="button" data-test-2fa-cancel secondary fluid content={t('form.cancelButton')} onClick={this.handleCancel} />
                                </Col>
                                {viewStore.auth.status === 'waiting' && (
                                    <Col xs>
                                        <Button data-test-2fa-submit primary fluid content={t('form.submitButton')} onClick={this.handleSubmit}/>
                                    </Col>
                                )}
                            </Row>
                        </Form>
                        <div style={{flexGrow: '1'}}/>
                        <PoweredByLogo />
                        </TranslucentSidebar>
                </ContentContainer>
            </Body>
        );
    }
}
export default TwoFactorAuth
