import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Card} from 'semantic-ui-react';
import styled from 'styled-components';
import {lighten} from 'polished';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import {theme} from 'styles';
import { DATETIME_FORMAT } from "helpers";
import {Notification} from 'store/Notification';

const NotificationCard = styled(Card)`
    box-shadow: 1px 4px 8px 2px rgba(0,0,0,0.2) !important;
    transition: cubic-bezier(.17,.67,.89,.43) 0.3s;
    width: inherit !important;

    &:hover {
      cursor: pointer;
      box-shadow: 2px 8px 16px 4px rgba(0,0,0,0.2) !important;
    }
`;

const CardHeader = styled(Card.Header)`
    font-size: 1em !important;
    font-weight:  500 !important;
`;

const CardMeta = styled(Card.Meta)`
    font-size: 0.75em !important;
    float:right;
    color: ${theme.primaryColor} !important;
`;

const CardActions = styled(Card.Content)`
    display: flex;
    justify-content: flex-end;
    padding-top: 0.25em !important;
`;

const Link = styled.a`
    color: ${theme.primaryColor} !important;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
        color: ${lighten(.1, theme.primaryColor)} !important;
        cursor: pointer;
    }
`;

@observer
export default class NotificationContainer extends Component {
    static propTypes = {
        notification: PropTypes.instanceOf(Notification).isRequired,
        onRead: PropTypes.func.isRequired
    };

    @observable dismissed=false

    handleDismiss = () => {
        const {notification, onRead} = this.props;
        notification.markSeen().catch(err => {
            console.log(err);
        });
        this.dismissed=true;
        onRead();
    }

    render() {
        const {notification} = this.props;
        const {content, createdAt, workOrder} = notification;

        // Don't continue if not all data has been fetched yet
        if (!notification.content) {
            return null;
        }

        const idx = content.indexOf('.') + 1
        const header = content.substr(0, idx)
        const description = content.substr(idx)

        const isDismissable = true, isActionable = true;
        return ( !this.dismissed &&
            <NotificationCard data-test-notification={notification.id}>
                <Card.Content>
                    <CardHeader>
                        {header}
                        <CardMeta>{createdAt.format(DATETIME_FORMAT)}</CardMeta>
                    </CardHeader>
                    <Card.Description>
                        {description}
                    </Card.Description>
                </Card.Content>
                <CardActions extra>
                    {
                        (isActionable && workOrder.id) &&
                        <Link data-test-resolve={notification.id} href={`/workorder/template/${ workOrder.id}/review`}>{t('notification.resolve')}</Link>
                    }
                    {
                        isDismissable &&
                        <Link data-test-mark-seen={notification.id} onClick={this.handleDismiss}>{t('notification.markRead')}</Link>
                    }
              </CardActions>
            </NotificationCard>
        );
    }
}
