import {Model, Store} from 'store/Base';
import {WorkOrder} from 'store/WorkOrder';
import {CheckContent} from 'store/CheckContent';
import {CheckSignOffStore} from 'store/CheckSignOff';
import {IssueStore} from 'store/Issue';
import {IssueReplyStore} from 'store/IssueReply';
import {observable} from 'mobx';

export const STATUS_DONE = 'done';

export class WorkOrderCheck extends Model {
    static backendResourceName = 'work_order_check';

    @observable id = null;

    // Get signoffs that are not retrected
    get currentSignOffs() {
        return this.checkSignOffs.models.filter(signOff => signOff.retractMoment === null);
    }

    getUserCheck(userId = this.workOrder.getAssigneeId()) {
        const models = this.checkSignOffs.models;
        for (let index = 0; index < models.length; index++) {
            const signOff = models[index];
            if (signOff.user.id === userId && signOff.retractMoment === null) {
                return signOff;
            }
        }

        return null;
    }

    hasUserChecked(userId = this.workOrder.getAssigneeId()) {
        return this.getUserCheck(userId) !== null;
    }

    hasUserAccepted(user) {
        const userCheck = this.getUserCheck(user.id);
        if (userCheck === null) {
            return false;
        }

        return userCheck.status === 'done';
    }

    hasUserRejected(user) {
        const userCheck = this.getUserCheck(user.id)
        if (userCheck === null) {
            return false;
        }

        return userCheck.status === 'rework';
    }

    isUserStuck(user) {
        const userCheck = this.getUserCheck(user.id);
        if (userCheck === null) {
            return false;
        }

        console.log(userCheck);

        return userCheck.status === 'stuck';
    }


    hasLevelSignedOff(level) {
        return this.currentSignOffs.some((signOff) => signOff.level === level);
    }

    getLevelStatus(level) {
        if (this.hasLevelSignedOff(level)) {
            const result = this.currentSignOffs.find((signOff) => signOff.level === level);
            if (result) {
                return result.status;
            }
        }
    }

    hasCheckSignedOff(level, status) {
        return this.currentSignOffs.some((signOff) => signOff.level === level && signOff.status === status);
    }

    hasCheckAlready(level, status, statuses) {
        let other = statuses.filter(s => s !== status);
        return other.some(s => this.hasCheckSignedOff(level, s))
    }

    signOff(status) {
        // const data = new FormData();
        // data.append('status', status);
        return this.api.post(`${this.url}sign_off/`, {'status': status},
            {
                params: {
                    with:
                        this.checkSignOffs.__activeRelations
                            .map(this.constructor.toBackendAttrKey)
                            .join(',') || null,
                }
            }
        )
    }

    deleteSignOff() {
        return this.getUserCheck().delete();
    }

    relations() {
        return {
            workOrder: WorkOrder,
            checkContent: CheckContent,
            checkSignOffs: CheckSignOffStore,
            issues: IssueStore,
            issueReplies: IssueReplyStore
        };
    }


    // Check if a user needs to sign of this workOrderCheck
    needsUserSignOff(user) {
        const userId = user.id;

        if (this.workOrder.builder && userId === this.workOrder.builder.id) {
            return this.needsBuilderSignOff && !this.hasBuilderSignedOff;
        }

        if (this.workOrder.juniorReviewer && userId === this.workOrder.juniorReviewer.id) {
            return this.needsJuniorReviewerSignOff && !this.hasJuniorReviewerSignedOff;
        }

        if (this.workOrder.seniorReviewer && userId === this.workOrder.seniorReviewer.id) {
            return this.needsSeniorReviewerSignOff && !this.hasSeniorReviewerSignOff;
        }


        return false;

    }

    get hasBuilderSignedOff() {
        return this.currentSignOffs.filter(signOff => signOff.level === 'builder').length > 0;
    }

    get hasJuniorReviewerSignedOff() {
        return this.currentSignOffs.filter(signOff => signOff.level === 'junior_reviewer').length > 0;
    }

    get hasSeniorReviewerSignOff() {
        return this.currentSignOffs.filter(signOff => signOff.level === 'senior_reviewer').length > 0;
    }

    canBuilderSignOff(user) {
        // Check if the builder can sign off a check.
        // The builder can only sign off a check if they are the assigned builder, and the workordercheck is in the
        // start state
        if (!this.workOrder.builder) {
            return false;
        }
        return user.id === this.workOrder.builder.id && this.workOrder.state === 'start';
    }


    get needsBuilderSignOff() {
        // Check if the builder needs to sign off this content
        return this.checkContent.needsSignOffBuilder;
    }


    canJuniorReviewerSignOff(user) {
        if (
            this.issues.find(issue => issue.type === 'feedback' && !issue.isResolved && issue.creator.groups.find(group => group.name === 'junior_reviewer'))) {
            return false;
        }

        console.log("JA")

        return true;
    }

    canJuniorReviewerRework(user) {
        // Junior reviewer can check if the state is wait_for_junior_review or junior_review_busy
        if (!this.workOrder.juniorReviewer) {
            return false;
        }

        return user.id === this.workOrder.juniorReviewer.id && this.workOrder.state === 'junior_review_busy';
    }


    get needsJuniorReviewerSignOff() {
        return this.checkContent.needsSignOffJuniorReviewer;
    }

    canSeniorReviewerSignOff(user) {
        if (this.issues.find(issue => issue.type === 'feedback' && !issue.isResolved)) {
            return false;
        }
        return this.canSeniorReviewerRework(user);

    }

    canSeniorReviewerRework(user) {
        if (!this.workOrder.seniorReviewer) {
            return false;
        }
        return user.id === this.workOrder.seniorReviewer.id && this.workOrder.state === 'senior_review_busy';
    }

    get needsSeniorReviewerSignOff() {
        return this.checkContent.needsSignOffSeniorReviewer;
    }

    get availableAction() {
        // for a workordercheck there is always exactly one available action.

        // First check fi there is a current

        // The role that currently needs an action
        const currentRole = this.workOrder.role;

        const roleSignOffs = this.currentSignOffs.filter(signOff => signOff.level === currentRole);



        const currentState = this.workOrder.state;
        // First check if the current role has signed off. If so, we can only clear
        if (roleSignOffs.length > 0 && !currentState.startsWith("stuck_")) {
            return 'clear';
        }

        // Check if we have an issue on the  current role
        const openIssues = this.issues.filter(issue => !issue.isResolved && issue.level === currentRole && issue.type !== 'remark');

        // If we have no issues, we can only say it is done
        if (!openIssues.length) {
            return 'done';
        }

        // If we have any questions, we are stuck
        if (openIssues.filter(issue => !issue.isResolved && issue.type === 'question').length) {
            return 'stuck';
        }

        // Otherwise we must have rework
        return 'rework';

    }
}

export class WorkOrderCheckStore extends Store {
    Model = WorkOrderCheck;
    static backendResourceName = 'work_order_check';
}
