import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { MessageStore } from "../../store/Message";
import ChatOverview from "./ChatOverview";
import PatientChatOverview from "./PatientChatOverview";
import { Patient } from "../../store/Patient";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

const StyledIcon = styled(Icon)`
    flex: 1;
    font-size: 16px !important;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: -20px !important;

    :hover {
        cursor: pointer;
    }

    overflow: visible;
    padding-top: 21px;
    padding-left: 365px;
`;

@observer
export default class ChatTab extends Component {

    static propTypes = {
        messageStore: PropTypes.instanceOf(MessageStore).isRequired,
        selectedPatient: PropTypes.instanceOf(Patient),
        template: PropTypes.string,
        closeChat: PropTypes.func,
    };

    render() {
        const { messageStore, selectedPatient, template, closeChat } = this.props;
        return (
            <>
                <div style={{ height: '0' }}>
                    <StyledIcon name={'close'} onClick={closeChat}/>
                </div>
                {
                    selectedPatient ?
                        <PatientChatOverview patient={selectedPatient} template = {template}
                                             backToOverview={() => window.setPatientForChat(null)}/>
                        : <ChatOverview messageStore={messageStore} setSelectedPatient={window.setPatientForChat}/>
                }
            </>
        );
    }
}
