import {Model, Store } from 'store/Base';
import {observable} from "mobx";
import { CheckContent } from 'store/CheckContent';

export class SubCheckContent extends Model {
    static backendResourceName = 'sub_check_content';

    @observable id = null;
    @observable identifier = null;
    @observable content = null;
    @observable order = null;

    relations() {
        return {
            checkContent: CheckContent,
            parent: SubCheckContent,
            children: SubCheckContentStore,
        };
    }
}

export class SubCheckContentStore extends Store {
    Model = SubCheckContent;
    static backendResourceName = 'sub_check_content';
}
