import {Model, Store} from 'store/Base';
import {observable} from "mobx";
import { TreatmentTreatmentGroupStore } from "./TreatmentTreatmentGroup";

export class TreatmentGroup extends Model {
    static backendResourceName = 'treatment_group';

    @observable id = null;
    @observable name = ''
    @observable treatmentCount = -1;

    relations() {
        return {
            treatmentTreatmentGroups: TreatmentTreatmentGroupStore,

        };
    }
}

export class TreatmentGroupStore extends Store {
    Model = TreatmentGroup;
    static backendResourceName = 'treatment_group';
}
