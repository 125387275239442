import { tint } from 'polished';

const primaryColor = '#458794';
const tintedPrimaryColor = tint(0.15, primaryColor);
const secondaryColor = '#7EC1B8';
const buttonColor = '#48b4ca';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';
const focusedFormBorderColor = '#85B7D9';

export const theme = {
    primaryColor,
    tintedPrimaryColor,
    secondaryColor,
    buttonColor,
    dangerColor,
    warningColor,
    focusedFormBorderColor,
};
