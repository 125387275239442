/**
 * Adds the children from the itemStorage to the correct parent
 * @param parent    - the parent to attach the children to
 * @param itemStorage   - the items that are connected to another item instead of to the clinical picture directly
 */

export function addChildren(parent, itemStorage) {
    const children = itemStorage.filter(item => {
        const parentId = parent.clinicalPictureInformation ? parent.clinicalPictureInformation.id : parent.id;
        // Check for the original item id, not the override
        if (item.clinicalPictureInformation){
            return item.clinicalPictureInformation.parent.id === parentId;
        } else {
            return item.parent.id === parentId
        }});
    parent.children.models = children
    parent.children.forEach(child => {
        addChildren(child, itemStorage);
    });
}

/**
 * This method takes in a clinical picture with relation 'clinicalPictureInformations' and reconstructs the tree structure.
 * Because we can have infinite depth for the children of a clinical picture we get all the attached models of a clinical picture and then rebuild te tree
 *
 * @param clinicalPicture: Takes the clinical picture as base structure
 * @param treatmentClinicalPicture: Takes the overrides from the treatment_clinical picture to override the clinical picture
 * @param treatmentId: Includes the treatmentId in the fetch request to get the information items local to this treatment.
 */
export default async function reconstructClinicalPictureTree(clinicalPicture, treatmentClinicalPicture = undefined, treatmentId = undefined) {
    const promise = new Promise(((resolve) => {
         clinicalPicture.fetch().then(() => {
        const overrides = treatmentClinicalPicture?.treatmentClinicalPictureClinicalPictureInformations.models;
        const localInformation = treatmentClinicalPicture?._localInformationForSaving?.models;
        const infoItemStorage = [];
        // Create a list of items that should not be directly attached to the condition but instead are connected to a condition information item
        clinicalPicture.clinicalPictureInformations.forEach((infoItem, index) => {
            // Add the tcp so that we can make an override if we want to modify the info item.
            infoItem._treatmentClinicalPicture = treatmentClinicalPicture;

            infoItem.cid = `r${infoItem.id}`;

            // Find the override object
            let override;
            if (overrides) {
                override = overrides.find(obj => obj.clinicalPictureInformation.id === infoItem.id);
            }

            // Or the changed item that we kept track of
            if (!override && localInformation){
                override = localInformation.find(obj => obj.id === infoItem.id);
            }

            // If we have an override, replace the model with the override
            if (override){
                override.cid = infoItem.cid
                // Add the tcp so that we can check if the override is from a parent, and make a new override if so.
                override._treatmentClinicalPicture = treatmentClinicalPicture;

                if (override.clinicalPictureInformation) {
                    // Replace the children for overrides to not get an incorrect cid
                    override.clinicalPictureInformation.children = infoItem._children;
                    override.clinicalPictureInformation.parent = infoItem.parent;
                } else {
                    if (!override.localToTreatment && infoItem.localToTreatment) {
                        override.localToTreatment = infoItem.localToTreatment;
                    }
                    override.parent = infoItem.parent;
                    override.children = infoItem.children;
                }

                // We add it here, so that it will be removed later when needed
                clinicalPicture.clinicalPictureInformations.models[index] = override;
            }

            // If the (original) model has a parent, add it the item to the storage
            if (infoItem.parent?.id && !infoItem.parent?.isNew) {
                // If there is an override add the override to the list instead of the item
                override ? infoItemStorage.push(override) : infoItemStorage.push(infoItem)
            } else {
                // We need the clinical picture to jump between previews
                if (override){
                    override.clinicalPicture = clinicalPicture;
                } else {
                    infoItem.clinicalPicture = clinicalPicture;
                }
            }
        });

        // Remove these items as a direct connection from clinical picture
        infoItemStorage.forEach(infoItem => {
            clinicalPicture.clinicalPictureInformations.models.remove(infoItem)
        });

        // Now rebuild the tree
        clinicalPicture.clinicalPictureInformations.forEach((infoItem) => {
            // Children are the items for which parent is this item
            addChildren(infoItem, infoItemStorage)
        });

        // if (treatmentClinicalPicture) {
        //     treatmentClinicalPicture._forceUpdate()
        // }

        //  !!!!!!!!!!! This is now fixed in DnDAccordion directly !!!!!!!!!!!!!!!!!
        // const fixOrdering = model => {
        //     model._children.comparator = 'ordering';
        //     model._children.sort();
        //     model._children.forEach(child => fixOrdering(child));
        // };
        // fixOrdering(clinicalPicture);
        resolve('Reconstruct Complete')

    })
    }));


    return promise;
}
