import {observable} from 'mobx';
import {QuestionStore} from "./Question";
import {ResponseStore} from "./Response";
import { Questionnaire } from "./Questionnaire";
import { DndInternalNodeModel, DndInternalNodeStore } from "../spider/store/DndAccordion";


export class Section extends DndInternalNodeModel {

    static backendResourceName = 'section';

    @observable id = null;
    @observable title = '';
    @observable ordering = 0;

    get _children(){
        return this.questions;
    }

    get _parent(){
        return this._parent;
    }

    relations() {
        return {
            questionnaire: Questionnaire,
            questions: QuestionStore,
            responses: ResponseStore,
        }
    }

}

export class SectionStore extends DndInternalNodeStore {
    Model = Section;
    static backendResourceName = 'section';
}
