import { observable } from 'mobx';
import { Casts, Model, Store } from "./Base";
import { Customer } from "./Customer/Customer";
import { Patient } from "./Patient";
import { User } from "./User";
import { subscribe } from "./Base";

export class Message extends Model {

    static backendResourceName = 'message';

    @observable id = null;
    @observable content = '';
    @observable sentAt = null;
    @observable seenAt = null;

    refresh(data) {
        this.fetch()
    }

    casts() {
        return {
            sentAt: Casts.luxonDatetime,
            seenAt: Casts.datetime,
        };
    }

    relations() {
        return {
            customer: Customer,
            patient: Patient,
            sentBy: User,
        }
    }

}

export class MessageStore extends Store {
    Model = Message;
    static backendResourceName = 'message';

    @observable unreadCount = 0;

    refreshOverviewData(data) {
        const content = data.data;

        if (content.type === 'read' || content.type === 'unread') {
            this.initializeMessageOverviewDataForCustomer(window.viewStore.currentUser.pharmacist.customer)
        } else {

            // Update unreadCount
            this.getUnreadCount();

            const message = this.find(msg => msg.patient.id === content.patient);

            if (message) {
                message.parse(content);
                if (!content['seen_at']) {
                    message.patient.unreadCount += 1;
                }
                this.sort()
            } else if (content.type === 'message') {
                const newMessage = this.add(content)
                newMessage.patient.id = content.patient
                newMessage.patient.fetch().then((response) => {
                    newMessage.patient.unreadCount = content['seen_at'] ? 0 : 1;
                })

            }
        }
    }

    /**
     * Set's the amount of unread messages
     */
    getUnreadCount() {
        this.api.get(`/message/unread_count/`).then((res) => {
            this.unreadCount = res['total'];
        });
    }

    /**
     * Subscribes to all chats for a specific customer
     * @param customer
     */
    subscribeToMessageOverviewData(customer) {
        if (!this.messageOverviewDataSubscription) {
            this.messageOverviewDataSubscription = subscribe({ "type": "chat", "customer": customer.id, "patient": "*" }, this.refreshOverviewData.bind(this));
        }
    }

    /**
     * Unsubscribes the current messageOverview subscription
     */
    unsubscribeFromMessageOverviewData() {
        if (this.messageOverviewDataSubscription) {
            this.messageOverviewDataSubscription.unsubscribe();
            this.messageOverviewDataSubscription = null;
        }
    }

    /**
     * Initializes the MessageOverviewData with it's subscription
     */
    initializeMessageOverviewDataForCustomer(customer) {
        this.api.get(`/message/group_by_patient/`).then((res) => {
            Object.entries(res).forEach(([key, message]) => {
                const storedMessage = this.find(mes => mes.patient.id === message.patient.id);
                if (storedMessage) {
                    storedMessage.parse(message)
                } else {
                    this.add(message)
                }
            })
            this.sort();
            // this.constructor()
            this.subscribeToMessageOverviewData(customer)
        });

        // Init the total amount of unread messages
        this.getUnreadCount();

    }

    refreshStore(data, afterUpdateFunction) {
        this.fetch().then(afterUpdateFunction)
    }


    /**
     * Subscribes to a chat from a customer and a patient
     * @param customer
     * @param patient
     * @param afterUpdateFunction
     */
    subscribeToChat(customer, patient, afterUpdateFunction) {
        return subscribe({ "type": "chat", "customer": customer.id, "patient": patient.id }, this.refreshStore.bind(this, afterUpdateFunction));
    }

    /**
     * Unsubscribe a subscription
     * @param subscription
     */
    unsubscribe(subscription) {
        subscription.unsubscribe();
    }




}
