import {observable} from 'mobx';
import {Response} from "./Response";
import {Model, Store, Casts} from 'store/Base';
import { Patient } from './Patient';
import {User} from "./User";
import {SignalCommentStore} from "./SignalComment";

export class Signal extends Model {

    static backendResourceName = 'signal';
    
    @observable id = null;
    @observable handledAt = null;

    casts() {
        return {
            handledAt: Casts.datetime,
        };
    }

    relations() {
        return {
            handledBy: User,
            response: Response,
            patient: Patient,
            signalComments: SignalCommentStore,
        }
    }

}

export class SignalStore extends Store {
    Model = Signal;
    static backendResourceName = 'signal';
}
