import { Model, Store } from 'store/Base';
import { observable } from "mobx";
import { Casts } from "../Base";
import { PatientTreatmentMedicationSchedule } from "./PatientTreatmentMedicationSchedule";
import { MedicationSchedulePhase } from "./MedicationSchedulePhase";

export class PatientTreatmentMedicationSchedulePhase extends Model {
    static backendResourceName = 'patient_treatment_medication_schedule_phase';

    @observable id = null;
    @observable startDate;
    @observable finalDate;

    casts() {
        return {
            startDate: Casts.date,
            finalDate: Casts.date,
        };
    }

    relations() {
        return {
            patientTreatmentMedicationSchedule: PatientTreatmentMedicationSchedule,
            medicationSchedulePhase: MedicationSchedulePhase,
        };
    }
}

export class PatientTreatmentMedicationSchedulePhaseStore extends Store {
    Model = PatientTreatmentMedicationSchedulePhase;
    static backendResourceName = 'patient_treatment_medication_schedule_phase';
}
