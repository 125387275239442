import {Model, Store} from 'store/Base';
import {observable} from "mobx";
import { CustomerCustomerGroupStore } from "./CustomerCustomerGroup";
import { TreatmentStore } from "../Treatment";

export class CustomerGroup extends Model {
    static backendResourceName = 'customer_group';

    @observable id = null;
    @observable name = null;
    @observable customerCount = -1;

    relations() {
        return {
            customerCustomerGroups: CustomerCustomerGroupStore,
            paths: TreatmentStore,
        };
    }
}

export class CustomerGroupStore extends Store {
    Model = CustomerGroup;
    static backendResourceName = 'customer_group';
}
