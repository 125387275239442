import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import {Treatment} from "./Treatment";
import {Patient} from "./Patient";
import {Casts} from "./Base";
import {
    PatientTreatmentMedicationScheduleStore
} from "./MedicationSchedule/PatientTreatmentMedicationSchedule";


export class PatientTreatment extends Model {

    static backendResourceName = 'patient_treatment';

    static omitFields = ['medicationScheduleStartDate']

    @observable id = null;
    // @observable illness = null;
    @observable firstUsageDate = null;
    @observable notes = '';
    @observable medicationScheduleStartDate = null;



    relations() {
        return {
            treatment: Treatment,
            patient: Patient,
            patientTreatmentMedicationSchedules: PatientTreatmentMedicationScheduleStore,
        };
    }

    casts() {
        return {
            firstUsageDate: Casts.date,
            medicationScheduleStartDate: Casts.date
        };
    }

    get notCustomMedicationSchedules() {
        return this.patientTreatmentMedicationSchedules.filter(ptms => !ptms.custom)
    }

}

export class PatientTreatmentStore extends Store {
    Model = PatientTreatment;
    static backendResourceName = 'patient_treatment';
}
