import { observable, computed } from 'mobx';
import { TreatmentClinicalPicture } from "./TreatmentClinicalPicture";
import { ClinicalPictureInformationModel } from "./ClinicalPictureInformation";
import { DndNodeModel, DndNodeStore } from "../../spider/store/DndAccordion";
import { Image } from "../Image";
import reconstructClinicalPictureTree from "../../helpers/reconstructClinicalPictureTree";

export class TreatmentClinicalPictureClinicalPictureInformation extends DndNodeModel {

    static backendResourceName = 'treatment_clinical_picture_clinical_picture_information';
    static omitFields = ['ordering', 'priority', 'title', 'text', 'video', 'visible', 'healthBasePage'];


    @observable id = null;
    @observable visibleOverride = null;
    @observable titleOverride;
    @observable textOverride;
    @observable videoOverride;
    @observable healthBasePageOverride;

    _treatmentClinicalPicture

    initialize() {
        // Let the model think there is an ordering attribute as we created an interface for it
        this.__attributes.push('ordering');
        this.__attributes.push('title');
        this.__attributes.push('text');
        this.__attributes.push('video');
        this.__attributes.push('visible');
        this.__attributes.push('healthBasePage');
    }

    @computed
    get _dndCheckbox(){
        // Check for not undefined or null
        return this.visible
    }

    set _dndCheckbox(value){
        const modelInStore = this._treatmentClinicalPicture.treatmentClinicalPictureClinicalPictureInformations.get(this.id);
        if (!modelInStore){
            const override = new TreatmentClinicalPictureClinicalPictureInformation(
                { ...this.toJS(), visibleOverride: value, },
                { relations: ['clinicalPictureInformation.children', 'clinicalPictureInformation.parent'] });
            override._treatmentClinicalPicture = this._treatmentClinicalPicture;

            // Add the override to the overrides (for visualization) as well as the treatmentClinicalPictureInformations (for saving)
            this._treatmentClinicalPicture.treatmentClinicalPictureClinicalPictureInformations.models.push(override);
            this._treatmentClinicalPicture.treatmentClinicalPictureClinicalPictureInformations.__setChanged = true;
            reconstructClinicalPictureTree(this._treatmentClinicalPicture.clinicalPicture, this._treatmentClinicalPicture)
                .then(() => this._treatmentClinicalPicture._forceUpdate());
        } else {
            modelInStore.setInput('visibleOverride', value);
            this.setInput('visibleOverride', value);
        }

    }

    @computed
    get _children() {
        return this.clinicalPictureInformation?._children;
    }

    @computed
    get video(){
        if (!(this.videoOverride === undefined || this.videoOverride === null)){
            return this.videoOverride;
        }
        return this.clinicalPictureInformation?.video;
    }

    set video(value){
        this.setInput('videoOverride', value)
    }

    @computed
    get text(){
        if (!(this.textOverride === undefined || this.textOverride === null)){
            return this.textOverride;
        }
        return this.clinicalPictureInformation?.text;
    }

    set text(value){
        this.setInput('textOverride', value);
    }

    @computed
    get title(){
        if (!(this.titleOverride === undefined || this.titleOverride === null)){
            return this.titleOverride;
        }
        return this.clinicalPictureInformation?.title;
    }

    set title(value){
        this.setInput('titleOverride', value);
    }

    @computed
    get healthBasePage(){
        if (!(this.healthBasePageOverride === undefined || this.healthBasePageOverride === null)){
            return this.healthBasePageOverride;
        }
        return this.clinicalPictureInformation?.healthBasePage;
    }

    set healthBasePage(value){
        this.setInput('healthBasePageOverride', value);
    }

    @computed
    get visible(){
        if (!(this.visibleOverride === undefined || this.visibleOverride === null)){
            return this.visibleOverride;
        }
        return this.clinicalPictureInformation?.visible;
    }

    set visible(value){
        this.setInput('visibleOverride', value);
    }

    @computed
    get ordering(){
        return this.clinicalPictureInformation?.ordering;
    }

    set ordering(value){
        // We do not want to change the ordering
    }

    @computed
    get priority(){
        return this.clinicalPictureInformation?.priority;
    }

    @computed
    get parent(){
        return this.clinicalPictureInformation?.parent;
    }

    @computed
    get children(){
        return this.clinicalPictureInformation?.children;
    }

    set children(value){
        this.clinicalPictureInformation.children = value;
    }

    @computed
    get image(){
        if (this.imageOverride){
            return this.imageOverride;
        }
        return this.clinicalPictureInformation?.image;
    }

    set image(value){
        this.setInput('imageOverride', value);
    }


    relations() {
        return {
            treatmentClinicalPicture: TreatmentClinicalPicture,
            clinicalPictureInformation: ClinicalPictureInformationModel,
            imageOverride: Image,
        }
    }

}

export class TreatmentClinicalPictureClinicalPictureInformationStore extends DndNodeStore {
    Model = TreatmentClinicalPictureClinicalPictureInformation;
    static backendResourceName = 'treatment_clinical_picture_clinical_picture_information';
}
