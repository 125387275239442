import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import {Choice} from "./Choice";
import {Response} from './Response';

export class ChoiceResponse extends Model {

    static backendResourceName = 'choice_response';

    @observable id = null;


    relations() {
        return {
            response: Response,
            choice: Choice,
        }
    }

}

export class ChoiceResponseStore extends Store {
    Model = ChoiceResponse;
    static backendResourceName = 'choice_response';
}
