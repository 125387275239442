import { observable } from "mobx";
import { MedicationSchedule } from "store/MedicationSchedule";
import {
    DndInternalNodeModel,
    DndInternalNodeStore,
} from "../../spider/store/DndAccordion";
import { MedicationSchedulePhaseTimeStore } from "./MedicationSchedulePhaseTime";
import { Store } from "../Base";

export const TIME_DAY = 'day';
export const TIME_WEEK = 'week';
export const TIME_MONTH = 'month';
export const TIME_YEAR = 'year';

export const AMOUNT_PIECE = 'piece';
export const AMOUNT_TABLET = 'tablet';
export const AMOUNT_CAPSULE = 'capsule';
export const AMOUNT_DROP = 'drop';
export const AMOUNT_MILLIGRAM = 'milligram';

export const MEDICATION_SCHEDULE_TIME_UNIT = [
    TIME_DAY,
    TIME_WEEK,
    TIME_MONTH,
    TIME_YEAR
];

export const MEDICATION_SCHEDULE_AMOUNT_UNIT = [
    AMOUNT_PIECE,
    AMOUNT_TABLET,
    AMOUNT_CAPSULE,
    AMOUNT_DROP,
    AMOUNT_MILLIGRAM,
]

export class MedicationSchedulePhase extends DndInternalNodeModel {
    static backendResourceName = 'medication_schedule_phase';

    static omitFields = [
        'title',
    ]

    @observable id = null;
    @observable ordering;
    @observable duration = 0;
    @observable durationUnit;
    @observable amount;
    @observable amountUnit;
    @observable dailyIntake;
    @observable frequencyUnit;
    @observable frequencyTime;
    @observable frequencyUseTime;
    @observable frequencyStopTime;
    @observable cyclusNoUseTime;
    @observable weekUseDay1 = false;
    @observable weekUseDay2 = false;
    @observable weekUseDay3 = false;
    @observable weekUseDay4 = false;
    @observable weekUseDay5 = false;
    @observable weekUseDay6 = false;
    @observable weekUseDay7 = false;

    @observable summarySentence;

    /**
     * We need to override the to backend to add an underscore before the number as the CameltoSnake does not do that properly
     * @param attrKey
     */
    static toBackendAttrKey(attrKey){
        if (attrKey.startsWith('weekUseDay')){
            attrKey = attrKey.replace('weekUseDay', 'weekUseDay_')
        }
        return super.toBackendAttrKey(attrKey);
    }

    get _children() {
        // We don't have children, so it is always an empty store
        return new Store();
    }

    get _parent() {
        return this.medicationSchedule;
    }

    /**
     * The duration checkbox property is used to check if the checkbox is checked
     *
     * @returns {boolean}
     * @private
     */
    get durationDisabled(){
        return this.duration === null;
    }

    /**
     * The set of the durationCheckbox is to change the state of the duration checkbox
     * @param value
     * @private
     */
    set durationDisabled(value){
        this.setInput('duration',  value ? null : 0);
        this.setInput('durationUnit', value ? null : undefined);
    }

    relations() {
        return {
            medicationSchedule: MedicationSchedule,
            times: MedicationSchedulePhaseTimeStore,
        }
    }
}

export class MedicationSchedulePhaseStore extends DndInternalNodeStore {
    comparator = 'ordering'
    Model = MedicationSchedulePhase;
    static backendResourceName = 'medication_schedule_phase';
}
