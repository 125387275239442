import { Model, Store } from 'store/Base';
import { observable } from "mobx";
import { Treatment } from "../Treatment";
import { MedicationSchedule } from "store/MedicationSchedule";

export class TreatmentMedicationSchedule extends Model {
    static backendResourceName = 'treatment_medication_schedule';

    @observable id = null;

    relations() {
        return {
            treatment: Treatment,
            medicationSchedule: MedicationSchedule,
        };
    }
}

export class TreatmentMedicationScheduleStore extends Store {
    Model = TreatmentMedicationSchedule;
    static backendResourceName = 'treatment_medication_schedule';
}
