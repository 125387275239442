import {observable} from 'mobx';
import { Treatment } from "../Treatment";
import { ClinicalPictureModel } from "../ClinicalPicture";
import {Model, Store} from 'store/Base';
import { TreatmentClinicalPictureClinicalPictureInformationStore } from "./TreatmentClinicalPictureClinicalPictureInformation";

export class TreatmentClinicalPicture extends Model {

    static backendResourceName = 'treatment_clinical_picture';

    @observable id = null;
    @observable ordering = 99;

    relations() {
        return {
            treatment: Treatment,
            clinicalPicture: ClinicalPictureModel,
            treatmentClinicalPictureClinicalPictureInformations: TreatmentClinicalPictureClinicalPictureInformationStore,
        }
    }

}

export class TreatmentClinicalPictureStore extends Store {
    Model = TreatmentClinicalPicture;
    static backendResourceName = 'treatment_clinical_picture';
}
