import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Customer } from "./Customer";
import { MetabaseDashboard } from "./MetabaseDashboard";

export class CustomerDashboard extends Model {

    static backendResourceName = 'customer_dashboard';
    @observable id = null;
    
    relations() {
        return {
            customer: Customer,
            dashboard: MetabaseDashboard,
        };
    }
}

export class CustomerDashboardStore extends Store {
    Model = CustomerDashboard;
    static backendResourceName = 'customer_dashboard';
}
