import {Model, Store} from 'store/Base';
import {WorkOrder} from 'store/WorkOrder';
import {User} from "store/User";
import {observable} from 'mobx';

export class WorkOrderSignOff extends Model {
    static backendResourceName = 'work_order_sign_off';

    @observable id = null

    relations() {
        return {
            workOrder: WorkOrder,
            user: User,
        };
    }
}

export class WorkOrderSignOffStore extends Store {
    Model = WorkOrderSignOff;
    static backendResourceName = 'work_order_sign_off';
}
