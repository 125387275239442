import { Model, Store } from 'store/Base';
import { observable } from "mobx";
import { MedicationSchedule } from "../MedicationSchedule";
import { PatientTreatment } from "../PatientTreatment";
import { PatientTreatmentMedicationSchedulePhaseStore } from "./PatientTreatmentMedicationSchedulePhase";

export class PatientTreatmentMedicationSchedule extends Model {
    static backendResourceName = 'patient_treatment_medication_schedule';
    static omitFields = ['inUse'];

    @observable id = null;
    // @observable time;
    @observable sendNotification;
    // Annotation to check if schedule is in use
    @observable inUse = false;

    @observable custom = false;

    // casts() {
    //     return {
    //         time: Casts.datetime
    //     };
    // }

    relations() {
        return {
            patientTreatment: PatientTreatment,
            medicationSchedule: MedicationSchedule,
            patientTreatmentMedicationSchedulePhases: PatientTreatmentMedicationSchedulePhaseStore,
        };
    }
}

export class PatientTreatmentMedicationScheduleStore extends Store {
    Model = PatientTreatmentMedicationSchedule;
    static backendResourceName = 'patient_treatment_medication_schedule';
}
