import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import { Question } from './Question';
import { Moment } from './Moment';
import { SignalConditionChoiceStore } from './SignalConditionChoice';

export const SIGNAL_CONDITION_ALL = 'choices_all';
export const SIGNAL_CONDITION_ONE = 'choices_one_of';
export const SIGNAL_CONDITION_NUMBER_RANGE = 'number_range';

export class SignalCondition extends Model {

    static backendResourceName = 'signal_condition';

    @observable id = null;
    @observable type = SIGNAL_CONDITION_ONE;
    @observable numberFrom;
    @observable numberTo;

    relations() {
        return {
            question: Question,
            moment: Moment,
            signalConditionChoices: SignalConditionChoiceStore
        };
    }
}

export class SignalConditionStore extends Store {
    Model = SignalCondition;
    static backendResourceName = 'signal_condition';
}
