import { Model, Store, Casts } from 'store/Base';
import { observable } from "mobx";
import { WorkOrder } from 'store/WorkOrder';
import { User } from "store/User";
import { IssueReplyStore } from "store/IssueReply";
import { WorkOrderCheck } from 'store/WorkOrderCheck';

export class Issue extends Model {
    static backendResourceName = 'issue';

    @observable id = null;
    @observable description = '';
    @observable createdAt = null;
    @observable type = null;
    @observable isResolved = null;
    @observable resolvedAt = null;
    @observable finalized = false;
    @observable level = null;

    resolve() {
        return this.api.post(`${this.url}resolve/`);
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            resolvedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            workOrder: WorkOrder,
            creator: User,
            workOrderCheck: WorkOrderCheck,
            issueReplies: IssueReplyStore
        };
    }
}

export class IssueStore extends Store {
    Model = Issue;
    static backendResourceName = 'issue';
}
