export const SUPERUSER = 'superuser';
export const ADMIN = 'admin';
export const JUNIOR_REVIEWER = 'junior_reviewer';
export const SENIOR_REVIEWER = 'senior_reviewer';
export const BUILDER = 'builder';
export const PHARMACIST = 'pharmacist';
export const PATIENT = 'patient';

export const GROUPS = [
    SUPERUSER, ADMIN, JUNIOR_REVIEWER, SENIOR_REVIEWER, BUILDER, PHARMACIST, PATIENT
]



