import { Casts } from 'store/Base';
import { observable } from "mobx";
import { DndRootModel, DndRootStore } from "spider/store/DndAccordion";
import { MedicationSchedulePhaseStore } from "./MedicationSchedule/MedicationSchedulePhase";
import { TreatmentMedicationScheduleStore } from './MedicationSchedule/TreatmentMedicationSchedule';

export const MEDICATION_SCHEDULE_TYPE_LOCAL = "local";
export const MEDICATION_SCHEDULE_TYPE_GLOBAL = "global";


export class MedicationSchedule extends DndRootModel {
    static backendResourceName = 'medication_schedule';

    @observable id = null;
    @observable name = '';
    @observable lastUpdatedAt = null;
    @observable type = null;


    get _children(){
        return this.phases;
    }

    casts() {
        return {
            lastUpdatedAt: Casts.datetime,
        }
    }

    relations() {
        return {
            phases: MedicationSchedulePhaseStore,
            treatmentMedicationSchedules: TreatmentMedicationScheduleStore,
            localCopyOf: MedicationSchedule,
            localCopies: MedicationScheduleStore,
        }
    }
}

export class MedicationScheduleStore extends DndRootStore {
    Model = MedicationSchedule;
    static backendResourceName = 'medication_schedule';
}
