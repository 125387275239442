import {Model, Store} from 'store/Base';
import {observable} from "mobx";
import { Customer } from "../Customer/Customer";
import { CustomerGroup } from "./CustomerGroup";

export class CustomerCustomerGroup extends Model {
    static backendResourceName = 'customer_customer_group';

    @observable id = null;


    relations() {
        return {
            customer: Customer,
            customerGroup: CustomerGroup
        };
    }
}

export class CustomerCustomerGroupStore extends Store {
    Model = CustomerCustomerGroup;
    static backendResourceName = 'customer_customer_group';
}
