import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';
import TrippyBackground from 'component/TrippyBackground';
import {Body, ContentContainer} from 're-cy-cle';
import {Link} from 'react-router-dom';
import {theme} from 'styles';
import styled from 'styled-components';
import {lighten} from 'polished';
import {Sidebar, SidebarContent} from "../../spider/semantic-ui/Admin/Overview";
import PoweredByLogo from 'component/PoweredByLogo';
import BaseLogin from 'spider/semantic-ui/Login';
import { Image } from 'semantic-ui-react';
import LogoImage from 'image/pharmilogo.svg';

export const PrimaryLinkContainer = styled.div`
    text-align: right;
    margin-top: -0.25rem;
    margin-bottom: 0.75rem;
`;

export const PrimaryLink = styled(Link)`
    color: ${theme.primaryColor};
    &:hover {
        color: ${lighten(0.1, theme.primaryColor)};
    }
    font-weight: bold;
`;

export const TranslucentSidebar = styled(Sidebar)`
    background-color: #1B3C4E;
    ${SidebarContent} {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

`;

const ForgotPasswordLink = styled(Link)`
    color: white;
    &:hover {
        color: #448896;
    }
    font-weight: bold;
`;



@observer
export default class Login extends BaseLogin {
    static propTypes = {
        viewStore: PropTypes.object,
        usernameField: PropTypes.string.isRequired,
    };
    constructor(props) {
        super(props)

        this.state = {
            registerLoading: false
        }
        this.handleRegister = this.handleRegister.bind(this)
    }

    static defaultProps = {
        usernameField: 'email',
    };
    labelColor = 'white'

    // HANDLE CSRF failures as well


    renderForgotPasswordLink() {
        return (
            <PrimaryLinkContainer>
                <ForgotPasswordLink to="/login/forgot" data-test-forgot-password-link>
                    {t('user.login.forgotPasswordLink')}
                </ForgotPasswordLink>
            </PrimaryLinkContainer>
        );
    }

    // HANDLE CSRF failures as well
    handleSubmit = () => {
        const {usernameField, afterLogin, viewStore} = this.props;

        this.errorMsg = '';
        this.props.viewStore
            .performLogin(this.input[usernameField], this.input.password, {usernameField: usernameField})
            .then(afterLogin)
            .catch(err => {
                const status = err.response && err.response.status;
                if (status === 403) {
                    if (err.response.data.code === 'csrf') {
                        this.errorMsg = 'Even geduld, er wordt een veiligheidscheck uitgevoerd.';

                        viewStore.fetchBootstrap().then(this.handleSubmit)

                        return;
                    }
                    this.errorMsg = t('user.login.errors.invalidCredentials');
                } else {
                    console.error(err);
                    this.errorMsg = t('user.login.errors.unknown', {
                        status: status || err.message,
                    });
                }
            });
    };


    renderBranding() {
        return <PoweredByLogo/>
    }

    handleRegister(e) {
        e.preventDefault()
        this.setState({registerLoading: true})

    }

    render() {
        return (
            <Body>
                <ContentContainer>
                    <TrippyBackground/>
                    <TranslucentSidebar>
                        <div style={{textAlign: 'center'}}>
                            <Image src={LogoImage} width="160px" style={{display: 'inline-block', marginTop: '24px'}}/>
                        </div>
                        {this.renderForm()}
                        <div style={{flexGrow: '1'}}/>
                        {this.renderBranding()}
                    </TranslucentSidebar>
                </ContentContainer>
            </Body>
        );
    }
}
