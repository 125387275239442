import {observable, computed} from 'mobx';
import {Phase} from './Phase'
import {Moment} from './Moment'
import { DndLeafModel, DndLeafStore } from "../spider/store/DndAccordion";

export class PhaseMoment extends DndLeafModel {

    static backendResourceName = 'phase_moment';

    @observable id = null;
    @observable ordering = 0;
    @observable disabled = false;

    @computed
    get title(){
        return this.moment?.title;
    }

    // Get the checkbox value
    get _dndCheckbox() {
        return !this.disabled;
    }

    // Set the checbox value
    set _dndCheckbox(value){
        this.setInput('disabled', !value)
    }

    /**
     * Returns the locked state of the related moment
     * Used for DndAccordion
     */
    get isLocked() {
        return this.moment.isLocked;
    }

    /**
     * Returns the createdIn of the related moment
     * Used for DndAccordion
     */
    get createdIn(){
        return this.moment.createdIn;
    }

    get icon(){
        return this.moment?.icon;
    }

    relations() {
        return {
            phase: Phase,
            moment: Moment,
        }
    }
 }

export class PhaseMomentStore extends DndLeafStore {
    Model = PhaseMoment;
    static backendResourceName = 'phase_moment';
    comparator = "ordering";
}
