import {Model, Store } from 'store/Base';
import {observable} from "mobx";
import { SubCheckContentStore } from 'store/SubCheckContent';

export class CheckContent extends Model {
    static backendResourceName = 'check_content';

    @observable id = null;
    @observable identifier = null;
    @observable title = null;
    @observable isActive = null;
    @observable needsSignOffBuilder = false;
    @observable needsSignOffJuniorReviewer = false;
    @observable needsSignOffSeniorReviewer = false;
    @observable order = null;

    relations() {
        return {
            subChecks: SubCheckContentStore,
        };
    }

}

export class CheckContentStore extends Store {
    Model = CheckContent;
    static backendResourceName = 'check_content';
}
