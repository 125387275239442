import {Model, Store} from 'store/Base';
import {observable} from "mobx";
import {User} from "./User";

export class CommunicationLog extends Model {
    static backendResourceName = 'communication_log';
    @observable id;
    @observable timestamp;
    @observable event;

    
    relations() {
        return {
            performedBy: User,
            performedOn: User,
        };
    }
}

export class CommunicationLogStore extends Store {
    Model = CommunicationLog;
    static backendResourceName = 'communication_log';
}