import {Model, Store} from 'store/Base';
import {computed, observable} from 'mobx';
import {ClinicalPictureInformationModel} from "./ClinicalPicture/ClinicalPictureInformation";
import {concat, remove} from "lodash";
import { MomentStore } from './Moment';

export const ACTION_NO_ACTION = 'no_action';
export const ACTION_WEBSITE = 'website';
export const ACTION_MEDICATION_TAB = 'medication_information'; // This is correct
export const ACTION_CONTACT_TAB = 'contact_tab';
export const ACTION_SETTINGS_TAB = 'settings_tab';
export const ACTION_REPORTS_TAB = 'reports_tab';
export const ACTION_CLINICAL_PICTURE_ITEM = 'clinical_picture_item';
export const ACTION_APP_REVIEW = 'app_review';

export const ACTIONS = [
    ACTION_NO_ACTION,
    ACTION_WEBSITE,
    ACTION_MEDICATION_TAB, // This links to the medication tab
    ACTION_CLINICAL_PICTURE_ITEM,
    ACTION_CONTACT_TAB,
    ACTION_SETTINGS_TAB,
    ACTION_REPORTS_TAB,
    ACTION_APP_REVIEW,
];

/*
 * The clinical picture action has subtypes. It can either link to the medication library or the indication library
 *
 * In the datamodel they are both mapped to the clinical picture item
 */
export const CLINICAL_PICTURE_ACTION_MEDICATION = 'clinical_picture_item_medication';
export const CLINICAL_PICTURE_ACTION_INDICATION = 'clinical_picture_item_indication';

export const CLINICAL_PICTURE_ACTION_TYPES = [
    CLINICAL_PICTURE_ACTION_MEDICATION,
    CLINICAL_PICTURE_ACTION_INDICATION,
];


export const EXTENDED_ACTIONS = concat(
    remove(ACTIONS, action => action !== ACTION_CLINICAL_PICTURE_ITEM),
    CLINICAL_PICTURE_ACTION_TYPES,
);



export class MomentAction extends Model {

    static backendResourceName = 'moment_action';

    @observable id = null;
    @observable buttonTitle = '';
    @observable type = ACTION_NO_ACTION;
    @observable link = '';

    @observable _subtype = null;

    @computed get extendedType() {
        if (this.type === ACTION_CLINICAL_PICTURE_ITEM) {

            if (this._subtype) {
                return this._subtype;
            }

            const clinicalPictureTyupe = this.clinicalPictureInformation?.clinicalPicture?.type;

            if (clinicalPictureTyupe === 'indication') {
                return CLINICAL_PICTURE_ACTION_INDICATION
            }

            return CLINICAL_PICTURE_ACTION_MEDICATION;
        }
        return this.type;
    }

    setInput(name, value) {
        if (name === 'extendedType') {
            name = 'type';

            if (CLINICAL_PICTURE_ACTION_TYPES.includes(value)) {
                this._subtype = value;
                value = ACTION_CLINICAL_PICTURE_ITEM;
            }
        }
        return super.setInput(name, value)

    }

    relations() {
        return {
            clinicalPictureInformation: ClinicalPictureInformationModel,
            moment: MomentStore
        };
    }
}

export class MomentActionStore extends Store {
    Model = MomentAction;
    static backendResourceName = 'moment_action';
}
