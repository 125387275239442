import {observable} from 'mobx';
import { User } from "./User";
import { Casts } from "./Base";
import { SectionStore } from "./Section";
import { MomentStore } from "./Moment";
import { DndRootModel, DndRootStore } from "../spider/store/DndAccordion";

export class Questionnaire extends DndRootModel {

    static backendResourceName = 'questionnaire';


    @observable id = null;
    @observable name = '';
    @observable lastUpdatedAt = null;
    @observable type = '';

    get _children(){
        return this.sections;
    }

    relations() {
        return {
            lastUpdatedBy: User,
            sections: SectionStore,
            moments: MomentStore,
        }
    }

    casts() {
        return {
            lastUpdatedAt: Casts.datetime
        };
    }

}

export class QuestionnaireStore extends DndRootStore {
    Model = Questionnaire;
    static backendResourceName = 'questionnaire';
}
