import React from 'react';

import { observable } from 'mobx';
import { observer } from "mobx-react";
import Login from "../spider/semantic-ui/Login";
import { Modal, Form, Button, Dimmer, Loader } from "semantic-ui-react";
import { FormField, TextInput } from 're-cy-cle';
import { AuthenticationStore } from 'store/Authentication';


@observer
export class LoginModal extends Login {

    @observable isLoading = false;

    renderForgotPasswordLink() {
        return null;
    }

    render() {
        return (
            <Modal open
                   data-test-login-modal
                   centered={false}
                   size="mini"
            >
                <Modal.Header>{t('user.login.title')}</Modal.Header>
                <Dimmer.Dimmable as={Modal.Content}>
                    <Dimmer blurring inverted active={this.isLoading}>
                        <Loader/>
                    </Dimmer>
                    <p>{t('user.login.reloginMessage')}</p>
                    {this.renderForm()}
                </Dimmer.Dimmable>
            </Modal>
        );
    }


    handleSubmit = () => {
        if(this.isLoading) {
            return;
        }

        const { usernameField, afterLogin, viewStore } = this.props;

        this.isLoading = true;
        this.errorMsg = '';
        this.props.viewStore
            .performLogin(this.input[usernameField], this.input.password, { usernameField: usernameField })
            .then(afterLogin)
            .catch(err => {
                const status = err.response && err.response.status;

                if (status === 403) {
                    if (err.response.data.code === 'csrf') {
                        this.errorMsg = t('user.login.safetyCheck.text');
                        return viewStore.fetchBootstrap().then(this.handleSubmit);
                    }
                    this.errorMsg = t('user.login.errors.invalidCredentials');
                } else {
                    this.errorMsg = t('user.login.errors.unknown', {
                        status: status || err.message,
                    });
                }
            })
            .finally(() => this.isLoading = false);
    };

}

@observer
export class TwoFactorModal extends Login {
    @observable code = '';
    @observable errorMsg = '';

    authenticationStore = new AuthenticationStore();

    render() {
        return (
            <Modal open
                   centered={false}
                   data-test-two-factor-modal
                   size="mini"
            >
                <Modal.Header>{t('user.twoFactorAuth.title')}</Modal.Header>

                <Modal.Content>
                    <p>{t('user.twoFactorAuth.explanation')}</p>
                    <Form>
                    <FormField color='white'>
                        <label style={{fontWeight: 'bold'}}>{t(`user.twoFactorAuth.code`)}</label>
                        <TextInput
                            name="2fa-modal-code"
                            type="text"
                            value={this.code}
                            onChange={(name, value) => this.code = value}
                        />
                    </FormField>
                    <Button data-test-2fa-button primary fluid
                        loading={this.authenticationStore.isLoading}
                        content={t('form.submitButton')}
                        onClick={this.handleSubmit}
                    />
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }

    handleSubmit = () => {
        if(this.authenticationStore.isLoading) {
            return;
        }

        const { viewStore, afterLogin } = this.props;
        return (
            this.authenticationStore
            .verifyCode(this.code)
            .then(() => viewStore.fetchBootstrap().then(afterLogin))
        );
    };
}
