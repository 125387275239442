import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { MessageStore } from "../../store/Message";
import { Header } from "semantic-ui-react";
import { PatientChatPreview } from "../../component/Chat/PatientChatPreview";


const SectionHeader = styled(Header)`
    height: 90px !important;
    border-bottom: 1px solid #E0E0E0 !important;
    padding: 0.7em 1em !important;
    font-size: 2.5em !important;
    margin: 0 !important;
    flex: none;
`;

const ChatMessagesStyle = styled.div`
    flex: 1;
    overflow: auto;
`;

@observer
export default class ChatOverview extends Component {

    static propTypes = {
        messageStore: PropTypes.instanceOf(MessageStore).isRequired,
        setSelectedPatient: PropTypes.func,
    };

    componentDidMount() {
        // this.props.messageStore.initializeMessageOverviewDataForCustomer(window.viewStore.currentUser.pharmacist.customer)
    }


    render() {
        const { messageStore, setSelectedPatient } = this.props;
        if (messageStore.length > 0) {
            // const keys = Object.keys(messageStore.overviewData);

            return (<>
                    <SectionHeader as="h1">
                        {t('chat.label')} {this.count > 0 ? `(${this.count} ${t('chat.new.label')})` : ''}
                    </SectionHeader>
                    <ChatMessagesStyle>
                        {messageStore.map(message => (
                            <PatientChatPreview key={message.cid} message={message} setSelectedPatient={setSelectedPatient} />
                        ))}
                    </ChatMessagesStyle>
                </>
            );
        } else {
            return <></>
        }
    }
}
