import {observable} from 'mobx';
import {MomentAction} from "./MomentAction";
import {Image} from "./Image";
import { Questionnaire } from "./Questionnaire";
import { Treatment } from "./Treatment";
import { PhaseMomentStore } from "./PhaseMoment";
import { MomentQuestionDependencyStore } from "./QuestionDependency";
import { ClinicalPictureModel } from "./ClinicalPicture";
import { PushNotificationStore } from "./Timeline/Moment/PushNotification";
import { DndInternalNodeModel, DndInternalNodeStore } from "../spider/store/DndAccordion";
import { SignalConditionStore } from './SignalCondition';

export const SPECIAL_MOMENT_TYPES = ['SMR'];

export const SHARE_BODY_COUNT = 280;

const LINK_REGEX = new RegExp('https?://\\S+', 'ig');

export class Moment extends DndInternalNodeModel {

    static backendResourceName = 'moment';

    static omitFields = ['ordering'];

    @observable id = null;
    @observable title = '';
    @observable content = '';
    @observable healthBasePage = '';
    @observable treatmentDay = null;
    @observable icon = undefined;
    @observable isLocked = false;
    @observable type = 'base';
    @observable video = '';
    @observable dependMedicationSchedule = null;
    @observable specialType = null;
    @observable shareContent = null;
    @observable shareImage = false;
    @observable sourceTitle = null;
    @observable sourceLink = null;

    get _parent(){
        return this.phaseMoments;
    }

    get ordering(){
        this.phaseMoments.forEach((phaseMoment) => {
            if (phaseMoment.phase.id === this._phaseForOrdering){
                return phaseMoment.ordering;
            }
        });

        return 0;
    }

    /**
     * Could be used as a quicker way to directly get a list of phases
     *
     * @returns []  - list of phases
     */
    get phases(){
        const phases = []
        this.phaseMoments.models.forEach((phaseMoment) => {
            phases.push(phaseMoment.phase)
        })
        // phases.add(this.phaseMoments.models.map((phaseMoment) => (phaseMoment.phase.toJS())))
        return phases
    }

    get shareContentLength() {
        if (this.shareContent === null) {
            return null;
        }
        return this.shareContent.replaceAll(LINK_REGEX, 'https://t.co/0000000000').trim().length;
    }

    relations() {
        return {
            phaseMoments: PhaseMomentStore,
            action: MomentAction,
            indication: ClinicalPictureModel,
            image: Image,
            questionnaire: Questionnaire,
            createdIn: Treatment,
            momentQuestionDependencies: MomentQuestionDependencyStore,
            pushNotifications: PushNotificationStore,
            signalConditions: SignalConditionStore,
        }
    }

    get canSave() {
        return this.treatmentDay != null && this.treatmentDay >= 0;
    }
 }

export class MomentStore extends DndInternalNodeStore {
    Model = Moment;
    static backendResourceName = 'moment';
}
