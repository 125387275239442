import {observable, computed} from 'mobx';
import { User } from "../User";
import {Casts, Store} from "../Base";
import { Image } from "../Image";
import { ClinicalPictureModel } from "../ClinicalPicture";
import { DndNodeModel, DndNodeStore } from "../../spider/store/DndAccordion";
import { TreatmentClinicalPictureClinicalPictureInformation } from "./TreatmentClinicalPictureClinicalPictureInformation";
import reconstructClinicalPictureTree from "../../helpers/reconstructClinicalPictureTree";
import { Treatment } from "../Treatment";
import {FRONTEND_API_BASE_URL} from "../../helpers";
import {BinderApi} from "mobx-spine";

export class ClinicalPictureInformationModel extends DndNodeModel {

    static backendResourceName = 'clinical_picture_information';


    @observable id = null;
    @observable title = '';
    @observable text = '';
    @observable video = null;
    @observable healthBasePage = '';
    @observable ordering = 0;
    @observable priority = 0;
    @observable visible = true;
    @observable sourceTitle = null;
    @observable sourceLink = null;

    _treatmentClinicalPicture;

    get _dndCheckbox() {
        return this.visible;
    }

    set _dndCheckbox(value) {
        if (! this._treatmentClinicalPicture || this.localToTreatment?.id === this._treatmentClinicalPicture?.treatment?.id) {
            this.setInput('visible', value);
        } else {
            const override = new TreatmentClinicalPictureClinicalPictureInformation(
                { visibleOverride: value, clinicalPictureInformation: this.toJS(), },
                { relations: ['clinicalPictureInformation.children', 'clinicalPictureInformation.parent'] });

            override._treatmentClinicalPicture = this._treatmentClinicalPicture;

            // Add the override to the overrides (for visualization) as well as the treatmentClinicalPictureInformations (for saving)
            this._treatmentClinicalPicture.treatmentClinicalPictureClinicalPictureInformations.models.push(override);
            this._treatmentClinicalPicture.treatmentClinicalPictureClinicalPictureInformations.__setChanged = true;
            reconstructClinicalPictureTree(this._treatmentClinicalPicture.clinicalPicture, this._treatmentClinicalPicture, this._treatmentClinicalPicture.treatment.id)
                .then(() => this._treatmentClinicalPicture._forceUpdate());
        }
    }


    @computed
    get _children(){
        // When building the tree, the item might not have its relation setup yet, in this case just return empty list
        return this.children ? this.children : [];
    }

    relations() {
        return {
            lastUpdatedBy: User,
            parent: ClinicalPictureInformationModel,
            children: ClinicalPictureInformationStore,
            clinicalPicture: ClinicalPictureModel,
            image: Image,
            localToTreatment: Treatment,
        }
    }

    casts() {
        return {
            lastUpdatedAt: Casts.datetime
        };
    }

}

export class ClinicalPictureInformationStore extends DndNodeStore {
    Model = ClinicalPictureInformationModel;
    static backendResourceName = 'clinical_picture_information';

    comparator = (a, b) => {
        if (a.ordering === b.ordering) {
            // The alternative ordering is a prio, higher prio means first
            return b.priority - a.priority;
        } else {
            return a.ordering - b.ordering;
        }
    }
}

/***
 * Very simple helper, it gets all the information items linked to a clinical picture.
 *
 * Only supports getting, but helps a lot already
 *
 * @param treatmentId
 * @returns
 */
export function forTreatmentClinicalPictureInformationStoreFactory(treatmentId) {
    const SpecialApi = class extends BinderApi {
        baseUrl = FRONTEND_API_BASE_URL;


        fetchStore({url, data, requestOptions}) {
            return this.get(url, data, requestOptions).then(_res => {

                const res = {
                    data: _res,
                    with: {},
                    with_mapping: {},
                    meta: {
                        total_records: 0
                    }
                }

                return {
                    response: res,
                    data: res.data,
                    repos: res.with,
                    relMapping: res.with_mapping,
                    reverseRelMapping: res.with_related_name_mapping,
                    totalRecords: res.meta.total_records,
                    meta: res.meta,
                };
            });
        }
    }

    const ForTreatmentClinicalPictureInformationModel = class extends ClinicalPictureInformationModel {

        @observable clinicalPicture;
        @observable parent;

        relations() {
            return {}
        }

    }


    return class extends Store {
        Model = ForTreatmentClinicalPictureInformationModel;

        api = new SpecialApi()

        static backendResourceName = `treatment/${treatmentId}/information`;


        naturalSort() {
            // Get all models with a specific parent in a sorted way
            const getSortedModelsWithParent = (parentId) => {

                const filteredModels = this.models.filter(model => {
                    return model.parent === parentId;
                });

                const res = []

                // eslint-disable-next-line
                for (const model of filteredModels ) {
                    res.push(model)
                    // eslint-disable-next-line
                    for (const child of getSortedModelsWithParent(model.id)) {
                        res.push(child)
                    }
                }

                return res;
            }

            this.models.replace( getSortedModelsWithParent(null));
        }

    }
}

