import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import {Question} from "./Question";
import {ResponseStore} from "./Response";

export class Choice extends Model {

    static backendResourceName = 'choice';

    @observable id = null;
    @observable value = '';

    relations() {
        return {
            question: Question,
            responses: ResponseStore,
        }
    }

}

export class ChoiceStore extends Store {
    Model = Choice;
    static backendResourceName = 'choice';
}
