import {observable} from 'mobx';
import { User } from "./User";
import { Casts } from "./Base";
import { ClinicalPictureInformationStore } from "./ClinicalPicture/ClinicalPictureInformation";
import { DndRootModel, DndRootStore } from "../spider/store/DndAccordion";
import { TreatmentClinicalPictureStore } from './ClinicalPicture/TreatmentClinicalPicture';

export const CLINICAL_PICTURE_TYPES = [
    'medication',
    'indication'
];

export class ClinicalPictureModel extends DndRootModel {

    static backendResourceName = 'clinical_picture';

    @observable id = null;
    @observable name = '';
    @observable type = '';
    @observable snomedCtCode = null;
    @observable lastUpdatedAt = null;

    get _children(){

       return this.clinicalPictureInformations;
    }

    relations() {
        return {
            lastUpdatedBy: User,
            clinicalPictureInformations: ClinicalPictureInformationStore,
            treatmentClinicalPictures: TreatmentClinicalPictureStore,
        }
    }

    casts() {
        return {
            lastUpdatedAt: Casts.datetime
        };
    }

}

export class ClinicalPictureStore extends DndRootStore {
    Model = ClinicalPictureModel;
    static backendResourceName = 'clinical_picture';
}
