import {Model, Store} from 'store/Base';
import {WorkOrderCheck} from 'store/WorkOrderCheck';
import {User} from "store/User"
import {observable} from "mobx";

export class CheckSignOff extends Model {
    static backendResourceName = 'check_sign_off';

    @observable level = null;
    @observable id = null;
    @observable checkMoment = null;
    @observable retractMoment = null;
    @observable status = null;


    relations() {
        return {
            workOrderCheck: WorkOrderCheck,
            user: User,
        };
    }
}

export class CheckSignOffStore extends Store {
    Model = CheckSignOff;
    static backendResourceName = 'check_sign_off';
}
