import {Model, Store} from 'store/Base';
import {observable} from "mobx";
import {TemplateStore} from 'store/Template';
import {WorkOrderCheckStore} from 'store/WorkOrderCheck';
import {WorkOrderSignOffStore} from 'store/WorkOrderSignOff';
import {IssueStore} from 'store/Issue';
import {IssueReplyStore} from 'store/IssueReply';
import {User} from "./User";
import {Casts} from "./Base";
import {ReassignmentStore} from './Reassignment';

export const ROLE_BUILDER = 'builder';
export const ROLE_JUNIOR_REVIEWER = 'junior_reviewer';
export const ROLE_SENIOR_REVIEWER = 'senior_reviewer';
export const STATE_FINISH = 'finish';

export class WorkOrder extends Model {
    static backendResourceName = 'work_order';

    static omitFields = ['assignedToMe', 'role'];

    @observable id = null;
    @observable state = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable assignedToMe = false;
    @observable role = null;

    signOff() {
        return this.api.post(`${this.url}sign_off/`);
    }

    getAssigneeId() {
        if (this.state === 'start' || this.state === 'needs_rework'){
            return this.builder.id;
        } else if (this.state === 'junior_review_busy' || this.state === 'stuck_builder') {
            return this.juniorReviewer.id;
        } else if (this.state === 'senior_review_busy' || this.state === 'stuck_junior_reviewer') {
            return this.seniorReviewer.id;
        } else {
            return null;
        }
    }

    relations() {
        return {
            templates: TemplateStore,
            builder: User,
            juniorReviewer: User,
            seniorReviewer: User,
            workOrderChecks: WorkOrderCheckStore,
            workOrderSignOffs: WorkOrderSignOffStore,
            issues: IssueStore,
            issueReplies: IssueReplyStore,
            reassignments: ReassignmentStore
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime
        };
    }

    hasUser(id) {
        const userIds = [this.builder.id, this.juniorReviewer.id, this.seniorReviewer.id];
        return (id !== undefined && userIds.includes(id));
    }

    getTeam() {
        let team = {};
        team[ROLE_BUILDER] = this.builder;
        team[ROLE_JUNIOR_REVIEWER] = this.juniorReviewer;
        team[ROLE_SENIOR_REVIEWER] = this.seniorReviewer;
        return team;
    }

    canBeClaimedBy(user) {
        const nextClaimableRole = this.builder.isNew ? 0 : (this.juniorReviewer.isNew ? 1 :
            (this.seniorReviewer.isNew ? 2 : -1))
        const roles = {'builder': 0, 'junior_reviewer': 1, 'senior_reviewer': 2};

        return roles[user.groupNames[0]] === nextClaimableRole;
    }

    getRoleOfUser(user) {
        const team = this.getTeam();
        return Object.keys(team).find(key => team[key].id === user.id);
    }

    formatRole(role) {
        return role.split('_').map(x => x[0].toUpperCase() + x.slice(1)).join(' ');
    }

    getNextCheck(user) {
        const userChecks = this.myChecks(user);
        const hasCheckedAny = userChecks.some(check => check.hasUserChecked());
        if (!hasCheckedAny) {
            return null;
        }
        const toCheck = userChecks.filter(check => !check.hasUserChecked());
        return toCheck.length > 0 ? toCheck[0] : null;
    }

    // Get the checks the user has to sign
    myChecks(user) {
        const role = this.getRoleOfUser(user);

        let filter = () => false;
        switch (role) {
            case ROLE_BUILDER:
                filter = check => check.needsBuilderSignOff;
                break;
            case ROLE_JUNIOR_REVIEWER:
                filter = check => check.needsJuniorReviewerSignOff;
                break;
            case ROLE_SENIOR_REVIEWER:
                filter = check => check.needsSeniorReviewerSignOff;
                break;
            default:
            // No default
        }

        return this.workOrderChecks.models.filter(filter);
    }

    canMarkAsStuck(user) {
        //
        const myChecks = this.myChecks(user);


        if (myChecks.some(myChecks => myChecks.needsUserSignOff(user))) {
            return false;
        }


        return this.myChecks(user).some(
            workOrderCheck => workOrderCheck.isUserStuck(user)
        );
    }

    // A user can sign off if they belong to the team, and they signed off everything for their role
    canSignOff(user) {
        return this.myChecks(user).every(
            workOrderCheck => workOrderCheck.hasUserAccepted(user.id)
        );
    }

    // A user can reject something if they belong to the team, and they signed off / rejected everything for their role
    canReject(user) {
        const myChecks = this.myChecks(user);

        // If we can sign off, then we cannnot reject
        if (this.canSignOff(user)) {
            return false;
        }

        // Otherwise all checks need to be accepted or rejected. Since we cannot sign off, this means we have
        // at least one rejected check
        return myChecks.every(
            workOrderCheck => workOrderCheck.hasUserRejected(user) || workOrderCheck.hasUserAccepted(user)
        );
    }
}

export class WorkOrderStore extends Store {
    Model = WorkOrder;
    static backendResourceName = 'work_order';
}
