import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from "mobx-react";
import { PropTypes } from "prop-types";
import { Message } from "../../store/Message";

const MessageContainer = styled.div`
    background-color: ${props => props.external ? '#F5F5F5' : '#ebf4f9'};
    background-color: ${props => props.unsaved ? '#EBF4F9AA' : null};
    justify-self: ${props => props.external ? 'flex-start' : 'flex-end'} !important;
    align-self: ${props => props.external ? 'flex-start' : 'flex-end'} !important;

    border-radius: ${props => props.external ? '0px 10px 10px 10px' : '10px 10px 0px 10px'};
    width: 318px;
    padding: 12px;
    margin: 4px 0px;
`;

const MessageTime = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    display: flex;
    flex: 1;
    justify-content: ${props => props.external ? 'flex-end' : 'flex-start'};
    letter-spacing: 0.4px;
    margin-top: 0.5em !important;

    /* general/black 38% */

    color: rgba(0, 0, 0, 0.38);

`;

const MessageText = styled.div`
    white-space: pre-wrap;
`;

@observer
export class ChatMessage extends Component {
    static propTypes = {
        message: PropTypes.instanceOf(Message).isRequired,
    };

    render() {
        const {message} = this.props;
        const external = message.patient.user?.id === message.sentBy?.id;

        return (
            <MessageContainer external={external} unsaved={message._unsaved} data-test-chat-message>
                <MessageText data-test-chat-message-content>{message.content}</MessageText>
                <MessageTime external={external}>{message.sentAt.toFormat('HH:mm')}</MessageTime>
            </MessageContainer>
        );
    }
}
