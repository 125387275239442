import {SUPERUSER, ADMIN, BUILDER, JUNIOR_REVIEWER, SENIOR_REVIEWER, PHARMACIST, PATIENT} from './Groups'


export const MENU_ASSIGNMENT = 'assignemnt';
export const MENU_ASSET = 'asset';
export const MENU_TREATMENT = 'treatment';
export const MENU_PATIENT = 'patient';
export const MENU_REPORT = 'report';
export const MENU_ACCESS_LOG = 'access_log';



export const MENU_PERMISSIONS = {
    [SUPERUSER]: [MENU_ASSIGNMENT, MENU_ASSET, MENU_TREATMENT, MENU_REPORT],
    [ADMIN]: [MENU_ASSIGNMENT, MENU_ASSET, MENU_TREATMENT, MENU_REPORT],
    [BUILDER]: [MENU_ASSIGNMENT, MENU_TREATMENT],
    [JUNIOR_REVIEWER]: [MENU_ASSIGNMENT, MENU_TREATMENT],
    [SENIOR_REVIEWER]: [MENU_ASSIGNMENT],
    [PHARMACIST]: [MENU_PATIENT, MENU_REPORT],
    [PATIENT]: []
}

export const MENU_ORDER = [MENU_ASSIGNMENT, MENU_ASSET, MENU_TREATMENT, MENU_PATIENT, MENU_REPORT, MENU_ACCESS_LOG];

export const MENU_CMS_TREATMENTS = 'cms_treatments';
export const MENU_CMS_TREATMENT_GROUPS = 'cms_treatment_groups';
export const MENU_CMS_GENERIC_MOMENTS = 'cms_generic_moments';
export const MENU_CMS_CATEGORIES = 'cms_categories';
export const MENU_CMS_LIBRARY = 'cms_library';
export const MENU_CMS_INFORMATION_PAGE = 'cms_information_page';


export const CMS_MENU_PERMISSIONS = {
    [SUPERUSER]: [MENU_CMS_TREATMENTS, MENU_CMS_TREATMENT_GROUPS, MENU_CMS_GENERIC_MOMENTS, MENU_CMS_CATEGORIES, MENU_CMS_LIBRARY, MENU_CMS_INFORMATION_PAGE,],
    [ADMIN]: [MENU_CMS_TREATMENTS, MENU_CMS_TREATMENT_GROUPS, MENU_CMS_GENERIC_MOMENTS, MENU_CMS_CATEGORIES, MENU_CMS_LIBRARY, MENU_CMS_INFORMATION_PAGE,],
    [BUILDER]: [MENU_CMS_TREATMENTS, MENU_CMS_LIBRARY],
    [JUNIOR_REVIEWER]: [MENU_CMS_TREATMENTS, MENU_CMS_LIBRARY],
    [SENIOR_REVIEWER]: [],
    [PHARMACIST]: [],
    [PATIENT]: []
}
