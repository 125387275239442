import { Model, Store } from 'store/Base';
import { observable } from 'mobx';

export class MetabaseDashboard extends Model {

    static backendResourceName = 'metabase_dashboard';
    @observable id = null;
    @observable title = null;
    @observable dashboardId = null;
}

export class MetabaseDashboardStore extends Store {
    Model = MetabaseDashboard;
    static backendResourceName = 'metabase_dashboard';
}
