import {observable} from 'mobx';
import {Section} from "./Section";
import {ChoiceStore} from "./Choice";
import {ResponseStore} from "./Response";
import { SpecialVariable } from "./SpecialVariable";
import { DndLeafModel, DndLeafStore } from "../spider/store/DndAccordion";

export const QUESTION_MULTIPLE_CHOICE = 'multiple_choice';
export const QUESTION_SINGLE_CHOICE = 'single_choice';
export const QUESTION_NUMERIC_INPUT = 'numeric_input';
export const QUESTION_OPEN = 'open';
export const QUESTION_LINEAR_SCALE = 'linear_scale';
export const QUESTION_BOOLEAN = 'boolean';
export const QUESTION_DATE = 'date';

export const TYPES = [
    QUESTION_MULTIPLE_CHOICE,
    QUESTION_SINGLE_CHOICE,
    QUESTION_NUMERIC_INPUT,
    QUESTION_OPEN,
    QUESTION_LINEAR_SCALE,
    QUESTION_BOOLEAN,
    QUESTION_DATE
];

export const QUESTION_SIZE_LONG = 'long';
export const QUESTION_SIZE_SHORT = 'short';

export const QUESTION_SCALE_TEXT_5 = 'text-5';
export const QUESTION_SCALE_TEXT_7 = 'text-7';
export const QUESTION_SCALE_SMILEYS = 'smileys';

export class Question extends DndLeafModel {

    static backendResourceName = 'question';

    @observable id = null;
    @observable title = '';
    @observable ordering = 0;

    @observable type = ''; // temporary fix
    @observable linearScaleType = '';
    @observable size = QUESTION_SIZE_SHORT;
    @observable question = '';
    @observable required = true;

    get _parent(){
        return this.section;
    }

    get _questionTypes() {
        return TYPES; 
    }

    relations() {
        return {
            section: Section,
            responses: ResponseStore,
            choices: ChoiceStore,
            specialVariable: SpecialVariable,
            // dependencies: QuestionDependencyStore,
        }
    }

}

export class QuestionStore extends DndLeafStore {
    Model = Question;
    static backendResourceName = 'question';
}
