import {observable, computed} from 'mobx';
import {omit, isArray} from 'lodash';
import {Model, Store, Casts} from 'store/Base';
import {PUBLIC_URL} from 'helpers';
import {AuthenticationStore} from "./Authentication";
import {Pharmacist} from "./Pharmacist";
import {Patient} from './Patient';
import {Customer } from './Customer/Customer';
import {CommunicationLogStore} from "./CommunicationLog";

export class Permission extends Model {
    static backendResourceName = 'permission';

    @observable id = null;

    @observable app = '';
    @observable codename = '';
    @observable permissions = [];
}

export class PermissionStore extends Store {
    Model = Permission;
    static backendResourceName = 'permission';
}

export class Group extends Model {
    static backendResourceName = 'group';

    @observable id = null;
    @observable name = '';

    relations() {
        return {
            permissions: PermissionStore,
        };
    }
}

export class GroupStore extends Store {
    Model = Group;
    static backendResourceName = 'group';
}

export class User extends Model {
    static backendResourceName = 'user';

    @observable id = null;
    @observable email = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable password = '';
    @observable isSuperuser = false;
    @observable avatar = null;
    @observable _avatar = null;
    @observable deleted = false;
    @observable isActive = false;
    @observable dateJoined = null;
    @observable communicationSubscribed = true;

    // Don't set this to a language
    @observable language = '';


    afterConstructor(data, options) {
        return super.afterConstructor(data, options)
    }

    getUrl() {
        return `/assets/user/${this.id}/edit`;
    }

    casts() {
        return {
            avatar: {
                parse(attr, value) {
                    if (value) {
                        return PUBLIC_URL + value;
                    }

                    return null;
                },
                toJS(attr, value) {
                    return value;
                },
            },
            dateJoined: Casts.datetime,
        }
    }

    saveAvatar() {
        if (this._avatar !== null) {
            return
        }
        const avatar: File = this._avatar ?? new File([], '', {});

        const data = new FormData();

        data.append('avatar', avatar, avatar.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}avatar/`, data, {headers}).then(response => {
            this.parse(response.data);

        });
    }


    @computed
    get fullName() {
        if (this.firstName || this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        return this.email;
    }

    @computed get permissions() {
        const perms = [];

        /* eslint-disable*/
        this.groups?.forEach((group: Group) =>
            group.permissions?.forEach(highLevelPermission => {
                // @ts-ignore
                perms.push(`${highLevelPermission.app}.${highLevelPermission.codename}`);


                highLevelPermission.permissions.forEach(p => {
                        // @ts-ignore
                        perms.push(`${p.name}:${p.scope}`)
                    }
                )
            })
        );
        /* eslint-enable*/

        return perms;
    }

    relations() {
        return {
            groups: GroupStore,
            authentications: AuthenticationStore,
            pharmacist: Pharmacist,
            patient: Patient,
            apiCustomer: Customer,
            communicationLogs : CommunicationLogStore,
        };
    }

    masquerade() {
        return this.api.post(`/user/${this.id}/masquerade/`).then(() => {
            if (this.inGroup('pharmacist')) {
                window.location.href = '/patient/patient/overview';
            } else {
                window.location.href = '/workorder/template/overview';
            }
        });
    }


    resetRequest(username) {
        return this.api.post(`/user/reset_request/`, {username});
    }

    changePassword({passwordOld, passwordNew}) {
        return this.api.put(`/user/change_password/`, {
            old_password: passwordOld,
            new_password: passwordNew,
        });
    }

    resetPassword({id, password, resetCode}) {
        return this.api
            .put(`/user/${id}/reset_password/`, {
                password,
                reset_code: resetCode,
            })
            .then(() => {
                window.location.href = '/';
            });
    }

    setCommunicationSubscription({token, communicationSubscribed}) {
        return this.api
            .put(`/user/communication_subscription/`,
            {
                communication_subscribed: communicationSubscribed,
                token: token,
            })
    }

    getCommunicationSubscription({token}) {
        return this.api.get(`/user/communication_subscription/`,
            {
                token: token
            })
    }

    hasPermission(permissions) {
        if (this.isSuperuser) {
            return true;
        }
        if (!isArray(permissions)) {
            // @ts-ignore
            return this.permissions.includes(permissions);
        }
        return this.permissions.some(groupName =>
            permissions.includes(groupName)
        );
    }

    @computed
    get groupNames() {
        return this.groups?.map(group => group.name)
    }
    /**
     * A function to check whether or not any groups are included in the parameter groups
     *
     * @param {@code groups} Either a single groupname or an array of groupnames.
     * @throws none
     * @returns {@code True} if {@code} groups is a single groupName and groups is in {@code groupNames} of this user
     * OR {@code groups} is an array of group names which contains any element of {@code groupNames}
     */
    inGroup(groups) {
        if (!isArray(groups)) {
            return this.groupNames.includes(groups);
        }
        return this.groupNames.some(groupName => groups.includes(groupName));
    }

    toBackend(options = {}) {
        return omit(super.toBackend(options), 'password', 'active');
    }

    get canViewChangeLog() {
        return this.inGroup('admin') || this.isSuperuser;
    }
}

export class UserStore extends Store {
    Model = User;
    static backendResourceName = 'user';
}
