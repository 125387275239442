import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { set, get, remove,  computed, reaction, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Model, Store } from 'mobx-spine';
import { Popup, Image, Label, Dropdown, Form, Icon, Input, Button, Checkbox, TextArea } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { pick, omit, debounce, get as _get } from 'lodash';
import moment from 'moment';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { MultiPick as BaseMultiPick } from 're-cy-cle';
import onClickOutside from 'react-onclickoutside';
import { DatePicker, DateRangePicker, TimePicker, TimeRangePicker, WeekPicker } from 'daycy';
import { snakeToCamel, ACTION_DELAY } from '../helpers';
import { DateTime, Interval } from 'luxon';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import AutosizeTextarea from 'react-textarea-autosize';
import RightDivider from '../component/RightDivider';
import BaseDropzone from 'react-dropzone';
import { Casts } from 'store/Base';

export const LabelLink = styled(Link)`
    color: rgba(0, 0, 0, 0.4) !important;
    text-decoration: none !important;
`;

export class ErrorLabel extends Label {
    static defaultProps = {
        pointing: true,
        color: 'red',
    };
};

export const FormLabel = styled.label`
    display: flex !important;
    align-items: center;
`;

export const InfoIcon = styled(Icon)`
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &:hover {
        color: rgba(0, 0, 0, 0.5);
    }
    margin: 0 !important;
`;

export const FormSubLabel = styled.span`
    font-weight: normal;
    opacity: 0.7;
    display: inline-block;
    margin-left: auto;
`;

function getId(obj) {
    return obj.id;
}

function sliceArray(array) {
    return array.slice();
}

export function parseWheres(param) {
    if (!param) {
        return {};
    }

    // We have to manually split because , can appear inside () and then we
    // do not split (Example 'foo(bar:in=1,2)')
    const lines = [];
    let start = 0;
    let depth = 0;
    for (let i = 0; i < param.length; i++) {
        if (param.charAt(i) === ',' && depth === 0) {
            lines.push(param.slice(start, i));
            start = i + 1;
        } else if (param.charAt(i) === '(') {
            depth += 1
        } else if (param.charAt(i) === ')') {
            depth -= 1
        }
    }
    lines.push(param.slice(start));

    const wheres = {};
    for (const line of lines) {
        const lpar = line.indexOf('(');
        const eq = line.indexOf('=');
        const rpar = line.indexOf(')');

        if (
            // Some chars were not found
            lpar === -1 || eq === -1 || rpar === -1 ||
            // Chars are not in expected order
            lpar > eq || eq > rpar
        ) {
            continue;
        }

        const where = line.slice(0, lpar);
        const name = line.slice(lpar + 1, eq);
        const value = line.slice(eq + 1, rpar);

        if (wheres[where] === undefined) {
            wheres[where] = {};
        }
        wheres[where][name] = value;
    }
    return wheres;
}

export function serializeWheres(wheres) {
    const lines = [];
    for (const [where, filters] of Object.entries(wheres)) {
        for (const [name, value] of Object.entries(filters)) {
            lines.push(`${where}(${name}=${value})`);
        }
    }
    if (lines.length === 0) {
        return undefined;
    }
    return lines.join(',');
}

// Base Class

@observer
export class TargetBase extends Component {
    static propTypes = {
        // Base
        target: PropTypes.oneOfType([
            PropTypes.instanceOf(Model).isRequired,
            PropTypes.instanceOf(Store).isRequired,
        ]).isRequired,
        name: PropTypes.string.isRequired,
        where: PropTypes.string,
        // Controlled component
        value: PropTypes.any,
        onChange: PropTypes.func,
        afterChange: PropTypes.func.isRequired,
        // Value
        toTarget: PropTypes.func.isRequired,
        fromTarget: PropTypes.func.isRequired,
        // Errors

        /**
         * Allow changing errors just before processing. Example:
         *
         * mapErrors={errors => [...errors, ...(language.backendValidationErrors.__all__ || [])]}
         */
        mapErrors: PropTypes.func.isRequired,
        errorProps: PropTypes.object.isRequired,
        // Rendering
        label: PropTypes.string,
        subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        viewTo: PropTypes.oneOfType([
            PropTypes.func.isRequired,
            PropTypes.string.isRequired,
            PropTypes.node.isRequired,
        ]),
        info: PropTypes.string,
        contentProps: PropTypes.object.isRequired,

        className: PropTypes.string,
        width: PropTypes.number,
        required: PropTypes.bool,
        inline: PropTypes.bool,
        noLabel: PropTypes.bool,
        error: PropTypes.bool,
        errors: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        toTarget: (value) => value,
        fromTarget: (value) => value,
        afterChange: () => {},
        mapErrors: (errors) => errors,
        contentProps: {},
        errorProps: {},
        noLabel: false,
        error: false,
        errors: [],
    };

    constructor(...args) {
        super(...args);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    @computed get type() {
        const { target } = this.props;

        return (
            target instanceof Model
            ? 'model'
            : target instanceof Store
            ? 'store'
            : undefined
        );
    }

    // Value conversion

    toModel(value) {
        return value;
    }

    toStore(value) {
        return value;
    }

    toTarget(value) {
        const { toTarget } = this.props;
        // User conversion
        value = toTarget(value);
        // Class conversion
        switch (this.type) {
            case 'model':
                value = this.toModel(value);
                break;
            case 'store':
                value = this.toStore(value);
                break;
            default:
                // nop
        }
        return value;
    }

    fromModel(value) {
        return value;
    }

    fromStore(value) {
        return value;
    }

    fromTarget(value) {
        const { fromTarget } = this.props;
        // Class conversion
        switch (this.type) {
            case 'model':
                value = this.fromModel(value);
                break;
            case 'store':
                value = this.fromStore(value);
                break;
            default:
                // nop
        }
        // User conversion
        value = fromTarget(value);
        return value;
    }

    // Value handling

    getValue() {
        return this.fromTarget(this.getValueBase());
    }

    getValueBase(name) {
        const { target, name: baseName, where, value } = this.props;

        if (name === undefined) {
            name = baseName;
        }

        if (value !== undefined) {
            return value;
        }

        switch (this.type) {
            case 'model':
                return target[name];
            case 'store':
                // Using mobx get is required for mobx4
                if (where) {
                    const wheres = parseWheres(target.params.where);
                    return wheres[where] && wheres[where][name];
                } else {
                    return get(target.params, name);
                }
            default:
                // nop
        }
    }

    setValue(value, name) {
        const { target, name: baseName, where } = this.props;

        if (name === undefined) {
            name = baseName;
        }

        switch (this.type) {
            case 'model':
                target.setInput(name, value);
                break;
            case 'store':
                if (where) {
                    const wheres = parseWheres(target.params.where);
                    if (value === undefined) {
                        if (wheres[where] !== undefined) {
                            delete wheres[where][name];
                            if (Object.keys(wheres[where]).length === 0) {
                                delete wheres[where];
                            }
                        }
                    } else {
                        if (wheres[where] === undefined) {
                            wheres[where] = {};
                        }
                        wheres[where][name] = value;
                    }
                    const serialized = serializeWheres(wheres);
                    if (serialized === undefined) {
                        delete target.params.where;
                    } else {
                        target.params.where = serialized;
                    }
                } else {
                    if (value === undefined) {
                        remove(target.params, name);
                    } else {
                        set(target.params, {[name]: value});
                    }
                }
                break;
            default:
                // nop
        }
    }

    @computed get value() {
        return this.getValue();
    }

    onChange(value) {
        const { onChange, target, afterChange } = this.props;
        const oldValue = this.value;

        value = this.toTarget(value);

        if (onChange) {
            onChange(value);
        } else {
            this.setValue(value);
        }

        afterChange(value, target, oldValue);
    }

    // Errors

    @computed get errors() {
        let { target, name, mapErrors, errors } = this.props;

        if (
            this.type === 'model' &&
            target &&
            target.backendValidationErrors &&
            target.backendValidationErrors[name]
        ) {
            errors = [
                ...target.backendValidationErrors[name],
                ...errors,
            ];
        }

        return mapErrors(errors);
    }

    // Rendering

    getModelName() {
        const { target } = this.props;
        return snakeToCamel(target.constructor.backendResourceName.replace(/\//g, '_'));
    }

    getLabel() {
        const { label, name } = this.props;

        if ('label' in this.props) {
            return label;
        }

        if (!name) {
            return '';
        }

        if (this.type === 'model') {
            return t(`${this.getModelName()}.field.${name}.label`);
        }

        return '';
    }

    getSubLabel() {
        if ('subLabel' in this.props) {
            return this.props.subLabel;
        }
    }

    renderViewTo() {
        const { target, viewTo } = this.props;

        if (!viewTo) {
            return null;
        }

        let res = viewTo;
        if (typeof viewTo === "function") {
            res = res(this.model, target);
        }
        if (typeof res === "string") {
            res = (
                <LabelLink to={res}>
                    <Icon name="eye" />
                </LabelLink>
            );
        }

        return (
            <React.Fragment>&nbsp;{res}</React.Fragment>
        );
    }

    renderLabel() {
        const { info, name } = this.props;
        const label = this.getLabel();
        const subLabel = this.getSubLabel();

        return (
            <FormLabel data-test-form-label>
                {label}
                {subLabel && (
                    <FormSubLabel>{subLabel}</FormSubLabel>
                )}
                {this.renderViewTo()}
                {info && (
                    <React.Fragment>
                        <RightDivider />
                        <Popup
                            trigger={<InfoIcon name="info circle" />}
                            content={
                                typeof info === 'string'
                                ? info
                                : t(`${this.getModelName()}.field.${name}.info`)
                            }
                        />
                    </React.Fragment>
                )}
            </FormLabel>
        );
    }

    renderError(error, i) {
        return (
            <div key={i}>{error}</div>
        );
    }

    renderErrors(props) {
        if (this.errors.length === 0) {
            return null;
        }

        return (
            <ErrorLabel {...props}>
                {this.errors.map(this.renderError)}
            </ErrorLabel>
        );
    }

    renderContent(props) {
        // Override this using this.value and this.onChange
    }

    render() {
        const { error, required, width, inline, className, contentProps, errorProps, noLabel, ...rest } = this.props;

        const props = {
            ...contentProps,
            ...omit(
                rest,
                'target', 'value', 'onChange', 'afterChange',
                'toTarget', 'fromTarget', 'label', 'viewTo', 'mapErrors',
                'noLabel',
            ),
        };

        const errors = this.renderErrors(errorProps);

        return (
            <Form.Field
                required={required}
                error={error || this.errors.length > 0}
                width={width}
                inline={inline}
                className={className}
            >
                {!noLabel && this.renderLabel()}
                {errorProps.pointing === 'right' && errors}
                {this.renderContent(props)}
                {errorProps.pointing !== 'right' && errors}
            </Form.Field>
        );
    }
}

// Text Input

export class TargetTextInput extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.string,
    };

    fromStore(value) {
        return value || '';
    }

    toStore(value) {
        return value === '' ? undefined : value;
    }

    onChange(e, { value }) {
        super.onChange(value);
    }

    renderContent(props) {
        return (
            <Input
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

export class TargetTextArea extends TargetTextInput {
    static propTypes = {
        ...TargetTextInput.propTypes,
        autoHeight: PropTypes.bool,
    };

    renderContent(props) {
        const { autoHeight, ...rest } = props;
        const autoHeightProps = autoHeight ? { as: AutosizeTextarea } : {};

        return (
            <TextArea
                value={this.value}
                onChange={this.onChange}
                {...autoHeightProps}
                {...rest}
            />
        );
    }
}

// Date Picker

const DATE_LIBS = ['moment', 'luxon'];
const DATE_CONVERTERS = {
    moment: {
        moment: identity,
        luxon: momentToLuxon,
    },
    luxon: {
        moment: luxonToMoment,
        luxon: identity,
    },
};
const DATE_RANGE_CONVERTERS = {
    moment: {
        moment: identity,
        luxon: ({ start, end }) => Interval.fromDateTimes(
            momentToLuxon(start),
            momentToLuxon(end),
        ),
    },
    luxon: {
        moment: ({ start, end }) => ({
            start: luxonToMoment(start),
            end: luxonToMoment(end),
        }),
        luxon: identity,
    },
};

function getDateLib(target, name, dateLib) {
    return (
        dateLib ||
        (
            target && name &&
            target instanceof Model &&
            target.casts()[name] && // check if contained in casts
            target.casts()[name].dateLib
        ) ||
        (
            target && name &&
            target instanceof Store &&
            new target.Model().casts()[name] && // check if contained in casts
            new target.Model().casts()[name].dateLib
        ) ||
        Casts.date.dateLib ||
        'moment'
    );
}

export class TargetDatePicker extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.instanceOf(moment),
        dateLib: PropTypes.oneOf(DATE_LIBS),
    };

    @computed get dateLib() {
        const { target, name, dateLib } = this.props;
        return getDateLib(target, name, dateLib);
    }

    toModel(value) {
        if (!value) {
            return null;
        }
        return DATE_CONVERTERS.luxon[this.dateLib](value);
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        return DATE_CONVERTERS[this.dateLib].luxon(value);
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }
        return value.toFormat(LUXON_SERVER_DATE_FORMAT);
    }

    fromStore(value) {
        if (!value) {
            return null;
        }
        return DateTime.fromFormat(value, LUXON_SERVER_DATE_FORMAT);
    }

    renderContent(props) {
        return (
            <DatePicker
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

// Week Picker

export class TargetWeekPicker extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.shape({
            year: PropTypes.number.isRequired,
            week: PropTypes.number.isRequired,
        }),
        yearName: PropTypes.string,
        weekName: PropTypes.string,
    };

    getValueBase(name) {
        const { yearName, weekName } = this.props;

        if (name || !yearName || !weekName) {
            return super.getValueBase(name);
        }

        const year = this.getValueBase(yearName);
        const week = this.getValueBase(weekName);
        switch (this.type) {
            case 'model':
                if (year && week) {
                    return { year, week };
                } else {
                    return null;
                }
            case 'store':
                if (year && week) {
                    return `${year}-W${week}`;
                } else {
                    return undefined;
                }
            default:
                // nop
        }
    }

    @action setValue(value, name) {
        const { yearName, weekName } = this.props;

        if (name || !yearName || !weekName) {
            return super.setValue(value, name);
        }

        switch (this.type) {
            case 'model':
                const { year, week } = value;
                this.setValue(year, yearName);
                this.setValue(week, weekName);
                break;
            case 'store':
                if (value === undefined) {
                    this.setValue(undefined, yearName);
                    this.setValue(undefined, weekName);
                } else {
                    const [year, week] = value.split('-W');
                    this.setValue(year, yearName);
                    this.setValue(week, weekName);
                }
                break;
            default:
                // nop
        }
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }
        const { year, week } = value;
        return `${year}-W${week}`;
    }

    fromStore(value) {
        if (!value) {
            return null;
        }
        const [year, week] = value.split('-W');
        return { year: parseInt(year), week: parseInt(week) };
    }

    renderContent(props) {
        return (
            <WeekPicker
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

// Multi Pick

const MultiPickContainer = styled.div`
    width: 100%;
    position: relative;
    box-sizing: border-box;
`;

const MultiPickButton = styled(Button)`
    margin: 0;
    width: 100%;
`;

// So to be able to extend MultiPick we have to unwrap it from onClickOutside,
// extend it, and then wrap it again
@onClickOutside
class MultiPick extends BaseMultiPick.getClass() {
    static propTypes = {
        ...BaseMultiPick.getClass().propTypes,
        remote: PropTypes.bool,
        onSearchChange: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.node.isRequired,
            searchValue: PropTypes.string.isRequired,
        }).isRequired).isRequired,
    };

    static defaultProps = {
        ...BaseMultiPick.getClass().defaultProps,
        remote: false,
    };

    constructor(...args) {
        super(...args);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.filterData = this.filterData.bind(this);
    }

    componentDidMount() {
        const { innerRef } = this.props;
        if (innerRef) {
            innerRef(this);
        }
    }

    componentWillUnmount() {
        const { innerRef } = this.props;
        if (innerRef) {
            innerRef(null);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { onSearchChange } = this.props;
        const { searchValue } = this.state;

        if (onSearchChange && searchValue !== prevState.searchValue) {
            onSearchChange(searchValue);
        }
    }

    filterData(data, searchValue) {
        const { remote } = this.props;

        if (!remote && searchValue !== '') {
            searchValue = searchValue.toLowerCase();
            data = data.filter((item) => item.searchValue.includes(searchValue));
        }

        return data;
    }

    render() {
        const props = omit(
            this.props,
            'options',
            'value',
            'searchAppearsAfterCount',
            'searchPlaceholder',
            'selectedText',
            'selectAllText',
            'selectNoneText',
            'noneSelectedText',
            'onChange',
            'disabled',
            'noBatchSelect',
            'remote',
            'innerRef',
            'enableOnClickOutside',
            'disableOnClickOutside',
            'stopPropagation',
            'preventDefault',
            'outsideClickIgnoreClass',
            'eventTypes',
        );

        return (
            <MultiPickContainer>
                <MultiPickButton
                    type="button"
                    onClick={this.handleToggle}
                    disabled={this.props.disabled}
                    content={this.generateButtonText()}
                    icon="angle down"
                    labelPosition="right"
                    size="small"
                    {...props}
                />
                {this.renderDropdown()}
            </MultiPickContainer>
        );
    }
}

export class TargetMultiPick extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.arrayOf(PropTypes.any),
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.node.isRequired,
            searchValue: PropTypes.string,
        }).isRequired),
        // Used for converting the value in the query parameter
        type: PropTypes.oneOf(['str', 'int']),
        fromParam: PropTypes.func,
        toParam: PropTypes.func,
        store: PropTypes.instanceOf(Store),
        toOption: PropTypes.func,
        // Remote settings
        remote: PropTypes.bool,
        searchKey: PropTypes.string,
        skipFetch: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        remote: false,
        searchKey: 'search',
        skipFetch: false,
    };

    @observable optionsCountOverride = null;

    constructor(...args) {
        super(...args);
        this.fromParam = this.fromParam.bind(this);
        this.toParam = this.toParam.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.setDebouncedFetchReaction = reaction(
            () => this.props.store,
            (store) => {
                if (store) {
                    this.debouncedFetch = debounce(
                        store.fetch.bind(store),
                        ACTION_DELAY,
                    );
                } else {
                    delete this.debouncedFetch;
                }
            },
            { fireImmediately: true },
        );

        const { remote, store, skipFetch } = this.props;

        if (remote && store && !skipFetch) {
            store.fetch().then(() => {
                this.optionsCountOverride = store.__state.totalRecords;
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.setDebouncedFetchReaction();
        if (this.valueStoreReaction) {
            this.valueStoreReaction();
        }
    }

    @computed get valueType() {
        const { type, store } = this.props;
        if (type) {
            return type;
        } else if (store) {
            return 'int';
        } else {
            return 'str';
        }
    }

    onSearchChange(searchValue) {
        const { store, searchKey } = this.props;
        store.params[searchKey] = searchValue;
        this.debouncedFetch();
    }

    valueStoreReaction = null;

    @computed get valueStore() {
        const { store, remote } = this.props;

        if (!remote) {
            return undefined;
        }

        if (this.valueStoreReaction) {
            this.valueStoreReaction();
        }

        if (this.type === 'model') {
            return this.getValueBase();
        } else {
            const Store = store.constructor;
            const valueStore = new Store();
            this.valueStoreReaction = reaction(
                () => this.value,
                (value) => {
                    if (value.length > 0) {
                        valueStore.params['.id:in'] = value.join(',');
                        valueStore.fetch();
                    } else {
                        valueStore.clear();
                    }
                },
                { fireImmediately: true },
            );
            return valueStore;
        }
    }

    fromParam(value) {
        const { fromParam } = this.props;

        if (fromParam) {
            return fromParam(value);
        }

        switch (this.valueType) {
            case 'str':
                return value;
            case 'int':
                return parseInt(value);
            default:
                throw new Error(`Invalid type: ${this.valueType}`);
        }
    }

    toParam(value) {
        const { toParam } = this.props;

        if (toParam) {
            return toParam(value);
        }

        switch (this.valueType) {
            case 'str':
                return value;
            case 'int':
                return value.toString();
            default:
                throw new Error(`Invalid type: ${this.type}`);
        }
    }

    toStore(value) {
        if (value.length === 0) {
            return undefined;
        }

        return value.map(this.toParam).join(',');
    }

    fromStore(value) {
        if (value === undefined) {
            return [];
        }

        return value.toString().split(',').map(this.fromParam);
    }

    fromModel(value) {
        const { store } = this.props;
        if (store) {
            value = value.map(getId);
        }
        value = super.fromModel(value);
        return value;
    }

    toModel(value) {
        const { store } = this.props;
        value = super.toModel(value);
        if (store) {
            value = value.map((id) => store.get(id) || this.valueStore.get(id));
        }
        return value;
    }

    @computed get options() {
        let { options, store, toOption } = this.props;

        if (!options && store && toOption) {
            options = store.map(toOption);
            if (this.valueStore) {
                for (const model of this.valueStore.models) {
                    const { value, text } = toOption(model);
                    if (options.every((option) => option.value !== value)) {
                        options.push({ value, text });
                    }
                }
            }
        }

        if (!options) {
            throw new Error('couldn\'t derive options');
        }

        return options.map(({ value, text, searchValue }) => ({
            value,
            label: text,
            searchValue: (
                searchValue !== undefined
                ? searchValue
                : typeof value === 'string'
                ? value
                : ''
            ).toLowerCase(),
        }));
    }

    @observable multiPickNode = null;

    renderContent({ options, remote, ...props }) {
        if (remote) {
            props.onSearchChange = this.onSearchChange;
        }

        if (this.optionsCountOverride !== null) {
            props.selectedText = (
                (props.selectedText || t('form.multiPick.selectedText'))
                .replace('$2', this.optionsCountOverride)
            );
        }

        // Dirty hack because re-cy-cle is utter crap and doesn't allow any
        // extendability
        if (
            remote &&
            this.multiPickNode &&
            this.multiPickNode.state.searchValue !== ''
        ) {
            props.searchAppearsAfterCount = 0;
        }

        return (
            <MultiPick
                innerRef={(node) => this.multiPickNode = node}
                value={this.value}
                onChange={this.onChange}
                options={this.options}
                remote={remote}
                {...omit(props, 'searchKey', 'skipFetch')}
            />
        );
    }
}

// Radio Buttons

export class TargetRadioButtons extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })).isRequired,
        activeProps: PropTypes.object,
        inactiveProps: PropTypes.object,
        disabled: PropTypes.bool,
        clearable: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        activeProps: { active: true },
        inactiveProps: {},
        disabled: false,
        clearable: false,
    };

    constructor(...args) {
        super(...args);
        this.renderOption = this.renderOption.bind(this);
    }

    renderOption({ tooltip, value, text, ...rest }, i) {
        const { activeProps, inactiveProps, disabled, clearable } = this.props;
        const active = value === this.value;
        const props = active ? activeProps : inactiveProps;

        const buttonProps = {
            disabled,
            ...props,
            ...rest,
        };

        let option = (
            <Button
                type="button"
                key={value || i}
                onClick={() => this.onChange(clearable && active ? null : value)}
                content={text}
                {...buttonProps}
            />
        );

        if (tooltip) {
            option = (
                <Popup
                    position="top center"
                    trigger={option}
                    content={tooltip}
                />
            );
        }

        return option;
    }

    renderContent({ options, ...props }) {
        delete props.activeProps;
        delete props.inactiveProps;
        delete props.disabled;
        return (
            <Button.Group widths={options.length} size="small" {...props}>
                {options.map(this.renderOption)}
            </Button.Group>
        );
    }
}

// Multi Buttons

export class TargetMultiButtons extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.arrayOf(PropTypes.any.isRequired),
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })).isRequired,
        activeProps: PropTypes.object,
        inactiveProps: PropTypes.object,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        activeProps: { active: true },
        inactiveProps: {},
        disabled: false,
    };

    constructor(...args) {
        super(...args);
        this.renderOption = this.renderOption.bind(this);
    }

    toStore(value) {
        if (value.length === 0) {
            return undefined;
        }
        return value.map(super.toStore).join(',');
    }

    fromStore(value) {
        if (!value) {
            return [];
        }
        return value.split(',').map(super.fromStore);
    }

    renderOption({ value, text, ...rest }, i) {
        const { activeProps, inactiveProps, disabled } = this.props;
        const active = this.value.includes(value);
        const props = active ? activeProps : inactiveProps;

        return (
            <Button
                type="button"
                key={value || i}
                onClick={() => {
                    if (active) {
                        this.onChange(this.value.filter((v) => v !== value));
                    } else {
                        this.onChange([...this.value, value]);
                    }
                }}
                content={text}
                disabled={disabled}
                {...props}
                {...rest}
            />
        );
    }

    renderContent({ options, ...props }) {
        delete props.activeProps;
        delete props.inactiveProps;
        delete props.disabled;
        return (
            <Button.Group widths={options.length} size="small" {...props}>
                {options.map(this.renderOption)}
            </Button.Group>
        );
    }
}

// Date Range Picker

const LUXON_SERVER_DATE_FORMAT = 'yyyy-LL-dd';

export function momentToLuxon(value) {
    return DateTime.fromISO(value.toISOString(true));
}

export function luxonToMoment(value) {
    return moment.parseZone(value.toISO());
}

function identity(value) {
    return value;
}

export class TargetDateRangePicker extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.instanceOf(Interval),
        startName: PropTypes.string,
        endName: PropTypes.string,
        dateLib: PropTypes.oneOf(DATE_LIBS),
    };

    @computed get dateLib() {
        const { target, startName, endName, dateLib } = this.props;

        const startDateLib = getDateLib(target, startName, dateLib);
        const endDateLib = getDateLib(target, endName, dateLib);

        if (startDateLib !== endDateLib) {
            throw new Error('incompatible date libraries used between start and end');
        }

        return startDateLib;
    }

    getValueBase(name) {
        const { startName, endName } = this.props;

        if (name || !startName || !endName) {
            return super.getValueBase(name);
        }

        const start = this.getValueBase(startName);
        const end = this.getValueBase(endName);
        switch (this.type) {
            case 'model':
                if (start && end) {
                    return { start, end };
                } else {
                    return null;
                }
            case 'store':
                if (start && end) {
                    return `${start},${end}`;
                } else {
                    return undefined;
                }
            default:
                // nop
        }
    }

    @action setValue(value, name) {
        const { startName, endName } = this.props;

        if (name || !startName || !endName) {
            return super.setValue(value, name);
        }

        switch (this.type) {
            case 'model':
                const { start, end } = value;
                this.setValue(start, startName);
                this.setValue(end, endName);
                break;
            case 'store':
                if (value === undefined) {
                    this.setValue(undefined, startName);
                    this.setValue(undefined, endName);
                } else {
                    const [start, end] = value.split(',');
                    this.setValue(start, startName);
                    this.setValue(end, endName);
                }
                break;
            default:
                // nop
        }
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        return DATE_RANGE_CONVERTERS[this.dateLib].luxon(value);
    }

    toModel(value) {
        if (!value) {
            return null;
        }
        return DATE_RANGE_CONVERTERS.luxon[this.dateLib](value);
    }

    fromStore(value) {
        if (value === undefined) {
            return null;
        }

        let [start, end] = value.split(',');

        return Interval.fromDateTimes(
            DateTime.fromFormat(start, LUXON_SERVER_DATE_FORMAT),
            DateTime.fromFormat(end, LUXON_SERVER_DATE_FORMAT),
        );
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }

        const start = value.start.toFormat(LUXON_SERVER_DATE_FORMAT);
        const end = value.end.toFormat(LUXON_SERVER_DATE_FORMAT);
        return `${start},${end}`;
    }

    renderContent(props) {
        return (
            <DateRangePicker
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

// Multi Text Input

@observer
class MultiTextInput extends Component {
    static propTypes = {
        value: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
    };

    constructor(...args) {
        super(...args);
        this.onChange = this.onChange.bind(this);
    }

    @computed get options() {
        const { value } = this.props;
        return value.map((item) => ({ value: item, text: item }));
    }

    onChange(e, { value }) {
        const { onChange } = this.props;
        onChange(value);
    }

    onAddItem() {
        // no op
    }

    render() {
        const { value, onChange, ...props } = this.props;
        return (
            <Dropdown
                selection fluid multiple search allowAdditions
                icon={null} noResultsMessage={null}
                options={this.options}
                value={value}
                onAddItem={this.onAddItem}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

export class TargetMultiTextInput extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.arrayOf(PropTypes.string.isRequired),
    };

    fromStore(value) {
        if (value === undefined) {
            return [];
        }

        return value.split(',');
    }

    toStore(value) {
        if (value.length === 0) {
            return undefined;
        }

        return value.join(',');
    }

    renderContent(props) {
        return (
            <MultiTextInput
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

const FlexContainer = styled.div`
    align-items: center;
    display: flex;
    > * {
        margin-left: 0.5em;
    }
    > :first-child {
        margin-left: 0;
    }
`;

const SmallFlex = styled.div`
    flex: 0 0 auto;
    line-height: 0;
`;

const BigFlex = styled.div`
    flex: 1 1 auto;
`;

export class TargetCheckbox extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.bool,
        rightLabel: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        rightLabel: false,
    }

    onChange(e, { checked }) {
        super.onChange(checked);
    }

    fromStore(value) {
        return value === 'true';
    }

    toStore(value) {
        return value ? 'true' : 'false';
    }

    renderContent({ rightLabel, ...props }) {
        let node = (
            <Checkbox
                checked={this.value}
                onChange={this.onChange}
                {...props}
            />
        );

        if (rightLabel) {
            node = (
                <FlexContainer>
                    <SmallFlex>{node}</SmallFlex>
                    <BigFlex>{this.renderLabel()}</BigFlex>
                </FlexContainer>
            );
        }

        return node;
    }
}

// Number Input

const StyledInput = styled(Input)`
    > input {
        text-align: ${(props) => props.textAlign} !important;
    }
`;

const PROPS_MASK = [
    'prefix',
    'suffix',
    'includeThousandsSeparator',
    'thousandsSeparatorSymbol',
    'allowDecimal',
    'allowNegative',
    'decimalSymbol',
    'decimalLimit',
];

export class TargetNumberInput extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.number,
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeperator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.number,
        textAlign: PropTypes.oneOf(['left', 'center', 'right']),
        createMask: PropTypes.func,
        createReverseMask: PropTypes.func,
        toFixed: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        allowDecimal: false,
        includeThousandsSeparator: false,
        prefix: '',
        suffix: '',
        thousandsSeparatorSymbol: '.',
        decimalSymbol: ',',
        textAlign: 'right',
        toFixed: false,
    };

    @observable localValue = '';
    ignoreReactionOnce = false;

    constructor(...args) {
        super(...args);

        const { decimalLimit, toFixed } = this.props;

        this.initLocalValue = this.initLocalValue.bind(this);
        this.renderInput = this.renderInput.bind(this);

        if (toFixed && decimalLimit === undefined) {
            throw new Error('decimalLimit is required for toFixed');
        }
    }

    componentDidMount() {
        this.initLocalValueReaction = reaction(
            () => this.value,
            this.initLocalValue,
            { fireImmediately: true },
        );
    }

    componentWillUnmount() {
        this.initLocalValueReaction();
    }

    initLocalValue() {
        if (!this.ignoreReactionOnce) {
            const { mask } = this.createMask(this.props);

            this.localValue = this.value
                ? conformToMask(this.value, mask).conformedValue
                : this.value;
        }
        this.ignoreReactionOnce = false;
    }

    onChange(e) {
        this.localValue = e.target.value;
        this.ignoreReactionOnce = true;
        super.onChange(this.localValue);
    }

    fromStore(value) {
        return value || '';
    }

    toStore(value) {
        return value === '' ? undefined : value;
    }

    fromModel(value) {
        if (typeof value !== 'number') {
            return '';
        }
        return value.toString();
    }

    normalizeValue(value) {
        if (value === '') {
            return '';
        }

        const { reverseMask } = this.createReverseMask(this.props);
        value = reverseMask(value);
        return value;
    }

    toModel(value) {
        if (value === '') {
            return null;
        }
        if (value.includes('.')) {
            return parseFloat(value);
        } else {
            return parseInt(value);
        }
    }

    fromTarget(value) {
        const { toFixed, decimalLimit, decimalSymbol } = this.props;
        value = super.fromTarget(value);
        if (value !== '' && toFixed) {
            value = (parseInt(value) / Math.pow(10, decimalLimit)).toFixed(decimalLimit);
            // value = trimEnd(value, '0');
        }
        value = value.replace('.', decimalSymbol);
        return value;
    }

    toTarget(value) {
        const { toFixed, decimalLimit } = this.props;
        value = this.normalizeValue(value);

        if (value !== '' && toFixed) {
            value = (value * Math.pow(10, decimalLimit)).toFixed();
        }
        value = super.toTarget(value);
        return value;
    }

    createMask(props) {
        if (props.createMask) {
            return props.createMask(props);
        }

        return {
            mask: createNumberMask(pick(props, PROPS_MASK)),
            props: omit(props, PROPS_MASK),
        };
    }

    createReverseMask(props) {
        if (props.createReverseMask) {
            return props.createReverseMask(props);
        }

        const { prefix, suffix, thousandsSeparatorSymbol, decimalSymbol, allowDecimal } = props;

        return {
            reverseMask: (value) => {
                let negative;
                if (value.startsWith('-')) {
                    value = value.slice(1);
                    negative = true;
                } else {
                    negative = false;
                }

                value = value.slice(prefix.length, value.length - suffix.length);

                if (negative) {
                    value = '-' + value;
                }

                let oldValue;
                do {
                    oldValue = value;
                    value = oldValue.replace(thousandsSeparatorSymbol, '');
                } while (value !== oldValue)

                if (allowDecimal) {
                    value = value.replace(decimalSymbol, '.');
                }
                return value;
            },
            props: omit(props, PROPS_MASK),
        }
    }

    renderInput(ref, { defaultValue, ...props }) {
        return (
            <StyledInput
                ref={(node) => {
                    let domNode = ReactDOM.findDOMNode(node);
                    if (domNode) {
                        domNode = domNode.getElementsByTagName('input')[0];
                    }
                    return ref(domNode);
                }}
                value={defaultValue}
                {...props}
            />
        );
    }

    renderContent(props) {
        const { props: otherProps, mask } = this.createMask(props);

        return (
            <MaskedInput
                mask={mask}
                value={this.localValue}
                onChange={this.onChange}
                onBlur={this.initLocalValue}
                render={this.renderInput}
                {...otherProps}
            />
        );
    }
}

export class TargetMoneyInput extends TargetNumberInput {
    static defaultProps = {
        ...TargetNumberInput.defaultProps,
        allowDecimal: true,
        decimalLimit: 2,
        includeThousandsSeparator: true,
        prefix: '€',
    };
}

export class TargetPercentageInput extends TargetNumberInput {
    static defaultProps = {
        ...TargetNumberInput.defaultProps,
        allowDecimal: true,
        decimalLimit: 1,
        suffix: '%',
    };
}

// File uploads

export function getFileTypeFromUrl(url) {
    let search = null;

    if (url) {
        const pos = url.indexOf('?');
        if (pos !== -1) {
            search = url.slice(pos);
        }
    }

    if (search) {
        const urlSearchParams = new URLSearchParams(search);
        return urlSearchParams.get('content_type');
    } else {
        return null;
    }
}

export function getFileNameFromUrl(url) {
    let search = null;

    if (url) {
        const pos = url.indexOf('?');
        if (pos !== -1) {
            search = url.slice(pos);
        }
    }

    if (search) {
        const urlSearchParams = new URLSearchParams(search);
        return urlSearchParams.get('filename');
    } else {
        return null;
    }
}

export function stripQueryParams(url) {
    if (typeof url === 'string' && url.startsWith('blob:')) {
        const pos = url.indexOf('?');
        if (pos !== -1) {
            url = url.slice(0, pos);
        }
    }

    return url;
}


const PreviewContainer = styled.div`
    margin-top: 0.5em;
`;


@observer
export class FilePreview extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    };

    renderImagePreview() {
        const { url, ...props } = this.props;
        return (
            <PreviewContainer>
                <Image src={url} {...props} />
            </PreviewContainer>
        );
    }

    render() {
        const { type } = this.props;
        if (type && type.startsWith('image/')) {
            return this.renderImagePreview();
        } else {
            return null;
        }
    }
}


export const Dropzone = styled(BaseDropzone)`
    cursor: pointer;
    width: unset;
    height: unset;
    border: unset;
`;

export const FileContainer = styled.div`
    display: flex;
    align-items: center;
    > .ui.button:last-child,
    > ${Dropzone}:last-child > .ui.button {
        margin-right: 0 !important;
    }
`;

function getFileUrl(url) {
    if (url === null || typeof url === 'string') {
        return url;
    } else {
        return `${url.preview || URL.createObjectURL(url)}?content_type=${url.type}`;
    }
}

export class TargetFile extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        target: PropTypes.instanceOf(Model).isRequired,
        value: PropTypes.string,
        noPreview: PropTypes.bool,
        disabled: PropTypes.bool,
        deletable: PropTypes.bool,
        onDelete: PropTypes.func,
        accept: PropTypes.string,
        previewDefault: PropTypes.string,
        previewDefaultFileType: PropTypes.string,
        noUpload: PropTypes.bool,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        noPreview: false,
        disabled: false,
        previewDefault: null,
        previewDefaultFileType: null,
        noUpload: false,
    };

    constructor(...args) {
        super(...args);
        this.onDelete = this.onDelete.bind(this);
    }

    @observable url = null;

    componentDidMount() {
        super.componentDidMount();
        this.dataReaction = reaction(
            () => [
                (this.props.target && this.props.target.api.getFileUrl) || getFileUrl,
                this.value,
            ],
            ([getUrl, value]) => {
                if (getUrl) {
                    const url = getUrl(value);
                    if (url instanceof Promise) {
                        this.url = null;
                        url.then((res) => this.url = res);
                    } else {
                        this.url = url;
                    }
                } else {
                    this.url = value;
                }
            },
            { fireImmediately: true },
        );
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.dataReaction();
    }

    @computed get fileType() {
        const { previewDefaultFileType } = this.props;
        return (
            this.url
            ? getFileTypeFromUrl(this.url)
            : previewDefaultFileType
        );
    }

    @computed get downloadUrl() {
        return this.url && stripQueryParams(this.url);
    }

    @computed get fileName() {
        return getFileNameFromUrl(this.url);
    }

    @computed get previewUrl() {
        const { previewDefault } = this.props;
        return this.downloadUrl || previewDefault;
    }

    @computed get deletable() {
        const { deletable } = this.props;
        return deletable === undefined ? !!this.value : deletable;
    }

    onChange(files) {
        for (const file of files) {
            super.onChange(file);
        }
    }

    onDelete() {
        const { onDelete } = this.props;

        if (onDelete) {
            onDelete();
        } else {
            super.onChange(null);
        }
    }

    renderContent({ noUpload, previewDefault, noPreview, disabled, accept, name, ...props }) {
        let icon, text;

        if (this.value === null) {
            icon = null;
            text = <i>{t('form.fileType.none')}</i>;
        } else if (this.fileType && this.fileType.startsWith('image/')) {
            icon = 'file image outline';
            text = t('form.fileType.image');
        } else if (this.fileType === 'application/pdf') {
            icon = 'file pdf outline';
            text = t('form.fileType.pdf');
        } else {
            icon = 'file outline';
            text = t('form.fileType.any');
        }

        let uploadButton = (
            <Button
                type="button"
                icon="upload"
                disabled={disabled}
                name={disabled ? name : undefined}
            />
        );

        if (!disabled) {
            uploadButton = (
                <Dropzone name={name} accept={accept} onDrop={this.onChange}>
                    {uploadButton}
                </Dropzone>
            );
        }

        return (
            <React.Fragment>
                <FileContainer>
                    {icon && <Icon name={icon} size="large" />} {text}
                    <RightDivider />
                    {!noUpload && uploadButton}
                    <Button
                        type="button"
                        icon="download"
                        as="a"
                        href={this.downloadUrl}
                        download={this.fileName}
                        disabled={!this.value}
                    />
                    <Button
                        type="button"
                        icon="delete"
                        onClick={this.onDelete}
                        disabled={disabled || !this.deletable}
                    />
                </FileContainer>
                {!noPreview && this.previewUrl !== null && (
                    <FilePreview
                        type={this.fileType}
                        url={this.previewUrl}
                        {...props}
                    />
                )}
            </React.Fragment>
        );
    }
}

const ImageContainer = styled.div`
    position: relative;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin: 0 auto;
    line-height: 0;

    border-radius: ${({ circle }) => circle ? '50%' : '0.5rem'};
    overflow: hidden;

    > div.imgs {
        transition: filter 300ms ease;
        width: 100%;
        height: 100%;

        > img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: ${({ objectFit }) => objectFit};
        }

        > img.base {
            opacity: 0.25;
            filter: blur(2px);
        }
        > img.circle {
            clip-path: ellipse(50% 50% at 50% 50%);
        }
    }

    > div.overlay {
        line-height: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: #FFF;
        transition: opacity 300ms ease;

        display: flex;
        align-items: center;
        justify-content: center;

        > i.icon {
            margin: 0 !important;
        }
    }

    &:hover {
        > div.imgs {
            filter: blur(4px);
        }
        > div.overlay {
            opacity: 1;
        }
    }
`;

const ImageCornerIcon = styled(Icon)`
    position: absolute;
    cursor: pointer;
    top: 0.5em;
    right: 0.5em;
`;

/**
 * <TargetImage noLabel
 *     target={driver}
 *     name="avatar"
 *     previewDefault={previewDefault}
 *     previewDefaultFileType="image/jpeg"
 * />
 */
export class TargetImage extends TargetFile {
    static propTypes = {
        ...TargetFile.propTypes,
        objectFit: PropTypes.string,
        circle: PropTypes.bool,
        imgWidth: PropTypes.string,
        imgHeight: PropTypes.string,
    };

    static defaultProps = {
        ...TargetFile.defaultProps,
        accept: 'image/*',
        objectFit: 'cover',
        circle: false,
        imgWidth: '10em',
        imgHeight: '10em',
    };

    renderContent({ accept, circle, objectFit, imgWidth, imgHeight }) {
        return this.previewUrl && (
            <ImageContainer
                objectFit={objectFit}
                width={imgWidth}
                height={imgHeight}
            >
                <div className="imgs">
                    {circle ? (
                        <React.Fragment>
                            <img className="base" src={this.previewUrl} alt={`${this.props.name} base`} />
                            <img className="circle" src={this.previewUrl} alt={`${this.props.name} circle`} />
                        </React.Fragment>
                    ) : (
                        <img src={this.previewUrl} alt={this.props.name} />
                    )}
                </div>
                <div className="overlay">
                    <Dropzone accept={accept} onDrop={this.onChange} name={this.props.name}>
                        <Icon size="large" name="upload" />
                    </Dropzone>
                    {this.value !== null && (
                        <ImageCornerIcon name="delete" onClick={this.onDelete} />
                    )}
                </div>
            </ImageContainer>
        );
    }
}

/**
 * Remote select example:
 * <TargetSelect remote
 *     store={customerStore}
 *     target={cost}
 *     name="customer"
 *     toOption={customer => ({
 *         value: customer.id,
 *         text: customer.name,
 *     })}
 * />
 */
export class TargetSelect extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired,
        })),
        toOption: PropTypes.func,
        optionFilter: PropTypes.func,

        // Used for converting the value in the query parameter
        type: PropTypes.oneOf(['str', 'int']),
        store: PropTypes.instanceOf(Store),

        // Remote settings

        /** Fetch options from the backend after a debounce timeout. */
        remote: PropTypes.bool,
        /** Query parameter for the backend to filter options on (defaults to 'search'). */
        searchKey: PropTypes.string,
        /** Whether initial options fetch is disabled. */
        skipFetch: PropTypes.bool,

        // Multiple settings
        multiple: PropTypes.bool,

        // Allows setting a ref to focus input field
        setRef: PropTypes.object,
    };

    static defaultProps = {
        ...TargetBase.defaultProps,

        // I would like to filter null and undefined out by default, but that would
        // break things spread over all our projects.
        //
        // Filters out related model with empty values out by default.
        optionFilter(option, props) {
            const { target, name } = props;

            if (target && target[name] instanceof Model) {
                return option.value !== null;
            }

            return true;
        },
        remote: false,
        searchKey: 'search',
        skipFetch: false,
        multiple: false,
    };

    constructor(...args) {
        super(...args);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.toStoreBase = this.toStoreBase.bind(this);
        this.fromStoreBase = this.fromStoreBase.bind(this);
        this.toModelBase = this.toModelBase.bind(this);
        this.fromModelBase = this.fromModelBase.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();

        this.setDebouncedFetchReaction = reaction(
            () => this.props.store,
            (store) => {
                if (store) {
                    this.debouncedFetch = debounce(
                        store.fetch.bind(store),
                        ACTION_DELAY,
                    );
                } else {
                    delete this.debouncedFetch;
                }
            },
            { fireImmediately: true },
        );

        const { remote, store, skipFetch } = this.props;

        if (remote && store && !skipFetch) {
            store.fetch();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.setDebouncedFetchReaction();
        if (this.valueStoreReaction) {
            this.valueStoreReaction();
        }
    }

    @computed get valueType() {
        const { type, store } = this.props;
        if (type) {
            return type;
        } else if (store) {
            return 'int';
        } else {
            return 'str';
        }
    }

    toStore(value) {
        const { multiple } = this.props;

        if (multiple) {
            if (Array.isArray(value) && value.length === 0) {
                return undefined;
            }
            value = value.map(this.toStoreBase).join(',');
        } else {
            if (value === null) {
                return undefined;
            }
            value = this.toStoreBase(value);
        }

        return value;
    }

    toStoreBase(value) {
        value = super.toStore(value);
        switch (this.valueType) {
            case 'str':
                break;
            case 'int':
                value = value.toString();
                break;
            default:
                throw new Error(`Invalid type: ${this.type}`);
        }
        return value;
    }

    fromStore(value) {
        const { multiple } = this.props;

        if (multiple) {
            if (value === undefined) {
                return [];
            }
            value = value.split(',').map(this.fromStoreBase);
        } else {
            if (value === undefined) {
                return null;
            }
            value = this.fromStoreBase(value);
        }

        return value;
    }

    fromStoreBase(value) {
        switch (this.valueType) {
            case 'str':
                break;
            case 'int':
                value = parseInt(value);
                break;
            default:
                throw new Error(`Invalid type: ${this.valueType}`);
        }
        value = super.fromStore(value);
        return value;
    }

    fromModel(value) {
        const { multiple } = this.props;

        if (multiple) {
            value = value.map(this.fromModelBase);
        } else {
            value = this.fromModelBase(value);
        }

        return value;
    }

    fromModelBase(value) {
        const { store } = this.props;
        if (store && value) {
            value = value.id;
        }
        value = super.fromModel(value);
        return value;
    }

    toModel(value) {
        const { multiple } = this.props;

        if (multiple) {
            value = value.map(this.toModelBase);
        } else {
            value = this.toModelBase(value);
        }

        return value;
    }

    toModelBase(value) {
        const { store } = this.props;
        value = super.toModel(value);
        if (store && value) {
            value = (
                store.get(value) ||
                (this.valueStore && this.valueStore.get(value)) ||
                null
            );
        }
        return value;
    }

    onChange(e, { value }) {
        // Special handler for clicking on clear button.
        if (
            value === '' &&
            _get(e, 'currentTarget.className', '').includes('icon clear')
        ) {
            value = null;
        }

        return super.onChange(value);
    }

    onSearchChange(e, { searchQuery }) {
        const { store, searchKey } = this.props;
        store.params[searchKey] = searchQuery;
        this.debouncedFetch();
    }

    valueStoreReaction = null;

    @computed get valueStore() {
        const { store, remote } = this.props;

        if (!remote) {
            return undefined;
        }

        if (this.valueStoreReaction) {
            this.valueStoreReaction();
        }

        const Store = store.constructor;
        const Model = store.Model;
        const base = this.getValueBase();

        if (base instanceof Store) {
            return base
        } else if (base instanceof Model) {
            const res = new Store({ relations: store.__activeRelations });
            res.add(base.toJS());
            return res;
        } else {
            const valueStore = new Store({ relations: store.__activeRelations });
            this.valueStoreReaction = reaction(
                () => this.value,
                (value) => {
                    if (typeof value === 'number') {
                        valueStore.clear();
                        const model = new Model(
                            { id: value },
                            { relations: valueStore.__activeRelations },
                        );
                        model.fetch().then(() => valueStore.add(model.toJS()));
                    } else if (Array.isArray(value)) {
                        if (value.length > 0) {
                            valueStore.params['.id:in'] = value.join(',');
                            valueStore.fetch();
                        } else {
                            valueStore.clear();
                        }
                    } else {
                        valueStore.clear();
                    }
                },
                { fireImmediately: true },
            );
            return valueStore;
        }
    }

    getOptions(props) {
        const { options, store, toOption, multiple, remote, optionFilter } = props;

        if (options) {
            return options;
        } else if (store && toOption) {
            let res = store.map(toOption);
            if (this.valueStore) {

                // It is possible that store and valueStore have elements in common
                // If that happens, the elements they have in common must only be
                // added once to avoid duplicate options.

                // Not the most efficient way, but the number of options should be small anyway
                this.valueStore.map(toOption).forEach(option => {
                    if (!res.some(existing => existing.value === option.value)) {
                        res.push(option);
                    }
                });
            } else if (!multiple && remote && this.value !== null && !store.get(this.value)) {
                res.push(toOption(this.getValueBase()));
            }

            return res.filter(option => optionFilter(option, props));
        } else {
            throw new Error('couldn\'t derive options');
        }
    }

    @computed get options() {
        return this.getOptions(this.props);
    }

    renderContent({ remote, ...props }) {

        if (remote) {
            props.search = sliceArray;
            props.onSearchChange = this.onSearchChange;
        }

        return (
            <Dropdown selection
                value={this.value}
                onChange={this.onChange}
                      ref={props.setRef}
                options={this.options}
                /* When using a remote search field the search query params of the store should be reset when opened to show all results  */
                onOpen={(this.props.remote && this.props.store) ? this.onSearchChange : null}
                {...omit(props,
                    'options', 'toOption', 'optionFilter', 'searchKey', 'skipFetch')}
            />
        );
    }
}

// Time Picker

const LUXON_SERVER_TIME_FORMAT = 'HH:mm:ssZZZ';

export class TargetTimePicker extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.instanceOf(moment),
        dateLib: PropTypes.oneOf(DATE_LIBS),
    };

    @computed get dateLib() {
        const { target, name, dateLib } = this.props;
        return getDateLib(target, name, dateLib);
    }

    toModel(value) {
        if (!value) {
            return null;
        }
        return DATE_CONVERTERS.luxon[this.dateLib](value);
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        return DATE_CONVERTERS[this.dateLib].luxon(value);
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }
        return value.toFormat(LUXON_SERVER_TIME_FORMAT);
    }

    fromStore(value) {
        if (!value) {
            return null;
        }
        return DateTime.fromFormat(value, LUXON_SERVER_TIME_FORMAT);
    }

    renderContent(props) {
        return (
            <TimePicker
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

// Time Range Picker

export class TargetTimeRangePicker extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.instanceOf(Interval),
        startName: PropTypes.string,
        endName: PropTypes.string,
        dateLib: PropTypes.oneOf(DATE_LIBS),
    };

    @computed get dateLib() {
        const { target, startName, endName, dateLib } = this.props;

        const startDateLib = getDateLib(target, startName, dateLib);
        const endDateLib = getDateLib(target, endName, dateLib);

        if (startDateLib !== endDateLib) {
            throw new Error('incompatible date libraries used between start and end');
        }

        return startDateLib;
    }

    getValueBase(name) {
        const { startName, endName } = this.props;

        if (name || !startName || !endName) {
            return super.getValueBase(name);
        }

        const start = this.getValueBase(startName);
        const end = this.getValueBase(endName);
        switch (this.type) {
            case 'model':
                if (start && end) {
                    return { start, end };
                } else {
                    return null;
                }
            case 'store':
                if (start && end) {
                    return `${start},${end}`;
                } else {
                    return undefined;
                }
            default:
                // nop
        }
    }

    @action setValue(value, name) {
        const { startName, endName } = this.props;

        if (name || !startName || !endName) {
            return super.setValue(value, name);
        }

        switch (this.type) {
            case 'model':
                const { start, end } = value;
                this.setValue(start, startName);
                this.setValue(end, endName);
                break;
            case 'store':
                if (value === undefined) {
                    this.setValue(undefined, startName);
                    this.setValue(undefined, endName);
                } else {
                    const [start, end] = value.split(',');
                    this.setValue(start, startName);
                    this.setValue(end, endName);
                }
                break;
            default:
                // nop
        }
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        return DATE_RANGE_CONVERTERS[this.dateLib].luxon(value);
    }

    toModel(value) {
        if (!value) {
            return null;
        }
        return DATE_RANGE_CONVERTERS.luxon[this.dateLib](value);
    }

    fromStore(value) {
        if (value === undefined) {
            return null;
        }

        let [start, end] = value.split(',');

        return Interval.fromDateTimes(
            DateTime.fromFormat(start, LUXON_SERVER_TIME_FORMAT),
            DateTime.fromFormat(end, LUXON_SERVER_TIME_FORMAT),
        );
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }

        const start = value.start.toFormat(LUXON_SERVER_TIME_FORMAT);
        const end = value.end.toFormat(LUXON_SERVER_TIME_FORMAT);
        return `${start},${end}`;
    }

    renderContent(props) {
        return (
            <TimeRangePicker
                value={this.value}
                onChange={this.onChange}
                {...props}
            />
        );
    }
}

// Date Picker

const LUXON_SERVER_DATETIME_FORMAT = `${LUXON_SERVER_DATE_FORMAT}'T'${LUXON_SERVER_TIME_FORMAT}`;

const NoMarginGroup = styled(Form.Group)`
    margin-bottom: 0 !important;
`;

export class TargetDateTimePicker extends TargetBase {
    static propTypes = {
        ...TargetBase.propTypes,
        value: PropTypes.instanceOf(DateTime),
        dateProps: PropTypes.object,
        timeProps: PropTypes.object,
        disabled: PropTypes.bool,
        dateLib: PropTypes.oneOf(DATE_LIBS),
    };

    static defaultProps = {
        ...TargetBase.defaultProps,
        dateProps: {},
        timeProps: {},
        disabled: false,
    };

    @computed get dateLib() {
        const { target, name, dateLib } = this.props;
        return getDateLib(target, name, dateLib);
    }

    constructor(...args) {
        super(...args);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
    }

    toModel(value) {
        if (!value) {
            return null;
        }
        return DATE_CONVERTERS.luxon[this.dateLib](value);
    }

    fromModel(value) {
        if (!value) {
            return null;
        }
        return DATE_CONVERTERS[this.dateLib].luxon(value);
    }

    toStore(value) {
        if (!value) {
            return undefined;
        }
        return value.toFormat(LUXON_SERVER_DATETIME_FORMAT);
    }

    fromStore(value) {
        if (!value) {
            return null;
        }
        return DateTime.fromFormat(value, LUXON_SERVER_DATETIME_FORMAT);
    }

    onChangeDate(value) {
        this.onChange(
            this.value
            ? this.value.set({
                year: value.year,
                month: value.month,
                day: value.day,
            })
            : value
        );
    }

    onChangeTime(value) {
        this.onChange(
            this.value
            ? this.value.set({
                hour: value.hour,
                minute: value.minute,
                second: value.second,
                millisecond: value.millisecond,
            })
            : value
        );
    }

    renderContent({ disabled, dateProps, timeProps, ...props }) {
        return (
            <NoMarginGroup {...props}>
                <TargetDatePicker noLabel
                    value={this.value}
                    onChange={this.onChangeDate}
                    width={8}
                    disabled={disabled}
                    {...dateProps}
                />
                <TargetTimePicker noLabel
                    value={this.value}
                    onChange={this.onChangeTime}
                    width={8}
                    disabled={disabled}
                    {...timeProps}
                />
            </NoMarginGroup>
        );
    }
}
