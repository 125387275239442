import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {theme} from 'styles';
import {Icon, Label} from 'semantic-ui-react';
import ImgLogo from 'image/logo.png';
import {NotificationStore} from 'store/Notification';
import { MessageStore } from "../store/Message";

const Sidebar = styled.section`
    flex: 0 0 auto;
    width: 50px;
    background-color: #183E53;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 100;
    padding-bottom: 50px;
`;

const Button = styled.div`
    width: 50px;
    height: 50px;
    font-size: 1.25rem;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    ${({active}) => active ? `
        background-color: ${theme.primaryColor};
        color: #fff;
    ` : `
        color: #fff;
    `}

    :hover {
        background-color: ${theme.secondaryColor};
    }

    transition: background-color 250ms ease;

    i.icon {
        margin: 0 !important;
    }
    position: relative;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1.5em;
        height: 1.5em;
    }
`;

const CyLink = styled.a`
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
    #cy-logo-c {
        fill: rgba(255, 255, 255, 0.2);
        transition: fill 600ms ease;
    }
    #cy-logo-y {
        fill: rgba(255, 255, 255, 0.2);
        transition: fill 600ms ease;
    }
    &:hover {
        #cy-logo-c {
            fill: #ebbb12;
        }
        #cy-logo-y {
            fill: #fff;
        }
    }
`;

const Logo = styled.img`
    width: 40px;
    margin: 5px;
`;

const UnreadMessageCountIndicator = styled.div`
    position: absolute;
    left: calc(50% - 20px / 2 + 8px);
    top: calc(50% - 14px / 2 - 9px);

    background: #B71C1C;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    flex: 1;
    padding: 1px 6px !important;

    color: white;
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 140%;
`;

const TABS = [
    {
        key: 'notifications',
        icon: <Icon name="bell"/>,
    },
    {
        key: 'chat',
        icon: <Icon name="chat"/>,
    },

];

@observer
export default class AppSidebar extends Component {
    static propTypes = {
        selected: PropTypes.string,
        store: PropTypes.object.isRequired,
        onSelect: PropTypes.func.isRequired,
        notificationStore: PropTypes.instanceOf(NotificationStore).isRequired,
        messageStore: PropTypes.instanceOf(MessageStore).isRequired,
    };

    constructor(...args) {
        super(...args);

        this.renderTab = this.renderTab.bind(this);
    }

    closeTab = () => {
        const {onSelect} = this.props;
        onSelect(null);
    }

    renderTab({key, icon}) {
        const { selected, onSelect, notificationStore, messageStore } = this.props;


        return (
            (key !== 'chat' || window.viewStore.currentUser.pharmacist?.customer.chatEnabled) &&
            <Button
                key={key}
                active={selected === key}
                data-test-sidebar-button={key}
                className={
                    selected === key
                        ? `sidebar-button active`
                        : `sidebar-button inactive`
                }
                onClick={e => {

                    onSelect(key)

                    // Don't close it again
                    e.stopPropagation();
                }}
            >
                {key === 'notifications' && notificationStore.hasNewNotification &&
                <Label data-test-has-notifications
                       style={{ top: "0.9rem", backgroundColor: theme.warningColor, left: "70%", fontSize: "0.3rem" }}
                       size='mini' circular floating/>
                }
                {key === 'chat' && messageStore.unreadCount > 0 &&
                <UnreadMessageCountIndicator data-test-chat-total-unread-count>{messageStore.unreadCount}</UnreadMessageCountIndicator>
                }
                {typeof icon === 'function' ? icon(selected === key) : icon}
            </Button>
        );
    }

    render() {
        return (
            <Sidebar onClick={this.closeTab}>
                <Logo src={ImgLogo} alt="PharmiCY"/>
                {TABS.map(this.renderTab)}
                <CyLink
                    href="https://www.codeyellow.nl/"
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                >
                    <svg width="50" height="50" viewBox="0 0 1 1">
                        <g transform="scale(0.8) translate(0.1, 0.1)">
                            <path id="cy-logo-c" d="
                                M 0.25,0.25 l -0.21875,0.21875 a 0.04419,0.04419 1 0 0 0,0.0625
                                l 0.28125,0.28125
                                l 0.125,-0.125
                                l -0.15625,-0.15625 a 0.04419,0.04419 1 0 1 0,-0.0625
                                l 0.1875,-0.1875 a 0.04419,0.04419 1 0 1 0.0625,0
                                l 0.15625,0.15625
                                l 0.125,-0.125
                                l -0.28125,-0.28125 a 0.04419,0.04419 1 0 0 -0.0625,0 Z
                            "/>
                            <path id="cy-logo-y" d="
                                M 0.46875,0.96875 a 0.04419,0.04419 1 0 0 0.0625,0
                                l 0.4375,-0.4375 a 0.04419,0.04419 1 0 0 0,-0.0625
                                l -0.09375,-0.09375
                                l -0.15625,0.15625 a 0.04419,0.04419 1 0 1 -0.0625,0
                                l -0.15625,-0.1562
                                l -0.125,0.125
                                l 0.15625,0.15625 a 0.04419,0.04419 1 0 1 0,0.0625
                                l -0.15625,0.15625
                            "/>
                        </g>
                    </svg>
                </CyLink>
            </Sidebar>
        );
    }
}
