import { observable } from 'mobx';
import { Moment } from "../../Moment"
import {Model, Store} from 'store/Base';
import { Casts } from "../../Base";


export const NOTIFICATION_TITLE_COUNT = 50;
export const NOTIFICATION_BODY_COUNT = 178;

export class PushNotification extends Model {
    static backendResourceName = 'push_notification';

    @observable id = null;

    @observable title = '';
    @observable body = '';
    @observable time = null;

    casts() {
         return {
            time: Casts.time,
        };
    }

    relations() {
        return {
            moment: Moment,
        };
    }
}

export class PushNotificationStore extends Store {
    Model = PushNotification;
    static backendResourceName = 'push_notification';
}
