import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import ViewStore from 'spider/store/View';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import {theme} from './styles.js';
import {t} from './i18n';
import i18next from 'i18next';
import { get } from 'lodash';

import {LoginModal, TwoFactorModal} from "./component/LoginModal";



    // use router instead of browserRouter for sentry, see
// https://github.com/getsentry/sentry-javascript/issues/3107#issuecomment-741431381
import { BrowserRouter as
        Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {ReCyCleTheme} from 're-cy-cle';
import configureSentry, {setUserSentry} from './sentry';
import {PUBLIC_URL, configOverride} from 'helpers';
import { configureModal } from 'helpers/modal';
import { configureNotification, showNotification } from 'helpers/notification';
import show, {clear} from 'helpers/modal';
import { configurePermission } from 'spider/component/Permission';
import { configureCurrentUser } from 'helpers/currentUser';
import { CancelButton, ApplyButton } from 'spider/semantic-ui/Button';
import { configureBasename, configureAfterSave } from 'spider/helpers/bindUrlParams';
import {Icon, Modal} from 'semantic-ui-react';
import RightDivider from 'spider/component/RightDivider';
import 'moment-duration-format';
import {api} from 'store/Base';
import {User} from 'store/User.tsx';
import {configureTranslation} from 'daycy';
import {createBrowserHistory} from 'history';

import 'daycy/dist/daycy.css';
import 'style/semantic-ui/foo/bar/main.css';
import 'style/semantic-ui/daycy.css';
import 'style/semantic-ui.css';
import 'style/extra-icons.css';
import 'style/custom-icons/cy-custom-icons.css';
import {Authentication} from "./store/Authentication";

window.t = t;

configureTranslation((key, args) => {
    return t(`daycy.${key}`, args);
});

class SpecialViewStore extends ViewStore {
    @observable auth = new Authentication();
    @observable twoFactorAuthModalIsShown = false;

    fetchBootstrap() {
        return super.fetchBootstrap().then(res => {
            if (res.auth) {
                this.auth.fromBackend({
                    data: res.auth.data,
                    repos: res.auth.with,
                    relMapping: res.auth.with_mapping,
                });
            } else {
                this.auth = new Authentication();
            }
            this.twoFactorEnabled = res.two_factor_enabled;

            configOverride(res);


            return res;
        });
    }

    @action
    performEndmasquerade() {
        return this.api
            .post('/user/endmasquerade/')
            .then(() => this.fetchBootstrap());
    }

    @action
    handleBootstrap(res) {
        configOverride(res);
        if (res.user) {
            i18next.changeLanguage(res.user.data.language);
        }
        return super.handleBootstrap(res);
    }

    parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({
            data: res.user.data,
            repos: res.user.with,
            relMapping: res.user.with_mapping,
        });
        setUserSentry(this.currentUser)
    }
};



const viewStore = new SpecialViewStore({
    api,
    user: new User({ id: null }, {
        relations: ['groups.permissions', 'pharmacist.customer']
    }),
    socketUrl: `${PUBLIC_URL || ''}/ws/`,
    fetchBootstrap: true
});

/**
 * Currently test newAppVersionNotification abuses the fact that viewStore is
 * globally available. We should only expose this when debug = true. BOEK has
 * a debug mode, where you can see more in the interface (like calculations)
 * and have access to scary buttons.
 */
window.viewStore = viewStore;


export const history = createBrowserHistory();
configureSentry(viewStore, history);

configureModal(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configureBasename(PUBLIC_URL);
configureAfterSave({
    followNext: true, // always go to the 'next' route (set in query paramter) after saving
    createUrl: '/add'
});
configureCurrentUser(viewStore);

@observer
class Root extends Component {
    @observable showAlert = false;
    @observable alertTitle = '';
    @observable alertMessages = [];
    @observable alertConfirm = null;

    // Custom alert callbacks.
    @observable alertOnApply = null;
    @observable alertOnCancel = null;

    componentDidMount() {
        i18next.on('languageChanged', () => this.forceUpdate());
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }

    cancel = () => {
        this.showAlert = false;

        if (this.alertOnCancel) {
            this.alertOnCancel();
        }

        this.alertConfirm(false);
        this.alertOnApply = null;
        this.alertOnCancel = null;
    }

    confirm = () => {
        this.showAlert = false;

        if (this.alertOnApply) {
            this.alertOnApply();
        }

        // Yeah... I really couldn't find another way to pass the right save() function to index.js
        if (window['saveBeforeContinue']) {
            window['saveBeforeContinue']().then(() => {
                this.alertConfirm(true);
                this.alertOnApply = null;
                this.alertOnCancel = null;
            });
        } else {
            this.alertConfirm(true);
            this.alertOnApply = null;
            this.alertOnCancel = null;
        }
    }

    render() {
        return (
            <Sentry.ErrorBoundary showDialog>
                <React.Fragment key={i18next.language}>
                <Modal size="tiny" open={this.showAlert} centered={false} data-test-modal-notification-window>
                    <Modal.Header>
                        <Icon style={{color: '#EBBB12', marginRight: '0.5em'}} name={'warning sign'}/>
                        { this.alertTitle }
                    </Modal.Header>
                    <Modal.Content style={{textAlign: 'center'}}>
                        { this.alertMessages.map(message => <div key={`alert-message`}> { message } </div>) }
                    </Modal.Content>
                    <Modal.Actions style={{display: 'flex'}}>
                        <CancelButton onClick={this.cancel} data-test-alert-cancel-button />
                        <RightDivider/>
                        <ApplyButton primary onClick={this.confirm} content={t('form.continueButton')} data-test-alert-save-button />
                    </Modal.Actions>
                </Modal>
                <ReCyCleTheme theme={theme}>
                    <Router history={history} basename={PUBLIC_URL} getUserConfirmation={(message, confirm, ...args) => {
                        this.showAlert = true;
                        this.alertConfirm = confirm;

                        if (typeof message === 'object') {
                            this.alertTitle = 'Warning';
                            this.alertMessages = [message.message];
                            this.alertOnApply = message.onApply;
                            this.alertOnCancel = message.onCancel;
                        } else if (message === 'standard-leave') {
                            this.alertTitle = t('nav.prompt.leave.title');
                            this.alertMessages = [t('nav.prompt.leave.line1'), t('nav.prompt.leave.line2')];
                            this.alertOnApply = null;
                            this.alertOnCancel = null;
                        } else {
                            this.alertTitle = 'Warning';
                            this.alertMessages = [message];
                            this.alertOnApply = null;
                            this.alertOnCancel = null;
                        }

                    }}>
                        <App store={viewStore} twoFactorAuthModalIsShown={viewStore.twoFactorAuthModalIsShown}/>
                    </Router>
                </ReCyCleTheme>
            </React.Fragment>
        </Sentry.ErrorBoundary>
        );
    }
}

let notAuthenticatedRequests = [];
let isLoginModalShown = false;

let authenticateRequest = () => {
    notAuthenticatedRequests.forEach(pending => {
        let responseConfig = pending.err.response.config
        if (responseConfig.headers['X-Csrftoken']) {
            responseConfig.headers['X-Csrftoken'] = api.csrfToken;
        }

        api.axios({ ...responseConfig, baseURL: '' })
            .then(pending.resolve)
            .catch(pending.reject);
    });

    notAuthenticatedRequests = [];
}

let handleTwoFactorModal = () => {
    viewStore.twoFactorAuthModalIsShown = true;
    show(TwoFactorModal, {
        viewStore,
        afterLogin: () => {
            authenticateRequest();
            viewStore.twoFactorAuthModalIsShown = false;
            clear();
        }
    });
};

api.axios.interceptors.response.use(null, err => {
    const status = get(err, 'response.status');
    const statusErrCode = get(err, 'response.data.code');

    if (status === 403 && statusErrCode === 'NotAuthenticated' && err.response.config.url !== '/api/user/login/') {
        if (!isLoginModalShown) {
            isLoginModalShown = true;
            show(LoginModal, {
                viewStore,
                afterLogin: () => {
                    clear();
                    isLoginModalShown = false;

                    const status = viewStore.auth.status;
                    if (status === 'waiting' || status === 'expired') {
                        handleTwoFactorModal();
                    } else {
                        authenticateRequest();
                    }
                }
            });
        }

        return new Promise((resolve, reject) =>
            notAuthenticatedRequests.push({ resolve, reject, err })
        );
    }
    if(status === 504 || status === 500){
        showNotification(t('user.login.errors.unknown', {status:status || statusErrCode}));

        return new Promise((resolve, reject) =>
            notAuthenticatedRequests.push({ resolve, reject, err })
        );
    }

    return Promise.reject(err);
});

ReactDOM.render(
    <Root/>,
    document.getElementById('root')
);
