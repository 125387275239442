import { Model, Store, Casts } from 'store/Base';
import { observable } from "mobx";
import { WorkOrder } from 'store/WorkOrder';
import { User } from "store/User";
import { WorkOrderCheck } from 'store/WorkOrderCheck';
import { Issue } from 'store/Issue';

export class IssueReply extends Model {
    static backendResourceName = 'issue_reply';

    @observable id = null;
    @observable description = '';
    @observable createdAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }

    relations() {
        return {
            issue: Issue,
            workOrder: WorkOrder,
            workOrderCheck: WorkOrderCheck,
            creator: User,
        };
    }
}

export class IssueReplyStore extends Store {
    Model = Issue;
    static backendResourceName = 'issue_reply';
}
