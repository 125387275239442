import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import {Header} from 'semantic-ui-react';
import styled from 'styled-components';
import {lighten} from 'polished';
import {theme} from 'styles';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import NotificationContainer from 'component/Notification';
import {NotificationStore} from 'store/Notification';

const Container = styled.div`
    padding: 2rem;
`;

const SectionHeader = styled(Header)`
    color: ${theme.primaryColor} !important;
`;

const Link = styled.a`
    color: ${theme.primaryColor} !important;
    margin-right: 5px;
    display:flex;
    justify-content: flex-end;

    &:hover {
        color: ${lighten(.1, theme.primaryColor)} !important;
        cursor: pointer;
    }
`;


@observer
export default class NotificationTab extends Component {

    static propTypes = {
        notificationStore: PropTypes.instanceOf(NotificationStore).isRequired,
    };

    @observable count = 0;

    subtract = () => {
        this.count = this.count > 0 ? this.count-1 : 0;
    }

    componentDidMount(){
        this.props.notificationStore.fetch().then(() => {
            this.count = this.props.notificationStore.length;
        });
    }

    renderNotif = (notification) => {
        return (<NotificationContainer key={notification.id} onRead={this.subtract} notification={notification} store={this.props.notificationStore}/>);
    }

    @action clearAll = () => {
        this.props.notificationStore.forEach(n => {
            n.markSeen().catch(err => {
                console.log(err);
            });
        });
        this.count=0;
        this.props.notificationStore.fetch();
    }

    render() {
        return (
            <Container>
                <Header data-test-notification-count={this.count} as="h3">
                    {t('notification.label')} { this.count > 0 ? `(${this.count})`: ''}
                </Header>

                {/*<SectionHeader as="h4">Messages</SectionHeader>*/}

                <SectionHeader as="h4">{t('notification.updates')}</SectionHeader>
                {this.count > 0 &&
                <Link data-test-mark-all-seen onClick={this.clearAll}>{t('notification.markAll')}</Link>}
                {this.count > 0 && this.props.notificationStore.map(this.renderNotif)}
            </Container>
        );
    }
}
